import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { UserContext } from "../../lib/UserContext";
import './Dashboard.css';
import DashboardProjectsView from '../../components/DashboardProjectsView';
import DashboardTasksView from '../../components/DashboardTasksView';
import DashboardClientUpdate from '../../components/DashboardClientUpdate';
import DashboardEmpperformance from '../../components/DashboardEmpperformance';
import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image';
import welcomeImg from '../..//static/images/welcomeimg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { functionService } from "../../lib/functions";

function Dashboard() {
    const [loginUser] = useContext(UserContext);
    const[rows,setRows] = useState([]);
    let currentUser = functionService.getCurrentUser();
    const limit = 20;
    const getList = async()=>{
      let date = new Date();
      let res = await functionService.get('dashboard/list',{limit:limit,currentPage:1,user_id:currentUser.sub});
      if(res.status === true){
          setRows(res.response.data);
      }
    }
    useEffect(()=>{
        getList();
    },[]);
  return (
        <>
            <Header />            
            <Container fluid>
                <Row className="main--panel">
                   <Sidebar/> 
                    <Col md={10} className="p-5">
                        <Row className="dashboard-greeting ">
                            <Col md={7}>
                                <h2 className="welcome-head">Welcome Back, <span className="home-red-color">Start Designs</span></h2>
                                <div className="welcom-latest-news"><p className="home-red-color">Latest News</p>
                                    <ListGroup as="ul">
                                        {rows && rows.result && rows.result.map((obj,index)=>(
                                            <ListGroup.Item key={index}  as="li" className="">{obj.title}</ListGroup.Item>
                                        ))}

                                    </ListGroup>
                                </div>
                            </Col>
                            {/* <Col md={2}></Col> */}
                            <Col md={5}>
                                <Image src={welcomeImg} className="welcom-img"></Image> 
                            </Col>                            
                        </Row>

                        <Row className="dashboard-project-section">
                         {rows && rows.projecteprint && rows.projecteprint.map((obj,index)=>(
                            <Col md={6}> <DashboardProjectsView taskName = {obj.title} projectName={obj.name} obj={obj}/>  </Col>
                         ))}
                                                                               
                        </Row>

                                                  
                        <div className= "recent-tasks-section">
                            <div className="tasks-section-head">
                                <h2>Recent Tasks</h2>
                                <a href="true" onClick={(e) => {e.preventDefault();}}><FontAwesomeIcon icon={faEdit} size="lg"/></a>
                            </div>                                    
                            <Row>
                            {rows && rows.taskitem && rows.taskitem.map((obj,index)=>(
                                <Col md={2}><DashboardTasksView taskid={index+1} taskName={obj.title} priority={obj.priority} name={obj.name} stylebg={{background: "#f1f9f9"}} details={obj.description}/></Col>
                            ))}
                           </Row>
                        </div>   

                        <Row >
                            <Col md={8}> 
                                <div className="dashboard-client-sections">
                                    <div className="clients-section-head">
                                        <h2>Recent Clients Update</h2>
                                        <a href="true" onClick={(e) => {e.preventDefault();}}><FontAwesomeIcon icon={faEdit} size="lg"/></a>
                                    </div>
                                    <Row>
                                     {rows && rows.projectmsg && rows.projectmsg.map((obj,index)=>(
                                        <Col md={3}><DashboardClientUpdate clientName={obj.user_name} clientCompany={obj.title} stylebg={{background: "#f1f9f9"}} date={obj.created_at} details={obj.description}/> </Col>
                                     ))}
                                    </Row>
                                </div>                                
                            </Col>
                            <Col md={4}> 
                                <div className="dashboard-emp-perform">
                                    <DashboardEmpperformance stylebg={{background: "#f1f9f9"}}/>  
                                    <DashboardEmpperformance stylebg={{background: "#ecf6ff"}}/>
                                    <DashboardEmpperformance stylebg={{background: "#eff3ff"}}/>  
                                </div>                          
                            </Col>  
                        </Row>                                                     
                        
                    </Col>
                </Row>
            </Container>
        </> 
  );
  
}

export default Dashboard;