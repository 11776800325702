import React,{useState,useEffect} from "react";
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import { functionService } from "../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { addSprint, validate } from "./ValidationChecked";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function AddSprintModal({handleModalState,setSandleModalState,handleClose,show,isCreate=0}) {   
  const[formData,setFormData]=useState({});
	const[message,setMessage] = useState('');
	const params = useParams();
  const[projectMilestone,setProjectMilestone]=useState([]);
  const[disabled,setDisabled]=useState(false);
  const[btnLoader,setBtnLoader]=useState(false);
  const[errors,setErrors]=useState({});
  const[trigger,setTrigger]=useState(0);
  
	const handleAction = async (isClose)=>{
    if(validate(formData,addSprint) !== 0){ setErrors(validate(formData,addSprint)); return false;}
		setMessage('');
    if(disabled === true){ return false;}
    setDisabled(true);
    setBtnLoader(true);
		formData.project_id =params.projectid;
    let res = {};
    
    if (isCreate === 0) {
      res = await functionService.post("sprints/save",formData);
      formData.title= "";
      formData.start_date= "";
      formData.end_date= "";
      formData.milestone_id= "";
    }else{
      formData.id = isCreate;
      res = await functionService.update("sprints/update",formData);
      handleClose();
    }
    setDisabled(false);
    setBtnLoader(false);
		if(res.status === true){
      isClose === 1 && 	handleClose();
			setSandleModalState(!handleModalState);
        }else{
            setMessage(res.error.message);
        }
	}
  const getItem = async()=>{
    let res = await functionService.get(`sprints/get/${isCreate}`,{id:isCreate,projectId:params.projectid});
    if(res.status === true){
       setFormData(res.response.data.result);
    }
  }
  const getTech = async()=>{
    let res = await functionService.get('milestones/list',{limit:200,currentPage:1,projectId:params.projectid});
    if(res.status === true){
      setProjectMilestone(res.response.data.result);
      console.log("mile", res )
    }
  }
  useEffect(()=>{
    setFormData({});
    if(isCreate !== 0 && show === true){
      getItem();
     }
     getTech();
     
  },[isCreate,show]);
    return (
      <>
        <Modal
        show={show}
        
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-project-modal"
        >          
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-project-modal">
            {isCreate === 0 ? 'Add New' : 'Update' } Sprint
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Row>
              <Col md = {12}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Sprint Name</Form.Label>
                  <Form.Control type="text" placeholder="Title" value={formData && formData.title} onChange={(e)=>{setFormData({...formData,'title':e.target.value})}}/>
                  {errors && <p className="error">{errors['title']}</p>}
                </Form.Group>              
              </Col>
             
            </Row>
            <Row>
              <Col md = {12}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control type="date" placeholder="Start Date" value={formData && functionService.getFormatedDate(formData.start_date)} onChange={(e)=>{setFormData({...formData,'start_date':e.target.value})}}/>
                  {errors && <p className="error">{errors['start_date']}</p>}
                </Form.Group>              
              </Col>
              
            </Row>
            <Row>
              <Col md = {12}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control type="date" placeholder="End Date" value={formData && functionService.getFormatedDate(formData.end_date)} onChange={(e)=>{setFormData({...formData,'end_date':e.target.value})}}/>
                  {errors && <p className="error">{errors['end_date']}</p>}
                </Form.Group>              
              </Col>
              
            </Row>
            <Row>
             
              <Col md = {12}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Milestone</Form.Label>
                  <Form.Select aria-label="status" className="form-control" value={formData && formData.milestone_id} onChange={(e)=>{setFormData({...formData,'milestone_id':e.target.value})}}>
                        <option value="0">Select Milestone</option>
                        { projectMilestone && projectMilestone.map((obj,index)=>(
                             <option value={obj.id} key={index}>{obj.title}</option>
                        ))}
                  </Form.Select> 
                  {errors && <p className="error">{errors['milestone_id']}</p>}
                </Form.Group>
              </Col>
            </Row>
           
            

          </Form>
        </Modal.Body>
        <Modal.Footer>
            {isCreate === 0 ?<>           
             <Button className="add-btn" onClick={()=>{setTrigger(0); handleAction(isCreate)}}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Create</Button>
            <Button className="add-btn" onClick={() => {setTrigger(1); handleAction(1) }}>{(btnLoader && trigger === 1) && <FontAwesomeIcon icon={faSpinner} spin />} Create & Close</Button> </> 
             :
              <Button className="add-btn"  onClick={()=>{setTrigger(0); handleAction(isCreate)}}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Update</Button>
            }
          <Button variant="outline-dark close-btn" onClick={handleClose}>
            Close
          </Button>
		  {message !== '' && message}
        </Modal.Footer>
        </Modal>        
        
      </>
    );
  }
  
 