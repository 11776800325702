import React from "react";
import DocumentImages from "./DocumentImages";
import AnchorLink from "./Link";
export const projectColumn =[
    ['CRM ID','id',{
            type: "render",
            render: (param) => (
                <AnchorLink label={`CRM ID#${param.value}`} link={`/project/${param.value}/view`}/>
            ),
     }],
    ['Project Name','name'],
    ['Status','status',{
            type: "render",
            render: (param) => (
                <span className={`project--status-${param.value}`}>{param.value}</span>
            ),
     }],
    ['Start Date','start_date'],
    ['End Date','end_date'],   
];
export const userColumn =[
    ['ID','id'],
    ['Name','name'],
    ['Email','email'],
    ['Phone Number','phone_number'],
    ['Role','user_type'],
];
export const taskColumn =[
    ['CRM ID','id',{
        type: "render",
        render: (param) => (
            <span className="table-task-id">Task ID#{param.value}</span>
        ),
    }],
    ['Task Name','title'],
    ['Start Date','start_date'],
    ['End Date','end_date'],    
    ['Priority','priority',{
        type: "render",
        render: (param) => (
            <span className={`priority--${param.value}`}>{param.value}</span>
        ),
    }],
    ['Status','task_status',{
        type: "render",
        render: (param) => (
            <span className={`task_status--${param.value}`}>{param.value}</span>
        ),
    }], 
];
export const myTaskColumn =[
    ['Project Name','name'],
    ['Sprint','ttitle'],
    ['Task','title'],
    ['Start Date','start_date'],
    ['End Date','end_date'],    
    ['Status','task_status'],    
    ['Priority','priority',{
        type: "render",
        render: (param) => (
            <span className={`priority--${param.value}`}>{param.value}</span>
        ),
    }],
    
];
export const teamTaskColumn =[
    ['Project Name','name'],
    ['Developer Name','uname'],
    ['Sprint','ttitle'],
    ['Task','title'],
    ['Start Date','start_date'],
    ['End Date','end_date'],  
	['Status','task_status'], 	
    ['Priority','priority',{
        type: "render",
        render: (param) => (
            <span className={`priority--${param.value}`}>{param.value}</span>
        ),
    }],
    
];
export const announceColumn =[
    ['Title','title'],
    ['Date','start_date'],
    ['End Date','end_date'],
    ['Type','announce_type'],
    ['User','name',{
        type: "render",
        render: (param) => (
            <span>{param.value === ""?"All":param.value}</span>
        ),
    }]
];
export const holidayColumn =[
    ['Title','holiday_name'],
    ['Start Date','holiday_date'],
    ['Is Defer','is_defer',{
        type: "render",
        render: (param) => (
            <>{param.value === 1 && 'Yes'}</>
        ),
 }],
    ['Type','holiday_type']
];
export const risksColumn =[
    ['Risk ID','id',{
            type: "render",
            render: (param) => (
                <>Risk ID#{param.value}</>
            ),
     }],
    ['Risk','description'],
    ['Status','status'],
    ['Review Date','reviewdate'],
    ['Close Date','end_date'],   
];
export const milstoneColumn =[
    ['Name','title'],
    ['Start Date','start_date'],
    ['End Date','end_date'],
    ['Status','status'],
    ['Total Task','total_task'],   
];
export const documentColumn =[
    ['Category','category'],
    ['Document File','images',{
        type: "render",
        render: (param) => (
            <DocumentImages values={param.value}/>
        ),
     }],
    ['Version','docversion'],   
];
export const unitTestColumn =[
    ['ID','id',{
        type: "render",
        render: (param) => (
            <>UT ID#{param.value}</>
        ),
    }],
    ['Sprint ID','task_id',{
        type: "render",
        render: (param) => (
            <>Sprint ID#{param.value}</>
        ),
    }],
    ['Task ID','task_item_id',{
        type: "render",
        render: (param) => (
            <>Task ID#{param.value}</>
        ),
    }],
    ['System User','system_user'],
    ['Review Date','review_date'],
    ['Actions Result','actions_result'],  
     
];
export const testCaseColumn =[
    ['Test Case ID','id',{
        type: "render",
        render: (param) => (
            <>Test Case ID#{param.value}</>
        ),
    }],
    ['Sprint ID','task_id',{
        type: "render",
        render: (param) => (
            <>Sprint ID#{param.value}</>
        ),
    }],
    ['Task ID','task_item_id',{
        type: "render",
        render: (param) => (
            <>Task ID#{param.value}</>
        ),
    }],
    ['Review Date','review_date'],
    ['Testing Type','testing_type'],  
    ['Description','description'], 
    
];
export const meetingColumn =[
    ['Title','meeting_title'],
    ['Meeting Date','meeting_date'],
    ['Duration(Hrs)','meeting_duration'],
    ['Agenda','agenda']
];
export const bucketColumn =[
    ['Name','user_name'],
    ['Date','work_date'],
    ['Description','description',{
        options:{
            headerClassName:"bucket-description headerCell", 
        }
        
    }],
    ['Duration(Hrs)','duration'],
    ['Type','bucket_type'],    
];
export const bugColumn =[
    ['Bug ID','id',{
        type: "render",
        render: (param) => (
            <>Bug ID#{param.value}</>
        ),
    }],
    ['Task ID','task_item_id',{
        type: "render",
        render: (param) => (
            <>Task ID#{param.value}</>
        ),
    }],
    ['Title','title'],
    
    ['Priority','bug_priority',{
        type: "render",
        render: (param) => (
            <span className={`priority--${param.value}`}>{param.value}</span>
        ),
    }],
    ['Occurrence','occurrence'],
    ['Category','bug_type'],
    ['Assigned To','assignedto'],
];