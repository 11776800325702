import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { functionService } from "../lib/functions";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function AddProjectMemberModal({handleClose,show}) {  
  // const[errors,setErrors]=useState({});  
  const[projectTech,setProjectTech]=useState([]);
  const[projectMember,setProjectMember]=useState([]);
  const[existingMember,setExistingMember]=useState([{id:0,role:0}]);
  const[formData]=useState({});
  const[btnLoader,setBtnLoader]=useState(false);
  const[relatedUser,setRelatedUser]=useState();
	const[message,setMessage] = useState('');
  const[disabled,setDisabled]=useState(false);
  const params = useParams();
  const getTech = async()=>{
    let res = await functionService.get(`project/project-role`,{});
    if(res.status === true){
      setProjectTech(res.response.data);
    }
  }
  const getMember = async()=>{
    let res = await functionService.get(`user/list`,{limit:4000,currentPage:1});
    if(res.status === true){
      setProjectMember(res.response.data.result);
    }
  }
  const getProjectMember = async()=>{
    let res = await functionService.get(`project/member`,{projectId:params.projectid});
    if(res.status === true){
      setExistingMember(res.response.data.result.results);
    }
  }
  const handleAction = (id)=>{
      if(id !== 0){
        setExistingMember(existingMember.filter(item => item.id !== id));
      }else{
        setExistingMember( existingMember=> [...existingMember,{id:0,role:0}]);
      }
      let users = [];
      existingMember.forEach(function(obj){
        users.push(obj.id);
      }); 
      setRelatedUser(users);
  }
  const handleUpdateAction = (type,index,val)=>{
    let array = [];
    existingMember.forEach(function(element){
      array.push(element);
    })  
    if(type === 'role'){
        array[index]['role'] = val;
      }else{
        array[index]['id'] = val;
      }
      //console.log("existingMember",array);
      setExistingMember(array);
      let users = [];
      array.forEach(function(obj){
        users.push(obj.id);
      }); 
      setRelatedUser(users);
  }
  const handleSubmit = async ()=>{
    setMessage('');
    if(disabled === true){ return false;}
    setDisabled(true);
    setBtnLoader(true);
		formData.project_id = params.projectid;
    formData.relatedUsers = [];
    formData.relatedRoles = [];
    existingMember.forEach(function(element){
      formData.relatedUsers.push(element.id);
      formData.relatedRoles.push(element.role);
    })  
    let res = {};
    res = await functionService.post("project/related-user-update",formData);
    setDisabled(false);
    setBtnLoader(false);
		if(res.status === true){
			handleClose();
		 }else{
        setMessage(res.error.message);
     }
	}
  useEffect(()=>{
     getTech();
     getMember();
     getProjectMember();
     let users = [];
      existingMember.forEach(function(obj){
        users.push(obj.id);
      }); 
      setRelatedUser(users);
  },[]);
    return (
      <>
        <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-new-task"
        >          
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-new-task">
            Add Members
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {existingMember && existingMember.map((data,index)=>(

                     
            <Row key={index}>         
              <Col md = {5}>
                <Form.Label>Name</Form.Label>
                <Form.Select aria-label="task" className="form-control mb-3" value={data.id} onChange={(e)=>{handleUpdateAction('member',index,e.target.value)}}>  
                  <option value="">Select Member</option>                
                  {projectMember && projectMember.map((obj,index)=>(
                    <option key={index} value={obj.id}>{obj.name}</option>
                  ))} 
                </Form.Select>
              </Col>
              <Col md = {5}>
                <Form.Label>Position</Form.Label>
                <Form.Select aria-label="task" className="form-control mb-3" value={data.role} onChange={(e)=>{handleUpdateAction('role',index,e.target.value)}}> 
                  <option value="" >Select Position</option>
                  {projectTech && projectTech.map((obj,index)=>(
                    <option key={index} value={obj.role_id}>{obj.project_role}</option>
                  ))} 
                
                </Form.Select>  
              </Col>
              <Col md = {2} className="addMemberIconBox">
                  
                  
                  { existingMember.length === (index+1) ? 
                  <span className="add--icon fa--icon" onClick={()=>{handleAction(0)}}><FontAwesomeIcon icon={faPlus}  className="sm--icon"/></span>
                :
                <span className="delete--icon fa--icon me-3" onClick={()=>{handleAction(data.id)}}><FontAwesomeIcon icon={faTrash}  className="sm--icon"/></span>  
                }
              </Col>
            </Row>   
               ))}      
                                                             
          </Form>
          {message && <>{message}</>}
        </Modal.Body>

        <Modal.Footer>          
          <Button className="add-btn" onClick={handleSubmit}>{btnLoader && <FontAwesomeIcon icon={faSpinner} spin />} Save</Button>
          <Button variant="outline-dark close-btn" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>     
        
    </>
    );
  }
  
 