import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { functionService } from "../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import FileUploader from "./FileUploader";
import { adddocfield, validate } from "./ValidationChecked";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function AddDocModal({ handleModalState, setSandleModalState, handleClose, show, isCreate=0 }) {
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState('');
  const params = useParams();
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [btnLoader, setBtnLoader] = useState(false);  
  const[trigger,setTrigger]=useState(0);
  const handleAction = async (isClose) => {
    if (validate(formData, adddocfield) !== 0) { setErrors(validate(formData, adddocfield)); return false; }
    setMessage('');
    if (disabled === true) { return false; }
    setTrigger(isClose);
    setDisabled(true);
    setBtnLoader(true);
    formData.project_id = params.projectid;
    let res = {};
    if (isCreate === 0) {
      res = await functionService.post("documents/save", formData);
    } else {
      formData.id = isCreate;
      res = await functionService.update("documents/update", formData);
    }
    setDisabled(false);
    setBtnLoader(false); 
    formData.category ="";
    if (res.status === true) {
      isClose === 1 && handleClose();
      setSandleModalState(!handleModalState);
    } else {
      setMessage(res.error.message);
    }
  }


  useEffect(() => {
    setFormData({});
  }, [isCreate, show]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-project-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-project-modal">
            {isCreate === 0 ? 'Add New' : 'Update'} Document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Document Type</Form.Label>
                  <Form.Select aria-label="task" className="form-control" value={formData && formData.category} onChange={(e) => { setFormData({ ...formData, 'category': e.target.value }) }}>
                    <option value="">Select Document</option>
                    <option value="Project Proposal">Project Proposal</option>
                    <option value="Software Requirement Specification">Software Requirement Specification</option>
                    <option value="Project Plan">Project Plan</option>
                    <option value="Test Plan">Test Plan</option>
                    <option value="Project Initaition">Project Initaition</option>
                    <option value="Estimation">Estimation</option>
                    <option value="DAR">DAR</option>
                    <option value="System Design">System Design</option>
                    <option value="Build Prepration Notes">Build Prepration Notes</option>
                    <option value="Releasing Notes">Releasing Notes</option>
                    <option value="Team Meeting">Team Meeting</option>
                    <option value="Closure Report">Closure Report</option>
                    <option value="Other Documents">Other Documents</option>
                  </Form.Select>
                  {errors && <p className="error">{errors['category']}</p>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="">
                  {/* <Form.Label>Document Type</Form.Label> */}
                  <FileUploader formData={formData} setFormData={setFormData} limit={1} />
                </Form.Group>
              </Col>
            </Row>


          </Form>
        </Modal.Body>
        <Modal.Footer>
          {isCreate === 0 ? <>
            <Button className="add-btn" onClick={() => {setTrigger(0); handleAction(0) }}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Add</Button>
            <Button className="add-btn" onClick={() => {setTrigger(0); handleAction(1) }}>{(btnLoader && trigger === 1) && <FontAwesomeIcon icon={faSpinner} spin />} Add & Close</Button> </>

            :
            <Button className="add-btn" onClick={() => { handleAction(0) }}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Update</Button>
          }
          <Button variant="outline-dark close-btn" onClick={handleClose}>
            Close
          </Button>
          {message !== '' && message}
        </Modal.Footer>
      </Modal>

    </>
  );
}


