import React,{useState,useEffect} from "react";
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import { functionService } from "../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { milestonefield, validate } from "./ValidationChecked";
import SunEditor from "./SunEditor";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function MilestonesModal({handleModalState,setSandleModalState,handleClose,show,isCreate=0}) {   
    const[formData,setFormData]=useState({});
	const[message,setMessage] = useState('');
	const params = useParams();
  const[disabled,setDisabled]=useState(false);
  const[btnLoader,setBtnLoader]=useState(false);
  const[errors,setErrors]=useState({}); 
  const[trigger,setTrigger]=useState(0); 
 const currentUser = functionService.getCurrentUser();

	const handleAction = async (isClose)=>{
    if(validate(formData,milestonefield) !== 0){ setErrors(validate(formData,milestonefield)); return false;}
		setMessage('');
    if(disabled === true){ return false;}
    setBtnLoader(true);
    setDisabled(true);
		formData.project_id =params.projectid;
    formData.user_id = currentUser.sub;
    let res = {};
    if (isCreate === 0) {
        res = await functionService.post("milestones/save",formData);
        formData.start_date="";
        formData.end_date="";
        formData.title="";
        formData.status="";
    }else{
      formData.id = isCreate;
      res = await functionService.update("milestones/update",formData);
      handleClose();
    }
		setDisabled(false);
		setBtnLoader(false);
		if(res.status === true){
			isClose === 1 && handleClose();
			setSandleModalState(!handleModalState);
        }else{
            setMessage(res.error.message);
        }
	}
  const getItem = async()=>{
    let res = await functionService.get(`milestones/get/${isCreate}`,{id:isCreate});
    if(res.status === true){
       setFormData(res.response.data);
    }
  }
  useEffect(()=>{
    if(isCreate !== 0 && show === true){
      getItem();
     }
     setFormData({});
  },[isCreate,show]);
    return (
      <>
        <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-project-modal"
        >          
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-project-modal">
            {isCreate === 0 ? 'Add New' : 'Update' } Milestone
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="StartDate">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control type="date" placeholder="Start  Date" value={formData && functionService.getFormatedDate(formData.start_date)} onChange={(e)=>{setFormData({...formData,'start_date':e.target.value})}}/>
                  {errors && <p className="error">{errors['start_date']}</p>}
                </Form.Group>              
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="EndDate">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control type="date" placeholder="End Date" value={formData && functionService.getFormatedDate(formData.end_date)} onChange={(e)=>{setFormData({...formData,'end_date':e.target.value})}}/>
                  {errors && <p className="error">{errors['end_date']}</p>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
             <Col md = {6}>
              <Form.Group className="mb-3" controlId="Title">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" placeholder="Title" value={formData && formData.title} onChange={(e)=>{setFormData({...formData,'title':e.target.value})}}/>
              {errors && <p className="error">{errors['title']}</p>}
             </Form.Group>
            </Col>
            <Col md = {6}>
                <Form.Group className="mb-3" controlId="Title">
                  <Form.Label>Status</Form.Label>
                  <Form.Select aria-label="status" className="form-control" value={formData && formData.status} onChange={(e)=>{setFormData({...formData,'status':e.target.value})}}>
                    <option>Status</option>
                    <option value="Open">Open</option>
                    <option value="Close">Close</option>
                    <option value="Ongoing">Ongoing</option>
                  </Form.Select> 
                  {errors && <p className="error">{errors['status']}</p>}
                </Form.Group>                            
            </Col>
              
            </Row>
            <Row>              
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Description</Form.Label>
                <SunEditor value={formData && formData.description} onChange={(e)=>{setFormData({...formData,'description':e})}}/>
                {errors && <p className="error">{errors['description']}</p>}
              </Form.Group>
            </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
            {isCreate === 0 ? <>          
             <Button className="add-btn" onClick={()=>{setTrigger(0); handleAction(isCreate)}}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Create</Button>
             <Button className="add-btn" onClick={()=>{setTrigger(1); setTrigger(1);handleAction(1)}}>{(btnLoader && trigger === 1) && <FontAwesomeIcon icon={faSpinner} spin />} Create & Close</Button> </> 
             :
              <Button className="add-btn"  onClick={()=>{setTrigger(0); handleAction(isCreate)}}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Update</Button>
            }
          <Button variant="outline-dark close-btn" onClick={handleClose}>
            Close
          </Button>
		  {message !== '' && message}
        </Modal.Footer>
        </Modal>        
        
      </>
    );
  }
  
 