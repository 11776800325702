import React from "react";
import { API_HOME_PATH } from "../../lib/api";
function DocumentImages({values}) {

  return (
           <>
           {
               values && (JSON.parse(values)).map((obj,index)=>(
                 <a key={index} href={`${API_HOME_PATH}${obj.name}`} target={"_blank"}>{obj.name}</a>  
               ))
           }
           </>
  );
  
}

export default DocumentImages;