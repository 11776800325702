import React, { Component } from "react";
import {Button,Col,FormGroup,FormControl,InputGroup, Form} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPaperclip,faPaperPlane, faVideo, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import Chatt from '../static/images/chatt.png';
import {
  MessageList,
  Navbar as NavbarComponent,
  Avatar
} from "react-chat-elements";

/**
 *
 * ChatBox Component
 *
 * displays all the messages from chat history.
 * renders message text box for input.
 */

export default class ChatBox extends Component {
  state = {
    messageText: ""
  };
  /**
   *
   * Sends a message only if it is not falsy.
   */
  onSendClicked() {
    if (!this.state.messageText) {
      return;
    }
    this.props.onSendClicked(this.state.messageText);
    this.setState({ messageText: "" });
  }
  onMessageInputChange(e) {
    this.setState({ messageText: e.target.value });
  }
  /**
   *
   * @param {KeyboardEvent} e
   *
   * listen for enter pressed and sends the message.
   */
  onMessageKeyPress(e) {
    if (e.key === "Enter") {
      this.onSendClicked();
    }
  }

  render() {
    return (
      <div>
        {this.props.targetUser ? (
          <div>
            <NavbarComponent
              left={
                <div>
                  <Col>
                    <p className="navBarText">
                      <span
                        onClick={this.props.onBackPressed}                      
                      ><FontAwesomeIcon icon={faChevronLeft} size="sm" className="me-3" /></span> 
                    </p>
                  </Col>
                  <Avatar
                    src={`../static/images/avatar/2.jpg`}
                    alt={"logo"}
                    size="large"
                    type="circle flexible"
                  />
                  <p className="navBarText">{this.props.targetUser.name}</p>                  
                </div>                           
              }
              right={
                <div>
                  <span className="me-3"><FontAwesomeIcon icon={faVideo} size= "2x" className=""/></span>
                  <span className="me-3"><FontAwesomeIcon icon={faPhoneAlt} size= "2x" className=""/></span>                 
                  
                </div>
              }
              
            />
            <MessageList
              className="message-list"
              lockable={true}
              toBottomHeight={"100%"}
              dataSource={this.props.targetUser.messages}
            />
            
            <div className="send--message--panel1">                            
              <Form.Group className="text-box" >                                
                <div className="attachicon"><FontAwesomeIcon icon={faPaperclip} size= "2x" className=""/> </div>                                               
                <Form.Control 
                  type="text" 
                  rows={2} 
                  value={this.state.messageText}
                  onChange={this.onMessageInputChange.bind(this)}
                  onKeyPress={this.onMessageKeyPress.bind(this)}
                  placeholder="Type a message here (Limit 3000 characters)..."
                  ref="messageTextBox"
                  className="messageTextBox"
                  maxLength="3000"
                  autoFocus
                />   
                <div
                    disabled={!this.state.messageText}
                    className="sendmsgicon"
                    onClick={this.onSendClicked.bind(this)}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} className="sendmsgicon"/>
                  </div> 
                  {/* <div className="sendmsgicon"><FontAwesomeIcon icon={faPaperPlane} size= "sm" className="sendmsgicon"/> </div>                       */}
                </Form.Group>                           
                            
            </div>
          </div>
        ) : (
          <div>
            <div>
              
              <div className="text-center mt-5">
              <h1>Hello, {(this.props.signedInUser || {}).name}!</h1>
              <p>Select a friend to start a chat.</p>
                <Image src={Chatt} className="mt-5"/></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
