import React, { useContext, useState } from "react";
import { Col, Container,Row} from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { UserContext } from "../../lib/UserContext";
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Graphic1 from '../..//static/images/graphic1.png';
import Logo from '../../static/images/logo.png';
import EmailIcon from '../../static/images/email-icon.png';
import LockIcon from '../../static/images/lock-icon.png';
import './Login.css';
import { functionService } from "../../lib/functions";

function Login() {
    const [loginUser, setLoginUser] = useContext(UserContext);
    const[formData,setFormData] = useState({email:'',password:''});
    const[error,setError]=useState();
    const history = useHistory();
    const handleLogin = async(e)=>{
        e.preventDefault();
        setError('');
        let res = await functionService.login(formData.email,formData.password);
       
        if(res.status === true){           
            setLoginUser({id:0,name:res.response.data.name,token:res.response.data.token});
			localStorage.setItem("currentUserName",res.response.data.name);
            localStorage.setItem("currentUser",res.response.data.token);
            history.push("/dashboard");
        }else{
            setError(res.error.message);
        }
        

        
    }
  return (
            <Container fluid className="loginpagecontainer">
                <Row >
                    <Col md={7} className="text-center">
                        <Image src={Graphic1}></Image>
                        <h2>Project Management System</h2>
                    </Col>
                    <Col md={5}>
                        <div className="Loginbox">
                            <div className="logo"><Image src={Logo}></Image></div>
                            <Form>
                                <Form.Group className="mb-3 inputlog" controlId="formBasicEmail">
                                    <Form.Label className="login-form-label">Username</Form.Label>
                                    <Form.Control type="text" className="login-form-control" placeholder="Enter Username"onChange={(e)=>{setFormData({...formData,email:e.target.value})}} />
                                    <Image src={EmailIcon} className="icon" />
                                </Form.Group>

                                <Form.Group className="mb-3 inputlog" controlId="formBasicPassword">
                                    <Form.Label className="login-form-label">Password</Form.Label>
                                    <Form.Control type="password" className="login-form-control" placeholder="Password" onChange={(e)=>{setFormData({...formData,password:e.target.value})}}/>
                                    <Image src={LockIcon} className="icon"/>
                                </Form.Group>
                                <div className="forgot_password"> <Link to="">Forgot Password?</Link> </div>                               
                                <Button className="loginbtn" type="submit" onClick={(e)=>{handleLogin(e)}}> Login </Button>
                            </Form>
                            {error && <p className="error-message">{error}</p>}
                        </div>
                    </Col>
                </Row>
            </Container>
  );
  
}

export default Login;