import React from "react";
const HeaderCell = ({ title, search, isSearch = false }) => {

  return (
    <>
      {title}
     
    </>
  );

};

export const renderHeaderCell = (option) => {
  if (option) {
    if (typeof option.search !== 'undefined') {
      return (param) => (
        <HeaderCell 
          title={param.field} 
          search={option.search} 
          // isSearch={option.isSearch} 
        />
      );
    }
  }
  return (param) => (param.field);
};