import React from "react";
import { Tooltip } from "react-bootstrap";



export const ToolTipCell = ({ cellData }) => {


  return (
    <Tooltip
      className={"tooltip"}
      title={cellData || ""}
      aria-label=""
    >
      <div component="div" className={"innerTootltip"}>
        {cellData}
      </div>
    </Tooltip>
  );
};


export const ActionCell = ({ action, contract }) => {



  const [label, icons, checkAction] = action[0];
  const handleAction = action[1];

  const iconIndex = (typeof checkAction === 'function') ? checkAction(contract) : 0;

  //console.log("[ActionCell]", action, contract, label, checkAction, handleAction);
  console.log("[ActionCell]", iconIndex);

  return (
    <>{ iconIndex === -1 ? '' :
    <button
      onClick={() => {
        if (((iconIndex === 0 || (typeof iconIndex !== 'number' && !!iconIndex)) || iconIndex === 2)
          && typeof handleAction === 'function') handleAction(contract);
      }}
      className={(iconIndex === 2 ) ? "actionCell btnBgLess f_size_22 disabled" : (!!iconIndex ? "actionCell btnBgLess f_size_22" : "actionCellDisabled")}
    >
      {
        Array.isArray(icons)
          ?
          (
            icons.length > 2 && typeof iconIndex === 'number' && iconIndex < icons.length
              ?
              icons[iconIndex]
              :
              (
                icons.length > 1
                  ?
                  (iconIndex ? icons[0] : icons[1])
                  :
                  icons[0]
              )
          )
          :
          icons
      }
    </button>
    }
    </>
  );

};


export const renderActionCells = (actions) => {
  // console.log("[renderActionCell] actions", actions);

  return (param) => {
    const contract = param.value;

    return (
      !!actions && typeof actions === 'object' && actions.length > 0
        ?
        actions.map((action, index) => <ActionCell action={action} contract={contract} key={index} />)
        :
        <></>
    );
  }
};