import React,{useState,useEffect} from "react";
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import { functionService } from "../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { meetingfield, validate } from "./ValidationChecked";
import SunEditor from "./SunEditor";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function MeetingsModal({handleModalState,setSandleModalState,handleClose,show,isCreate=0}) {   
  const[formData,setFormData]=useState({});
	const[message,setMessage] = useState('');
  const[disabled,setDisabled]=useState(false);
  const[btnLoader,setBtnLoader]=useState(false);
	const params = useParams();
  const[errors,setErrors]=useState({});
  const currentUser = functionService.getCurrentUser();
  const[trigger,setTrigger]=useState(0);

	const handleAction = async ( isClose)=>{
    if(validate(formData,meetingfield) !== 0){ setErrors(validate(formData,meetingfield)); return false;}
		setMessage('');
    if(disabled === true){ return false;}
    
    setDisabled(true);
    setBtnLoader(true);
		formData.project_id =params.projectid;
    formData.user_id = currentUser.sub;
    let res = {};
    if (isCreate === 0) {
        res = await functionService.post("meetings/save",formData);
        formData.meeting_title ="";
        formData.agenda = "";
        formData.meeting_date="";
        formData.meeting_duration ="";
        formData.meeting_description ="";
    }else{
      formData.id = isCreate;
      res = await functionService.update("meetings/update",formData);
      handleClose();
    }
		setBtnLoader(false);
		setDisabled(false);    
    isClose === 1 && handleClose();

		if(res.status === true ){     
			setSandleModalState(!handleModalState);
        }else{
            setMessage(res.error.message);
        }          
	}
  const getItem = async()=>{
    let res = await functionService.get(`meetings/get/${isCreate}`,{id:isCreate});
    if(res.status === true){
       setFormData(res.response.data);
    }
  }
  useEffect(()=>{
    if(isCreate !== 0 && show === true){
      getItem();
     }
     setErrors({});
     setFormData({});
  },[isCreate,show]);
    return (
      <>
        <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-project-modal"
        >          
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-project-modal">
            {isCreate === 0 ? 'Add New' : 'Update' } Meeting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="Meeting Title">
                  <Form.Label>Meeting Title</Form.Label>
                  <Form.Control type="text" placeholder="Meeting Title" value={formData && formData.meeting_title} onChange={(e)=>{setFormData({...formData,'meeting_title':e.target.value})}}/>
                  {errors && <p className="error">{errors['meeting_title']}</p>}
                </Form.Group>              
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="Agenda">
                  <Form.Label>Agenda</Form.Label>
                  <Form.Control type="text" placeholder="Agenda" value={formData && formData.agenda} onChange={(e)=>{setFormData({...formData,'agenda':e.target.value})}}/>
                  {errors && <p className="error">{errors['agenda']}</p>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
             <Col md = {6}>
              <Form.Group className="mb-3" controlId="">
              <Form.Label>Meeting Date</Form.Label>
              <Form.Control type="date" placeholder="Meeting Date" value={formData && functionService.getFormatedDate(formData.meeting_date)} onChange={(e)=>{setFormData({...formData,'meeting_date':e.target.value})}}/>
              {errors && <p className="error">{errors['meeting_date']}</p>}
              </Form.Group>
            </Col>
            <Col md = {6}>
                <Form.Group className="mb-3" controlId="">
                <Form.Label>Meeting Duration</Form.Label>
                <Form.Control type="text" placeholder="Meeting Duration" value={formData && formData.meeting_duration} onChange={(e)=>{setFormData({...formData,'meeting_duration':e.target.value})}}/>
                {errors && <p className="error">{errors['meeting_duration']}</p>}
                </Form.Group>
            </Col>              
            </Row>
            <Row>              
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Description</Form.Label>
                <SunEditor value={formData && formData.meeting_description} onChange={(e)=>{setFormData({...formData,'meeting_description':e})}}/>
                {errors && <p className="error">{errors['meeting_description']}</p>}
              </Form.Group>
            </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
            {isCreate === 0 ? <>          
             <Button className="add-btn" onClick={()=>{setTrigger(0);handleAction(0)}}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Create</Button>
             <Button className="add-btn" onClick={()=>{setTrigger(1);handleAction(1)}}>{(btnLoader && trigger === 1) && <FontAwesomeIcon icon={faSpinner} spin />} Create & Close</Button> </>
              :
              <Button className="add-btn"  onClick={()=>{setTrigger(0);handleAction()}}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Update</Button>
            }
          
          
          <Button variant="outline-dark close-btn" onClick={handleClose}>
            Close
          </Button>
		      {message !== '' && message}
        </Modal.Footer>
        </Modal>        
        
      </>
    );
  }
  
 