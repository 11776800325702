import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { functionService } from "../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { unitTestField, validate } from "./ValidationChecked";
import FileUploader from "./FileUploader";
import SunEditor from "./SunEditor";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function UnitTestingModal({ handleModalState, setSandleModalState, handleClose, show, isCreate = 0}) {
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState('');
  const params = useParams();
  const [disabled, setDisabled] = useState(false);
  const [rows, setRows] = useState([]);
  const [itemRows, setItemRows] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const currentUser = functionService.getCurrentUser();
  const[trigger,setTrigger]=useState(0);

  const handleAction = async (isClose) => {
    if (validate(formData, unitTestField) !== 0) { setErrors(validate(formData, unitTestField)); return false; }
    setMessage('');
    if (disabled === true) { return false; }
    setDisabled(true);
    setBtnLoader(true);
    formData.project_id = params.projectid;
    formData.user_id = currentUser.sub;
    let res = {};
    if (isCreate === 0) {
      res = await functionService.post("unittesting/save", formData);
      formData.actions_result="";
      formData.expected_result="";
      formData.system_user ="";
      formData.task_id ="";
      formData.task_item_id ="";
      formData.review_date ="";
    } else {
      formData.id = isCreate;
      res = await functionService.update("unittesting/update", formData);
      handleClose();
    }
    setDisabled(false);
    setBtnLoader(false);

    if (res.status === true) {
      isClose === 1 && handleClose();
      setSandleModalState(!handleModalState);
    } else {
      setMessage(res.error.message);
    }
  }
  const getItem = async () => {
    let res = await functionService.get(`unittesting/get/${isCreate}`, { id: isCreate });
    if (res.status === true) {
      setFormData(res.response.data);
      getList('last', res.response.data.task_id);
    }
  }
  const getList = async (path = 'list', id = 0) => {
    let parameter = { projectId: params.projectid, limit: 3000, currentPage: 1 };
    if (id !== 0) { parameter['id'] = id; }
    let res = await functionService.get(`sprints/${path}`, parameter);
    if (res.status === true) {
      if ((res.response.data).hasOwnProperty("total")) {
        setRows(res.response.data.result);
      } else {
        setItemRows(res.response.data.tasklist);
      }

    }
  }

  useEffect(() => {
    getList();
    if (isCreate !== 0 && show === true) {
      getItem();
    }
    setFormData({});
  }, [isCreate, show]);
  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-project-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-project-modal">
            {isCreate === 0 ? 'Add New' : 'Update'} Unit Testing
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Sprint</Form.Label>
                  <Form.Select aria-label="status" className="form-control " value={formData && formData.task_id} onChange={(e) => { setFormData({ ...formData, 'task_id': e.target.value }); getList('last', e.target.value) }}>
                    <option value=""> Select </option>
                    {(rows.length > 0) && rows.map((obj, index) => (
                      <option key={index} value={obj.id}>{obj.title}</option>
                    ))}
                  </Form.Select>
                  {errors && <p className="error">{errors['task_id']}</p>}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Task</Form.Label>
                  <Form.Select aria-label="status" className="form-control " value={formData && formData.task_item_id} onChange={(e) => { setFormData({ ...formData, 'task_item_id': e.target.value }) }}>
                    <option value=""> Select </option>
                    {(itemRows.length > 0) && itemRows.map((obj, index) => (
                      <option key={index} value={obj.id}>{obj.title}</option>
                    ))}
                  </Form.Select>
                  {errors && <p className="error">{errors['task_item_id']}</p>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="createdate">
                  <Form.Label>Review Date</Form.Label>
                  <Form.Control type="date" placeholder="Review Date" value={formData && functionService.getFormatedDate(formData.review_date)} onChange={(e) => { setFormData({ ...formData, 'review_date': e.target.value }) }} />
                  {errors && <p className="error">{errors['review_date']}</p>}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="System">
                  <Form.Label>System User</Form.Label>
                  <Form.Control type="text" placeholder="System User" value={formData && formData.system_user} onChange={(e) => { setFormData({ ...formData, 'system_user': e.target.value }) }} />
                  {errors && <p className="error">{errors['system_user']}</p>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="test-steps">
                  <Form.Label>Actions</Form.Label>
                  <SunEditor placeholder="Actions" value={formData && formData.actions_result} onChange={(e) => { setFormData({ ...formData, 'actions_result': e }) }} />
                  {errors && <p className="error">{errors['actions_result']}</p>}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="expected-result">
                  <Form.Label>Expected Result</Form.Label>
                  <SunEditor placeholder="Expected Result" value={formData && formData.expected_result} onChange={(e) => { setFormData({ ...formData, 'expected_result': e }) }} />
                  {errors && <p className="error">{errors['expected_result']}</p>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FileUploader formData={formData} setFormData={setFormData} />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {isCreate === 0 ? <>
            <Button className="add-btn" onClick={() => {setTrigger(0); handleAction(0) }}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Create</Button>
            <Button className="add-btn" onClick={() => {setTrigger(1); handleAction(1) }}>{(btnLoader && trigger === 1) && <FontAwesomeIcon icon={faSpinner} spin />} Create & Close</Button> </>

            :
            <Button className="add-btn" onClick={() => {setTrigger(0); handleAction(0) }}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Update</Button>
          }
          <Button variant="outline-dark close-btn" onClick={handleClose}>
            Close
          </Button>
          {message !== '' && message}
        </Modal.Footer>
      </Modal>

    </>
  );
}

