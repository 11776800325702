import React from "react";
import {Col} from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';

function DashboardEmpperformance ({ empName, stylebg }){     

    return (
        <>
            <Col className="emp-performance-box" style={stylebg}>   
                <div className="date--">
                    <p><span>Mon</span> 12</p> 
                </div>                         
                <div className="working-hour">
                    <p className="working-lbl">Total Working Hours</p> 
                    <p className="working-val">5h 30min</p> 
                </div>
                <div className="productivity--">
                    <p>Productivity</p>
                    <ProgressBar variant="success" now={40} />
                </div>               
                           
            </Col>
        </>
    )}

export default DashboardEmpperformance;