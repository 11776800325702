import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { UserContext } from "../../lib/UserContext";
function AddProject() {
    const [loginUser] = useContext(UserContext);
    console.log(loginUser);
  return (
    <>
    <Header />
    
    <Container fluid>
        <Row className="sidebar--panel">
           <Sidebar/> 
           <Col md={10}>
                Projects
            </Col>
        </Row>
    </Container>
<Footer/>
</> 
  );
  
}
export default AddProject;