import React,{useState} from "react";
import {Modal, Button, Form } from 'react-bootstrap';
import { messageField, validate } from "./ValidationChecked";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function MessageModal({handleMessageClose,show}) {     

  // const[errors,setErrors]=useState({});
	const[btnLoader,setBtnLoader]=useState(false);
  const handleAction = async ()=>{
    // if(validate(formData,messageField) !== 0){ setErrors(validate(formData,messageField)); return false;}
  }
    return (
      <>
        <Modal
        show={show}
        size="lg"
        onHide={handleMessageClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-new-task"
        >          
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-new-task">
            Message
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
                          
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Title</Form.Label>
                  <Form.Control type="text" placeholder="Title" />
                </Form.Group>                                   
                        
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={10} />
              </Form.Group>              

            <Form.Group controlId="formFileMultiple" className="mb-3">
                <Form.Label>Choose Files</Form.Label>
                <Form.Control type="file" multiple />
            </Form.Group>         
       
          </Form>
        </Modal.Body>
        <Modal.Footer>          
          <Button className="add-btn" onClick={()=>{handleAction()}}>{btnLoader && <FontAwesomeIcon icon={faSpinner} spin />} Create</Button>
          <Button variant="outline-dark close-btn" onClick={handleMessageClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>        
        
      </>
    );
  }
  
 