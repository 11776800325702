import React, { useContext,useEffect,useState } from "react";
import Container from 'react-bootstrap/Container';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Avtar2 from '../static/images/avatar/2.jpg';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { UserContext } from "../lib/UserContext";
import { functionService } from "../lib/functions";
function Header (){  
  const [loginUser] = useContext(UserContext);
  const[notification,setNotification] = useState(0);

  const history = useHistory();
    const handleLogout = async(e)=>{
        e.preventDefault();        
        history.push("/login")
    }
    const longPolling = async()=>{
      let res = await functionService.get("notification/unread",{});
      if(res.status === true){
       setNotification(res.response.data[0].cnt)
      }
      //setTimeout(function(){longPolling()},15000);
    }
    useEffect(()=>{
      longPolling();
    },[]);
  
    return (
      <>
      <Navbar className="main-header">
        <Container fluid>
          <Navbar.Brand href="#home" className="nav_logo">PMS</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <div className="notifications_icon">
              <Link to="/notification">
                <FontAwesomeIcon icon={faBell}  size="2x"/>
                {notification > 0  && <span className="redbell">{notification}</span> }
              </Link>
              </div>
              <div className="notifications_icon ">
              <Link to="/discussion">
                <FontAwesomeIcon icon={faEnvelope}  size="2x"/>
                <span className="redbell">9</span>
                </Link>
              </div>

              <Image roundedCircle src={Avtar2} className="avatar_img" />              
              <Dropdown >
                <Dropdown.Toggle id="dropdown-basic" className="Logged_user">
                  {(loginUser && loginUser.name !== "") ? loginUser.name : 'Your Name' }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1"><FontAwesomeIcon icon={faUser}  size="sm" className = "me-2"/> Profile</Dropdown.Item>
                  <Dropdown.Item href="#/action-2" onClick={(e)=>{handleLogout(e)}}><FontAwesomeIcon icon={faSignInAlt}  size="sm" className = "me-2"/> Log Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      
      </> 
    );   
}

export default Header;
