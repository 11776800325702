import axios from "axios";
import {API_PATH} from "./api";
import jwt_decode from "jwt-decode";
export const functionService = {
    login,
    post,
    get,
    update,
    remove,
	getCurrentUser,
    uploadFile,
    checkEditStatus,
	getFormatedDate
}
function checkEditStatus(id,arr=[]){
    let user = getCurrentUser();
    if(arr.includes(id)){
        return true;
    }else if(user && user.role === "admin"){
        return true;
    }
    return false;
}
function getCurrentUser(){
	if(localStorage.getItem("currentUser")){
		return jwt_decode(localStorage.getItem("currentUser"));
	}
	return {role:''};
}
function login(username, password) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };

    let dataPost = {
        email: username,
        password: password,
   }
   return axios.post(`${API_PATH}auth/authenticate`, dataPost, axiosRequestOptions)
        .then(response => {
             return {status:true,response};
        })
        .catch((error) => {
            return {status:false,error};
        });
}
function post(path,formdata={}) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    let currentUser = localStorage.getItem("currentUser");
    if(currentUser){
        axiosRequestOptions['headers']['authorization'] = currentUser;
        let form = {};
        form['token'] = currentUser;
        let user = getCurrentUser();
        form['login'] = user.sub;
        form['role'] = user.role;
        axiosRequestOptions['headers']['body'] = JSON.stringify(form);
    }
   return axios.post(`${API_PATH}${path}`, formdata, axiosRequestOptions)
        .then(response => {
             return {status:true,response};
        })
        .catch((error) => {
            return {status:false,error};
        });
}
function get(path,formdata={}) {
    
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    let currentUser = localStorage.getItem("currentUser");
    if(currentUser){
        axiosRequestOptions['headers']['authorization'] = currentUser;
        formdata['token'] = currentUser;
        let user = getCurrentUser();
        formdata['login'] = user.sub;
        formdata['role'] = user.role;
        axiosRequestOptions['headers']['body'] = JSON.stringify(formdata);
    }
   return axios.get(`${API_PATH}${path}`, axiosRequestOptions)
        .then(response => {
             return {status:true,response};
        })
        .catch((error) => {
            return {status:false,error};
        });
}
function update(path,formdata={}) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    let currentUser = localStorage.getItem("currentUser");
    if(currentUser){
        axiosRequestOptions['headers']['authorization'] = currentUser;
        let form = {};
        form['token'] = currentUser;
        let user = getCurrentUser();
        form['login'] = user.sub;
        form['role'] = user.role;
        axiosRequestOptions['headers']['body'] =  JSON.stringify(form);
    }
   return axios.put(`${API_PATH}${path}`, formdata, axiosRequestOptions)
        .then(response => {
             return {status:true,response};
        })
        .catch((error) => {
            return {status:false,error};
        });
}
function remove(path,formdata={}) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    let currentUser = localStorage.getItem("currentUser");
    if(currentUser){
        axiosRequestOptions['headers']['authorization'] = currentUser;
        formdata['token'] = currentUser;
        let user = getCurrentUser();
        formdata['login'] = user.sub;
        formdata['role'] = user.role;
        axiosRequestOptions['headers']['data'] = JSON.stringify(formdata);
    }
   return axios.delete(`${API_PATH}${path}`, axiosRequestOptions)
        .then(response => {
             return {status:true,response};
        })
        .catch((error) => {
            return {status:false,error};
        });
}
function uploadFile(file) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    const formData = new FormData();
    formData.append("file", file);
   
    let currentUser = localStorage.getItem("currentUser");
    if(currentUser){
         axiosRequestOptions['headers']['authorization'] =  currentUser;
         formData.append("token", currentUser);
         //axiosRequestOptions['headers']['data'] = formData;
    }
   return axios.post(`${API_PATH}media/save`, formData)
        .then(response => {
             return {status:true,response};
        })
        .catch((error) => {
            return {status:false,error};
        });
}
function getFormatedDate(data = new Date()){
	let dateObject = new Date (data);
	let date = dateObject.getFullYear()+"-"+(("0" +(dateObject.getMonth()+1)).slice(-2))+"-"+(("0" +dateObject.getDate()).slice(-2));
	return date;
}