import React,{useState,useEffect} from "react";
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { functionService } from "../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function TaskRiskDetailModal({handleModalState,setSandleModalState,handleClose,show,isCreate=0}) {   
  
    return (
      <>
        <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-project-modal"
        >          
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-project-modal">
            Risk Id
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="task-bug-body">
        
                                                <div className="task-bug-info">
                                                    <h3 className="mb-4">Risk Name- UI Designs</h3>                                                    
                                                </div>
                                                <div className="bug-detailing"><p className="bug-label">Identification Date :</p><p  className="bug-value">20-12-2020</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Review Date :</p><p  className="bug-value">25-12-2020</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Risk Category :</p><p  className="bug-value">External</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Risk Owner :</p><p  className="bug-value">Ower</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Status :</p><p  className="bug-value">Close</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Risk End Date :</p><p  className="bug-value">04-01-2021</p></div>

                                                <div className="bug-detailing">
                                                    <p className="bug-label">Risk Description :</p>
                                                    <p  className="bug-value1">1) Both useEffect and useLayoutEffect will be fired after layout and paint. However, useLayoutEffect will fire synchronously 
                                                        before the next paint. I'd say if you really need visual consistency then use useLayoutEffect, otherwise, useEffect should 
                                                        suffice. 
                                                        2) You're right about that! In a functional component createRef will create a new ref every time the component is 
                                                        being rendered. Using useRef is the better option. I'll update my answer</p>
                                                </div>
                                                                                           
        </Modal.Body>
        {/* <Modal.Footer>            
          <Button variant="outline-dark close-btn" onClick={handleClose}>
            Close
          </Button>		  
        </Modal.Footer> */}
        </Modal>        
        
      </>
    );
  }
  
 