import React,{useState,useEffect} from "react";
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import { functionService } from "../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AutoFillDropDown from "./AutoFillDropDown";
import { addbugfield, validate } from "./ValidationChecked";
import FileUploader from "./FileUploader";
import SunEditor from "./SunEditor";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AddBugModal({handleModalState,setSandleModalState,handleClose,show,isCreate=0}) {   
  const[formData,setFormData]=useState({});
	const[message,setMessage] = useState('');
   const params = useParams();
  const[disabled,setDisabled]=useState(false);
  const[rows,setRows] = useState([]);
  const[itemRows,setItemRows] = useState([]);
  const[errors,setErrors]=useState({}); 
  const[btnLoader,setBtnLoader]=useState(false);
  const[trigger,setTrigger]=useState(0); 
  const currentUser = functionService.getCurrentUser();

	const handleAction = async (isClose)=>{
    if(validate(formData,addbugfield) !== 0){ setErrors(validate(formData,addbugfield)); return false;}
		setMessage('');
    if(disabled === true){ return false;}
    setBtnLoader(true);
    setDisabled(true);
		formData.project_id =params.projectid;
    formData.user_id = currentUser.sub;
    let res = {};
    if (isCreate === 0) {
        res = await functionService.post("bugtracker/save",formData);
        formData.start_date = "";
        formData.end_date = "";
        formData.task_id = "";
        formData.task_item_id = "";
        formData.bug_status = "";
        formData.bug_type = "";
        formData.occurrence = "";
        formData.bug_priority = "";
        formData.bug_impact = "";
        formData.bug_description = "";
        formData.title = "";
    }else{
      formData.id = isCreate;
      res = await functionService.update("bugtracker/update",formData);
      handleClose();
    }
		setBtnLoader(false);
		setDisabled(false);
		if(res.status === true){
			isClose === 1 && handleClose();
			setSandleModalState(!handleModalState);
        }else{
            setMessage(res.error.message);
        }
	}
  const getItem = async()=>{
    let res = await functionService.get(`bugtracker/get/${isCreate}`,{id:isCreate});
    if(res.status === true){
       setFormData(res.response.data);
       getList('last',res.response.data.task_id);
    }
  }
  const getList = async(path='list',id=0)=>{
   let  parameter = {projectId:params.projectid,limit:3000,currentPage:1};
   if(id !== 0){parameter['id']=id;}
    let res = await functionService.get(`sprints/${path}`,parameter);
   if(res.status === true){
        if((res.response.data).hasOwnProperty("total")){
            setRows(res.response.data.result);
        }else{
          setItemRows(res.response.data.tasklist);
        }
        
    }
  }
 
  useEffect(()=>{
     getList();
    if(isCreate !== 0 && show === true){
      getItem();
     }
     setFormData({});
     setErrors({});
  },[isCreate,show]);
  const handleChange = (val)=>{
    setFormData({...formData,developer_id:val});
  }
    return (
      <>
        <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-project-modal"
        >          
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-project-modal">
            {isCreate === 0 ? 'Add New' : 'Update' } Bug
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
          <Row>
              <Col md = {12}>
                <Form.Group className="mb-3" controlId=""> 
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Title" value={formData && formData.title} onChange={(e)=>{setFormData({...formData,'title':e.target.value})}}/>              
                {errors && <p className="error">{errors['title']}</p>}
                </Form.Group>
              </Col>
          </Row>
          <Row>
            <Col md = {12}>
              <Form.Group className="mb-3" controlId=""> 
              <Form.Label>Description</Form.Label>
              <SunEditor  placeholder="Description" value={formData && formData.bug_description} onChange={(e)=>{setFormData({...formData,'bug_description':e})}}/>              
               {errors && <p className="error">{errors['bug_description']}</p>}
              </Form.Group>
            </Col>
          </Row> 

            <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId=""> 
                <Form.Label>Impact</Form.Label>
                  <Form.Select aria-label="task" className="form-control"  value={formData && formData.bug_impact} onChange={(e)=>{setFormData({...formData,'bug_impact':e.target.value})}}>                  
                    <option value="">Select</option>
                    <option value="nothing">Nothing</option>
                    <option value="minor">Minor</option>
                    <option value="major">Major</option>
                  </Form.Select>
                  {errors && <p className="error">{errors['bug_impact']}</p>}
                </Form.Group>             
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Priority</Form.Label>
                  <Form.Select aria-label="task" className="form-control" value={formData && formData.bug_priority} onChange={(e)=>{setFormData({...formData,'bug_priority':e.target.value})}}>                  
                  <option value="">Select</option>
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </Form.Select>
                  {errors && <p className="error">{errors['bug_priority']}</p>}
                </Form.Group>              
              </Col>
            </Row>
            <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId=""> 
                  <Form.Label>Occurrence</Form.Label>
                  <Form.Select aria-label="task" className="form-control"  value={formData && formData.occurrence} onChange={(e)=>{setFormData({...formData,'occurrence':e.target.value})}}>                  
                    <option value="">--N/A--</option>
                    <option value="Always">Always</option>
                    <option value="Random">Random</option>
                    <option value="unabletorepoduce">Unable to Repoduce</option>
                  </Form.Select>
                  {errors && <p className="error">{errors['occurrence']}</p>}
                </Form.Group>              
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId=""> 
                  <Form.Label>Category</Form.Label>
                  <Form.Select aria-label="task" className="form-control" value={formData && formData.bug_type} onChange={(e)=>{setFormData({...formData,'bug_type':e.target.value})}}>                  
                        <option value="">Select Category</option>
                          <option value="ui">UI Related</option>
                          <option value="validation">Validation</option>
                          <option value="compatibility">Compatibility</option>
                          <option value="gui">Graphical User Interface</option>
                          <option value="functional">Functional</option>
                          <option value="security">Security</option>
                          <option value="enhancement">Enhancement</option>
                          <option value="unittesting">Unit Testing</option>
                          <option value="UAT">UAT (User Acceptance Testing)</option>
                  </Form.Select>
                  {errors && <p className="error">{errors['bug_type']}</p>}
                </Form.Group>              
              </Col>
            </Row>

            <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="">
                <Form.Label>Status</Form.Label>
                  <Form.Select aria-label="task" className="form-control"  value={formData && formData.bug_status} onChange={(e)=>{setFormData({...formData,'bug_status':e.target.value})}}>                  
                    <option value="">--N/A--</option>
                    <option value="New">New</option>
                    <option value="Open">Open</option>
                    <option value="Close">Close</option>
                    <option value="Resolve">Resolve</option>
                    <option value="Feedback">Feedback</option>
                    <option value="Reopen">Reopen</option>
                  </Form.Select>
                  {errors && <p className="error">{errors['bug_status']}</p>}
                </Form.Group>              
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Assigned To</Form.Label>
                  <AutoFillDropDown formData={formData} setFormData={handleChange} path={'project/member'} name={'developer_id'}  parameter={{projectId:params.projectid}} attr_name={'name'} />
                  {errors && <p className="error">{errors['developer_id']}</p>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId=""> 
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="date" placeholder="Start Date" value={formData && functionService.getFormatedDate(formData.start_date)} onChange={(e)=>{setFormData({...formData,'start_date':e.target.value})}}/>              
                {errors && <p className="error">{errors['start_date']}</p>}
                </Form.Group>
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId=""> 
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" placeholder="End Date" value={formData && functionService.getFormatedDate(formData.end_date)} onChange={(e)=>{setFormData({...formData,'end_date':e.target.value})}}/>              
                {errors && <p className="error">{errors['end_date']}</p>}
                </Form.Group>
              </Col>
          </Row> 
          <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId=""> 
                <Form.Label>Sprint</Form.Label>
                  <Form.Select aria-label="status" className="form-control" value={formData && formData.task_id} onChange={(e)=>{setFormData({...formData,'task_id':e.target.value}); getList('last',e.target.value)}}>
                    <option value=""> Select </option>
                    {(rows.length > 0) && rows.map((obj,index)=>(
                      <option key={index} value={obj.id}>{obj['title']}</option>    
                    ))}
                </Form.Select>
                {errors && <p className="error">{errors['task_id']}</p>}
                </Form.Group>                
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId=""> 
                <Form.Label>Task</Form.Label>
                  <Form.Select aria-label="status" className="form-control" value={formData && formData.task_item_id} onChange={(e)=>{setFormData({...formData,'task_item_id':e.target.value})}}>
                    <option value=""> Select </option>
                    {(itemRows.length > 0) && itemRows.map((obj,index)=>(
                      <option key={index} value={obj.id}>{obj['title']}</option>    
                    ))}
                </Form.Select>
                {errors && <p className="error">{errors['task_item_id']}</p>}
                </Form.Group>                
              </Col>
            </Row>            
            <Row>
              <Col md = {12}>
                <FileUploader formData={formData} setFormData={setFormData}/>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
            {isCreate === 0 ? <>
             <Button className="add-btn" onClick={()=>{setTrigger(0); handleAction(isCreate)}}>
               {(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Create</Button>
             <Button className="add-btn" onClick={()=>{setTrigger(1); setTrigger(1);handleAction(1)}}>{(btnLoader && trigger === 1) && <FontAwesomeIcon icon={faSpinner} spin />} Create & Close</Button> </>
            
              :
              <Button className="add-btn"  onClick={()=>{setTrigger(0); handleAction(isCreate)}}> {(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Update</Button>
            }
          <Button variant="outline-dark close-btn" onClick={handleClose}>
            Close
          </Button>
		  {message !== '' && message}
        </Modal.Footer>
        </Modal>        
        
      </>
    );
  }
  
  
 