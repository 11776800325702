import React from "react";
import {Col} from "react-bootstrap";


function ProjectDashboardCard ({cardHeading, headbg, borderbg,row}){      
    const getDay = (end)=>{
        const date1 = new Date();
        const date2 = new Date(end);
        const diffTime = Math.abs(date2 - date1);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
       
        if(date1 >= date2){ diffDays = "-";}else{ diffDays = diffDays+" Days";}
        return diffDays;
    }
    return (
        <>
            <Col className="project-dashboard-box" style={borderbg}>
                <div className="proj-dashboard-head" style={headbg}> <h2>{cardHeading}</h2> </div>                
                
                <div className="proj-dashboard-details">
                    <div className="detailRow"><p className="lbl">Project Id:</p><p className="val">CRM ID#{row  && row.id}</p></div>
                    <div className="detailRow"><p className="lbl">ProjectName:</p><p className="val">{row  && row.name}</p></div>
                    <div className="detailRow"><p className="lbl">Start Date:</p><p className="val">{row  && row.start_date}</p></div>
                    <div className="detailRow"><p className="lbl">End Date:</p><p className="val">{row  && row.end_date}</p></div>
                    <div className="detailRow"><p className="lbl">Duration:</p><p className="val">{row  && row.duration} Days</p></div>
                    <div className="detailRow"><p className="lbl">Progress:</p><p className="val">40%</p></div>
                    <div className="detailRow"><p className="lbl">Days Remaining:</p><p className="val">{row && getDay(row.end_date)}</p></div>
                    {/* <Button className="add-btn"><FontAwesomeIcon icon={faPlus} className="me-3" />Add Task</Button>   */}
                </div>

                
                
            </Col>
        </>
    )}

export default ProjectDashboardCard;