import React from "react";
import {Col} from "react-bootstrap";
import Avtar2 from '../static/images/avatar/2.jpg';
import Image from 'react-bootstrap/Image';


function PeopleCard ({empName, email,phone,empPosition}){  
   
    return (
        <>
            <Col className="people--box mb-4">
                <div className="people_img pb-3">
                    <Image roundedCircle src={Avtar2} className="avatar_img" />  
                    <p className="name_emp">{empName}</p>
                    <p className="position_emp">{empPosition}</p>                                    
                </div>                                
                
                <div className="emp-details pt-3">
                    <div className="emp_mail"><p className="lbl">Email: </p><p className="val">{email}</p></div>
                    <div className="emp_phone"><p className="lbl">Mobile No:</p><p className="val">{phone}</p></div>                    
                </div>
                
                {/* <AddTaskModal handleAddTaskClose={handleAddTaskClose} show={show}/> */}
            </Col>
        </>
    )}

export default PeopleCard;