import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Button, Form, FormControl } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import {unitTestColumn } from "../column/columns";
import { UserContext } from "../../lib/UserContext";
import TreeTable from "../../components/Grid/Table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import TestCaseModal from '../../components/TestCaseModal';
import { functionService } from "../../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmModal from "../../components/ConfirmModal";
import UnitTestingModal from "../../components/UnitTestingModal";
import Offcanvas from 'react-bootstrap/Offcanvas';
import ImagesExtract from "../../components/ImagesExtract";

function UnitTesting() {
    const [loginUser] = useContext(UserContext);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () =>setShow(true);
    const[isCreate,setIsCreate] = useState(0);
    const[currentPage,setCurrentPage] = useState(1);
    const [showCanvas, setCanvasShow] = useState(false);
    const[currentProject,setCurrentProject] = useState({role:0});
  	const[handleModalState,setSandleModalState] = useState(false);
    const[totalRecords,setTotalRecords]=useState(0);
    const[selectedId,setSelectedId]=useState(0);
    const[confirmModal,handleConfirmModal]=useState(false);
    const [currentRow,setCurrentRow] = useState({});
    const[rows,setRows] = useState([]);
  	const params = useParams();
    const limit = 20;
    const getList = async()=>{
      let res = await functionService.get('unittesting/list',{limit:limit,currentPage:currentPage,projectId:params.projectid});
      if(res.status === true){
          if(currentPage === 1){
            setTotalRecords(res.response.data.total[0].total);
            setCurrentProject(res.response.data.projectresult);
          }
        setRows(res.response.data.result);
      }
    }
    useEffect(()=>{
        getList();
    },[currentPage,handleModalState]);

    const handlecanvasClose  = () => setCanvasShow(false);
    const handlecanvasShow  = () => setCanvasShow(true);

    const handleViewAction = (row)=>{  handlecanvasShow(); setCurrentRow(row); }
	
    const handleEditAction = (row)=>{
      setIsCreate(row.id);
      handleShow();
    }
    const handleDeleteAction = (row)=>{
      setSelectedId(row.id);
      handleConfirmModal(true);
    }
    const handleCreate = ()=>{
      setIsCreate(0);
      handleShow();
    }
    const actionHook = ()=>{
      let action = [
        [["View", [<span className="fa--icon view--icon me-3"><FontAwesomeIcon icon={faEye} size= "2x" className="sm--icon"/></span> ],
          (c) => true],handleViewAction]];
      if(functionService.checkEditStatus(currentProject.role,["1","2","6","7"])){ 
        action.push([["Edit", [<span className="edit--icon fa--icon"><FontAwesomeIcon icon={faEdit} className="sm--icon"/></span>],
          (c) => true],handleEditAction]);
        action.push( [["Delete", [<span className="delete--icon fa--icon"><FontAwesomeIcon icon={faTrash} className="sm--icon"/></span>],
          (c) => true],handleDeleteAction]); 
      }
      return action;
    }
  return (
    <>
    <Header />
    
    <Container fluid>
        <Row className="main--panel">
           <Sidebar/> 
           <Col md={10} className="p-5">
           <div className="project-list-head">
                 <h2>Unit Testing</h2>
                 <div className="project-head-right">
                  <Form className="d-flex search--form">
                      <FormControl
                        type="search"
                        placeholder="search "
                        className="me-2"
                        aria-label="Search"
                      />  <FontAwesomeIcon icon={faSearch} size= "lg" className="search--icon"/>                  
                  </Form>
                  { (functionService.checkEditStatus(currentProject.role,["1","2","6","7"])) &&
                  <Button className="add-btn" onClick={handleCreate}><FontAwesomeIcon icon={faPlus} className="me-3" />Add Unit Testing</Button>
                  }
                 </div>
               </div>
             <div className="projects-list--on card--fluid mt-4">
               
               <UnitTestingModal handleModalState={handleModalState} setSandleModalState={setSandleModalState} handleClose={handleClose} show={show} isCreate={isCreate}/>
               <ConfirmModal handleModalState={handleModalState} setSandleModalState={setSandleModalState} handleConfirmModal={handleConfirmModal} show={confirmModal} selectedId={selectedId} modalName={'testcase'} />
              <TreeTable
                  columns ={unitTestColumn}
                  contracts={rows}
                  rowsPerPage={limit}
                  totalRecords={totalRecords}
                  page={currentPage}
                  setPage ={setCurrentPage}
                  actions={[

                    [
                      "Actions",
                      actionHook(),
                      {
                        width: 90,
                        background: "#26BDB8",
                        color: "#fff",
                        fontSize: "14px",
                        width:"130px",
                      }
                    ]
                  ]}
                 
                />
             </div>
               
            </Col>
        </Row>
    </Container>
    <Offcanvas show={showCanvas} onHide={handlecanvasClose} placement="end" className= "profile--offcanvas"> 
                        <Offcanvas.Header closeButton>
                          <h4>Unit Test Details</h4>                                                    
                        </Offcanvas.Header>
                        <Offcanvas.Body className="task-bug-body">                                               
                                                                                                
                        <div className="bug-detailing"><p className="bug-label">Sprint :</p><p  className="bug-value">{currentRow && currentRow.task_id}</p></div>
                        <div className="bug-detailing"><p className="bug-label">Task :</p><p  className="bug-value">{currentRow && currentRow.task_item_id} </p></div>
                        <div className="bug-detailing"><p className="bug-label">Review Date :</p><p  className="bug-value">{currentRow && currentRow.review_date}</p></div>
                        <div className="bug-detailing"><p className="bug-label">System User :</p><p  className="bug-value">{currentRow && currentRow.system_user}</p></div>
                        <div className="bug-detailing">
                          <p className="bug-label">Description :</p>
                          <div  className="bug-value" dangerouslySetInnerHTML={{
                                            __html: currentRow && currentRow.description
                                          }}></div>                                                    
                        </div>   
                        <div className="bug-detailing">
                          <p className="bug-label">Actions :</p>
                          <div  className="bug-value" dangerouslySetInnerHTML={{
                                            __html: currentRow && currentRow.actions_result
                                          }}></div>                                                    
                        </div>
                        <div className="bug-detailing">
                          <p className="bug-label">Expected Result :</p>
                          <div  className="bug-value" dangerouslySetInnerHTML={{
                                            __html: currentRow && currentRow.expected_result
                                          }}></div>                              
                        </div>  
                        <div className="bug-detailing"><p className="bug-label">Attachments :</p><div  className="bug-value">
                                                <ImagesExtract documents={currentRow && currentRow.images}/> 
                                                  </div></div>

                        </Offcanvas.Body>
      </Offcanvas>
</> 
  );
  
}
export default UnitTesting;