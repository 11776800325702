/*const ROOT_PATH = "localhost";
//const ROOT_PATH = "192.168.1.35";
export const API_PATH = 'http://'+ROOT_PATH+':8002/api/';
export const API_HOME_PATH = 'http://'+ROOT_PATH+':8002/';
export const SOCKET_PATH = 'http://'+ROOT_PATH+':8003/';
export const HOME_PATH = 'http://'+ROOT_PATH+':3001';*/

//Live
export const API_PATH = 'https://pmsapi.ngrok.io/api/';
export const API_HOME_PATH = 'https://pmsapi.ngrok.io/';
export const SOCKET_PATH = 'https://pmsapi.ngrok.io/';
export const HOME_PATH = 'https://pms.startdesigns.com';