import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { functionService } from "../lib/functions";

function AutoFillDropDown({formData,setFormData,path,name,errors={},parameter={},attr_name}) {
    const[rows,setRows] = useState([]);
    const getList = async()=>{
        let res = await functionService.get(path,parameter);
       if(res.status === true){
            if((res.response.data).hasOwnProperty("total")){
                setRows(res.response.data.result);
            }else  if((res.response.data).hasOwnProperty("projectresult")){
				 if(res.response.data.result.hasOwnProperty("projectrole")){
					 setRows(res.response.data.result.results);
				 }else{
						setRows(res.response.data.result);
				}
				 
            }else{
                setRows(res.response.data);
            }
            
        }
      }
      useEffect(()=>{
          getList();
          
      },[]);
      const handleAction = (val)=>{
        setFormData(val);
      }
  return (        
        <>
            <Form.Select aria-label="status" className="form-control" value={formData && formData[name]} onChange={(e)=>{handleAction(e.target.value)}}>
                  <option value=""> Select </option>
                  {(rows.length > 0) && rows.map((obj,index)=>(
                    <option key={index} value={obj.id}>{obj[attr_name]}</option>    
                  ))}
             </Form.Select>
             {errors && <p className="error">{errors[name]}</p>}        
        </> 
  );
  
}

export default AutoFillDropDown;