import React, { useContext, useState } from "react";
import { Card, Form, Row, Col} from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import {Link} from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { UserContext } from "../lib/UserContext";
import TreeTable from "./Grid/Table";
import {taskColumn} from "../Pages/column/columns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit, faVirus, faEye, faBug, faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Offcanvas from 'react-bootstrap/Offcanvas';
import noDataImg from '../static/images/no-data-found.jpg';
import AddBugModal from "./AddBugModal";
import TaskRiskDetailModal from './TaskRiskDetailModal';
import AddTaskModal from './AddTaskModal';
import ConfirmModal from "./ConfirmModal";
import Avtarr from '../static/images/avatar/3.jpg';
import Avtarr1 from '../static/images/avatar/5.jpg';
import { functionService } from "../lib/functions";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";


function SprintCard({currentProject,row,setIsCreateParent, handleConfirmSprintModal,handleModal, setSelectedId,handleModalState,setSandleModalState}) {
    const [loginUser] = useContext(UserContext); 
    const params = useParams(); 
    const history = useHistory();
    const[disabled,setDisabled]=useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const[currentRow,setCurrentRow]=useState({});
    const [taskShow, setTaskShow] = useState(false);
    const handleAddTaskClose = () => setTaskShow(false);
    const handleAddTaskShow = () =>setTaskShow(true);
    const[confirmModal,handleConfirmModal]=useState(false);
    const[selectedTaskId,setSelectedTaskId]=useState(0);
    const [bugshow, setBugShow] = useState(false);
    const handleBugClose = () => setBugShow(false);
    const handleBugShow = () =>setBugShow(true);     
    const handleBugCreate = ()=>{      
      handleBugShow();
    }

    const[isCreate,setIsCreate] = useState(0);
 
    const [riskshow, setRiskShow] = useState(false);
    const handleRiskClose = () => setRiskShow(false);
    const handleRiskShow = () =>setRiskShow(true);     
    const handleRiskCreate = ()=>{      
      handleRiskShow();
    }

    const handleViewAction = (row)=>{ setCurrentRow(row);  handleShow(); }   
  

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
          console.log(),
        );      
        return (
          <button
            type="button"
            style={{ fontSize: "16px",fontWeight:500, border: "none", background:"#fff", padding: 0 }}
            onClick={decoratedOnClick}
          >
            {children}
          </button>
        );
      }
    const handleEdit = (id)=>{
      setIsCreateParent(id);
      handleModal();
    }
   const handleSprintDelete = (id)=>{
    handleConfirmSprintModal(true);
    setSelectedId(id);
   } 

   const handleEditAction = (row)=>{    
    setTaskShow(true);
    setIsCreate(row.id);
  }
  const handleDeleteAction = (row)=>{   
    setSelectedTaskId(row.id);
    handleConfirmModal(true);
  }
  const handleBugsList = (row)=>{
      history.push('/project/'+row.project_id+'/task-bug-tracker/'+row.id);
  }
  const actionHook = ()=>{
    let action = [
              [["View", [<span className="fa--icon view--icon me-3"><FontAwesomeIcon icon={faEye} size= "2x" className="sm--icon"/></span> ],
                (c) => true],handleViewAction],
              [["Bug", [<span  className="bug--icon fa--icon"><FontAwesomeIcon icon={faBug} className="sm--icon"/></span>],
                (c) =>  ((c.bug_status === 0 )? -1 : (c.bug_status === 1 ? true :2 ))],handleBugsList],
              [["Risk", [<span className="delete--icon fa--icon"><FontAwesomeIcon icon={faVirus} className="sm--icon"/></span>],
                (c) => ((c.risk_status === 0 )? -1 : (c.risk_status === 1 ? true :false ))],handleRiskCreate],
          ];
          if(functionService.checkEditStatus(currentProject.role,["1","2"])){ 
              action.push([["Edit", [<span className="edit--icon fa--icon"><FontAwesomeIcon icon={faEdit} className="sm--icon"/></span>],
              (c) => true],handleEditAction]);
              action.push([["Delete", [<span className="delete--icon fa--icon"><FontAwesomeIcon icon={faTrash} className="sm--icon"/></span>],
              (c) => true],handleDeleteAction]);

          }
    return action;      
  }
  const handleTaskAction = async(e)=>{
     if(disabled === true){ return false;}
      setDisabled(true);
      let array = {...currentRow};
      array.task_status = e.target.value;
      setCurrentRow(array);
      let res = await functionService.update("tasks/status-update",{id:array.id,task_status:array.task_status,project_id:params.projectid});
      if(res.status === true){
        setSandleModalState(!handleModalState);
      }
      setDisabled(false);
      
  }
  var startDates = new Date(currentRow.start_date).toLocaleDateString().replaceAll('/','-');;   
  var endDates = new Date(currentRow.end_date).toLocaleDateString().replaceAll('/','-');; 
  return (
    <>

    <div className= 'task--main mb-5'>
    {row && row.result ?  
        <div className="tasks-inner-data">
       
        <Accordion defaultActiveKey="0" >  
                                                                   
                                    <Card>
                                        <Card.Header className= 'task-bar-head'>
                                            <CustomToggle eventKey="0">T-{row.result.id} / { row.result.title} <span className="sprint-duration">({row.result.start_date} to { row.result.end_date})</span></CustomToggle>
                                            { (functionService.checkEditStatus(currentProject.role,["1","2"])) &&
                                            <div className="task-action"> 
                                                <span className="me-3 add--icon1 fa--icon1" onClick={handleAddTaskShow}><FontAwesomeIcon icon={faPlus} size= "lg" className="sm--icon me-1"/> Add Task</span>                                               
                                                <span className="me-3 edit--icon1 fa--icon1" onClick={()=>{handleEdit(row.result.id)}}><FontAwesomeIcon icon={faEdit} size= "lg" className="sm--icon me-1"/>Edit</span>
                                                <span className="delete--icon1 fa--icon1" onClick={()=>{handleSprintDelete(row.result.id)}}><FontAwesomeIcon icon={faTrash} size= "lg" className="sm--icon me-1"/>Delete</span>                                                
                                            </div>
                                            }
                                        </Card.Header >
                                        
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body className="task-bar-body">
                                                <TreeTable
                                                    columns ={taskColumn} 
                                                    contracts={ row.tasklist }                                           
                                                    actions={[
                                                        [
                                                        "Actions", 
                                                        actionHook(),
                                                        {
                                                            width: 90,
                                                            background: "#f7f7f7",
                                                            color: "#000",
                                                            fontSize: "14px",
                                                            width:"150px",
                                                        }
                                                        ]
                                                    ]}                                                    
                                                />
                                                {/* ,handleDeleteAction */}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    
                                </Accordion> 
                                <AddTaskModal handleModalState={handleModalState} setSandleModalState={setSandleModalState} handleClose={handleAddTaskClose} show={taskShow} isCreate={isCreate} task_id={row.result.id}/>
                                <ConfirmModal handleModalState={handleModalState} setSandleModalState={setSandleModalState} handleConfirmModal={handleConfirmModal} show={confirmModal} selectedId={selectedTaskId} modalName={'tasks'} />
                                <AddBugModal handleClose= {handleBugClose} show= {bugshow} />
                                <TaskRiskDetailModal handleModalState={handleModalState} setSandleModalState={setSandleModalState} handleClose={handleRiskClose} show={riskshow} isCreate={isCreate}/>
        </div>
        :<><div className="text-center no-data-found"><Image src= {noDataImg}></Image></div></>  }
    </div>
                      <Offcanvas show={show} onHide={handleClose} placement="end" className= "task--offcanvas"> 
                        <Offcanvas.Header closeButton>
                          <h4>Task Id#{currentRow && currentRow.id}</h4>
                          <div className="task-action">                            
                            <Form.Group className="me-3" controlId="taskname">                              
                                <Form.Select aria-label="status" onChange={handleTaskAction} className="" value={currentRow && currentRow.task_status} disabled={currentRow && currentRow.task_status != 'Completed' ? false:true}>
                                      <option>Select Status</option>
                                      <option value="Not Started">Not Started</option>
                                      <option value="Running">Running</option>
                                      <option value="Completed">Completed</option>
                                </Form.Select> 
                            </Form.Group>                                             
                            {/* <span className="me-3 edit--icon1 fa--icon1"><FontAwesomeIcon icon={faEdit} size= "lg" className="sm--icon me-1"/>Edit</span>
                            <span className="me-3 delete--icon1 fa--icon1"><FontAwesomeIcon icon={faTrash} size= "lg" className="sm--icon me-1"/>Delete</span>  */}
                            <span className="close--icon1 fa--icon1" ><FontAwesomeIcon icon={faTrash} size= "sm" className="sm--icon me-1"/>Close</span>                                                                        
                          </div>                          
                        </Offcanvas.Header>
                        <Offcanvas.Body>                          
                          <div className="top_body">
                            <Form.Group className="mb-3" >                          
                              <Form.Control type="text" className="task--name" value={currentRow && currentRow.title}/>                          
                            </Form.Group>
                            <div className="other-details mb-3">
                              <p>Start date: <br/><span>{currentRow && startDates}</span></p>
                              <p>End date: <br/><span>{currentRow && endDates}</span></p>
                              <p>Assigned To: <br/><span>{currentRow && currentRow.assignedto}</span></p>
                              <p>Assigned By: <br/><span>{currentRow && currentRow.assignedby}</span></p>
                              <p>Duration: <br/><span>{currentRow && currentRow.duration} Hours</span></p>
                            </div>
                            
                                <div className="mb-3" >  
                                <Form.Label>Description</Form.Label>                                                
                                <Form.Control disabled as="textarea" rows={6} value={currentRow && currentRow.description}/>                          
                                </div>
                              
                              {/* <div  className="files--list">
                              <Form.Label>Files</Form.Label> 
                              <ul className="task-uploaded-files">
                                <li>FileName1.pdf</li>
                                <li>FileName2.jpg</li>
                                <li>FileName3.html</li>
                                <li>FileName4.png</li>
                                <li>FileName1.pdf</li>
                                <li>FileName2.jpg</li>
                                <li>FileName3.html</li>
                                <li>FileName4.png</li>
                              </ul>
                             </div> */}
                            
                          </div>
                          <div className="task--message--box">
                            <div className="taskmessage in">
                              <div className="avtar-img">
                                <Image src={Avtarr}></Image>
                              </div>
                              <div className="avtar-title">
                                <p className="avtar-name">John Doe <span>12:30pm</span></p>
                                <p className="avtar-text">Hello michel, please complete it as soon as possible</p>
                              </div>
                            </div>
                            <div className="taskmessage out">
                              <div className="avtar-img">
                                <Image src={Avtarr1}></Image>
                              </div>
                              <div className="avtar-title">
                                <p className="avtar-name">Amit Kumar <span>2:30pm</span></p>
                                <p className="avtar-text">Here is some issue i'm working on.Here is some issue i'm working on. Here is some issue i'm working on.s</p>
                              </div>
                            </div>
                            <div className="taskmessage in">
                              <div className="avtar-img">
                                <Image src={Avtarr}></Image>
                              </div>
                              <div className="avtar-title">
                                <p className="avtar-name">John Doe <span>12:30pm</span></p>
                                <p className="avtar-text">Hello michel, please complete it as soon as possible</p>
                              </div>
                            </div>
                            <div className="taskmessage out">
                              <div className="avtar-img">
                                <Image src={Avtarr1}></Image>
                              </div>
                              <div className="avtar-title">
                                <p className="avtar-name">Amit Kumar <span>2:30pm</span></p>
                                <p className="avtar-text">Here is some issue i'm working on.Here is some issue i'm working on. Here is some issue i'm working on.s</p>
                              </div>
                            </div>  
                            <div className="taskmessage out">
                              <div className="avtar-img">
                                <Image src={Avtarr1}></Image>
                              </div>
                              <div className="avtar-title">
                                <p className="avtar-name">Amit Kumar <span>2:30pm</span></p>
                                <p className="avtar-text">Here is some issue i'm working on.Here is some issue i'm working on. Here is some issue i'm working on.s</p>
                              </div>
                            </div>                          
                          </div>

                          <div className="send--message--panel">                            
                            <Form.Group className="text-box" >    
                              
                              <div className="attachicon"><FontAwesomeIcon icon={faPaperclip} size= "2x" className=""/> </div>                                               
                              <Form.Control type="text" rows={2} />   
                              <div className="sendmsgicon"><FontAwesomeIcon icon={faPaperPlane} size= "sm" className="sendmsgicon"/> </div>                      
                            </Form.Group>
                            
                            <div className="send-tools"></div>
                          </div>
                          
                        </Offcanvas.Body>
                      </Offcanvas>


    
    
   
    </>
  );
  
}

export default SprintCard;