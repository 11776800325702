import React,{useState,useEffect} from "react";
import { Editor } from "react-draft-wysiwyg";
import '../index.css';

export default function SunEditor({value,onChange}) {
	const content = {
	  entityMap: {},
	  blocks: [
		{
		  key: "637gr",
		  text: (value !== "" && typeof value !== "undefined")? value:'',
		  type: "unstyled",
		  depth: 0,
		  inlineStyleRanges: [],
		  entityRanges: [],
		  data: {},
		},
	  ],
	}
	
	return (
		<Editor   editorClassName="wysingEditor" initialContentState={content}  onChange={(e)=>{onChange(e.blocks[0].text)}}/>	
	
	)


}	