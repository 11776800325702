import React,{useState,useEffect} from "react";
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import { functionService } from "../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AutoFillDropDown from "./AutoFillDropDown";
import { bucketfield, validate } from "./ValidationChecked";
import SunEditor from "./SunEditor";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function BucketModal({handleModalState,setSandleModalState,handleClose,show,isCreate=0}) {   
  const[formData,setFormData]=useState({});
	const[message,setMessage] = useState('');
	const params = useParams();
	const[btnLoader,setBtnLoader]=useState(false);
  const[disabled,setDisabled]=useState(false);
  const[errors,setErrors]=useState({}); 
  const[trigger,setTrigger]=useState(0); 

	const handleAction = async (isClose)=>{
    if(validate(formData,bucketfield) !== 0){ setErrors(validate(formData,bucketfield)); return false;}
		setMessage('');
    if(disabled === true){ return false;}    
    setDisabled(true);
    setBtnLoader(true);
    
		formData.project_id =params.projectid;
    let res = {};
    if (isCreate === 0) {
        res = await functionService.post("bucket/save",formData);    
        formData.duration = "";
        formData.description = "";    
    }else{
      formData.id = isCreate;
      res = await functionService.update("bucket/update",formData);
      handleClose();
    }    
		setDisabled(false);
		setBtnLoader(false);
    isClose === 1 && handleClose();

		if(res.status === true){			
			setSandleModalState(!handleModalState);
        }else{
            setMessage(res.error.message);
        }
	}

  const getItem = async()=>{
    let res = await functionService.get(`bucket/get/${isCreate}`,{id:isCreate});
    if(res.status === true){
	
       setFormData(res.response.data);
    }
  }
  const handleChangeValue = (val)=>{
	  setFormData({...formData,user_id:val});
  }
  useEffect(()=>{
    if(isCreate !== 0 && show === true){
      getItem();
     }else{
		let currentUser = functionService.getCurrentUser();
		setFormData({user_id:currentUser.sub});
	 }
     setErrors({});
  },[isCreate,show]);
    return (
      <>
        <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-project-modal"
        >          
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-project-modal">
            {isCreate === 0 ? 'Add New' : 'Update' } Bucket
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Row>
              <Col md = {6}>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>User</Form.Label>
                <AutoFillDropDown formData={formData} setFormData={handleChangeValue} path={'project/member'} name={'user_id'}  parameter={{projectId:params.projectid}} attr_name={'name'} />              
                {errors && <p className="error">{errors['user_id']}</p>}
              </Form.Group>
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Date</Form.Label>
                  <Form.Control type="date" placeholder="Date" value={formData && functionService.getFormatedDate(formData.work_date)} onChange={(e)=>{setFormData({...formData,'work_date':e.target.value})}}/>
                  {errors && <p className="error">{errors['work_date']}</p>}
               </Form.Group>
              </Col>
            </Row>

            <Row>
            <Col md = {6}>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Duration</Form.Label>
                <Form.Control type="text" placeholder="Duration" value={formData && formData.duration} onChange={(e)=>{setFormData({...formData,'duration':e.target.value})}}/>
                {errors && <p className="error">{errors['duration']}</p>}
              </Form.Group>
            </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="">
                <Form.Label>Type</Form.Label>
                <Form.Select aria-label="status" className="form-control" value={formData && formData.bucket_type} onChange={(e)=>{setFormData({...formData,'bucket_type':e.target.value})}}>
                    <option value="">Bucket Type</option>
                    <option value="Used">Used</option>
                    <option value="Purchased">Purchased</option>                 
                  </Form.Select>
                  {errors && <p className="error">{errors['bucket_type']}</p>}
                </Form.Group>             
              </Col>             
              
            </Row>
            <Row>              
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Description</Form.Label>
                <SunEditor value={formData && formData.description} onChange={(e)=>{setFormData({...formData,'description':e})}}/>
                {errors && <p className="error">{errors['description']}</p>}
              </Form.Group>
            </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
            {isCreate === 0 ? <>  
             <Button className="add-btn" onClick={()=>{setTrigger(0);handleAction(0)}}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Create</Button>
             <Button className="add-btn" onClick={()=>{setTrigger(1);handleAction(1)}}>{(btnLoader && trigger === 1) && <FontAwesomeIcon icon={faSpinner} spin />} Create & Close</Button> </>
             :
              <Button className="add-btn"  onClick={()=>{setTrigger(0); handleAction(0)}}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Update</Button>
            }
            
          <Button variant="outline-dark close-btn" onClick={handleClose}>
            Close
          </Button>
		      {message !== '' && message}
        </Modal.Footer>
        </Modal>        
        
      </>
    );
  }
  
 