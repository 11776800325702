import React from "react";
export const validate= (formData={},fields={})=>{
    var num = 0;
    let errors = {};
    for(let field in fields){
        if(!formData.hasOwnProperty(field)){
            num++;
            errors[field] = fields[field];
        }else if(formData.hasOwnProperty(field) && formData[field] === ""){
            num++;
            errors[field] = fields[field];
        }else{
            errors[field] = false;
        }
    }
    return num === 0 ? num : errors; 
}
export const messageField = {
    title:'Please fill required field',
    description:'Please fill required field',
   
}  
export const holidayField = {
    holiday_name:'Please fill required field',
    holiday_type:'Please fill required field',
    holiday_date:'Please fill required field',
    is_defer:'Please fill required field',
}
export const announceField = {
    title:'Please fill required field',
    announce_type:'Please fill required field',
   
}  
export const addProjectField = {
    name:'Please fill required field',
    start_date:'Please fill required field',
    end_date:'Please fill required field',
    duration:'Please fill required field',
    status:'Please fill required field',
    project_tech:'Please fill required field',
    description:'Please fill required field',
}
export const teamMembers = {
    name:'Please fill required field',
    phone_number:'Please fill required field',
    email:'Please fill required field',
    password:'Please fill required field',
    user_type:'Please fill required field',
    member_id:'Please fill required field',
    date_of_joining:'Please fill required field',
    dob:'Please fill required field',
}
export const customers = {
    name:'Please fill required field',
    phone_number:'Please fill required field',
    email:'Please fill required field',
    password:'Please fill required field',
    user_type:'Please fill required field',
    address:'Please fill required field',
    city:'Please fill required field',
    country:'Please fill required field',
    country:'Please fill required field',
    postcode:'Please fill required field',
	dob:'Please fill required field',
}

export const addSprint = {
    title:'Please fill required field',
    start_date:'Please fill required field',
    end_date:'Please fill required field',   
    milestone_id:'Please fill required field',   
}
export const unitTestField = {
    task_id:'Please fill required field',
    task_item_id:'Please fill required field',
    review_date:'Please fill required field',   
    system_user:'Please fill required field',   
    actions_result:'Please fill required field',  
    expected_result:'Please fill required field', 
}
export const meetingfield = {
    meeting_title:'Please fill required field',
    agenda:'Please fill required field',
    meeting_date:'Please fill required field',   
    meeting_duration:'Please fill required field',   
    meeting_description:'Please fill required field', 
}
export const riskfield = {
    identificationdate:'Please fill required field',
    reviewdate:'Please fill required field',
    description:'Please fill required field',   
    category:'Please fill required field',   
    riskowner:'Please fill required field', 
    status:'Please fill required field', 
   // end_date:'Please fill required field', 
}
export const milestonefield = {
    start_date:'Please fill required field',
    end_date:'Please fill required field',
    title:'Please fill required field',   
    status:'Please fill required field',   
    description:'Please fill required field', 
}
export const testcasefield = {
    task_id:'Please fill required field',
    task_item_id:'Please fill required field',
    review_date:'Please fill required field',
    testing_type:'Please fill required field',
    steps:'Please fill required field',   
    description:'Please fill required field',   
    expected_result:'Please fill required field', 
}
export const bugtrakerfield = {
    task_id:'Please fill required field',
    task_item_id:'Please fill required field',
    review_date:'Please fill required field',
    testing_type:'Please fill required field',
    steps:'Please fill required field',   
    description:'Please fill required field',   
    expected_result:'Please fill required field', 
}
export const taskItem = {
    title:'Please fill required field',
    description:'Please fill required field',
    priority:'Please fill required field',
    developer_id:'Please fill required field', 
    start_date:'Please fill required field', 
    end_date:'Please fill required field', 
    duration:'Please fill required field',
}
export const addbugfield = {
    title:'Please fill required field',
    bug_description:'Please fill required field',
    bug_impact:'Please fill required field',
    bug_priority:'Please fill required field',
    occurrence:'Please fill required field',
    bug_type:'Please fill required field',   
    bug_status:'Please fill required field',   
    developer_id:'Please fill required field', 
    start_date:'Please fill required field', 
    task_id:'Please fill required field', 
    end_date:'Please fill required field', 
    task_item_id:'Please fill required field',
}
export const adddocfield = {
    category:'Please fill required field', 
}
export const bucketfield = {
    user_id:'Please fill required field', 
    work_date:'Please fill required field',
    duration:'Please fill required field',
    bucket_type:'Please fill required field',
    description:'Please fill required field',
}
export const addTaskfield = {
    user_id:'Please fill required field', 
    work_date:'Please fill required field',
    duration:'Please fill required field',
    bucket_type:'Please fill required field',
    description:'Please fill required field',
}
