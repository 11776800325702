import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "./util";

function getByPath(data, path) {
    if (path.length === 0) return data;
    if (data[path[0]] === undefined) {
        throw new Error("Object doesn't have key '" + path[0] + "': " + JSON.stringify(data));
    }
    const value = getByPath(data[path[0]], path.slice(1));
    return value;
}

export function setByPath(value, path, defaultValue={}) { 
    var obj = {...defaultValue};
    var schema = obj;  // a moving reference to internal objects within obj
    var pList = path.split('.');
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i].trim();
        if (!schema[elem]) {
            var nextVal = {};
            if (i < len-2) {
                var nextElem = pList[i+1].trim();
                if (!isNaN(nextElem) && parseInt(nextElem).toString() === nextElem) nextVal = [];
            }
            if (!isNaN(elem) && parseInt(elem).toString() === elem) elem = parseInt(elem);
            schema[elem] = nextVal;
        }
        schema = schema[elem];
    }
    schema[pList[len-1]] = value;
    return obj;
}
  
export function getValue(data, path, option={}) {
    // console.log("[Contracts] getValue", path, data);

    const split = typeof path === "string" && path !== "" ? path.split(".") : [];
    let value = option.defaultValue || "";

    try {
        value = getByPath(data, split);
        if (!option.isString) value = formatDate(value);
    }
    catch(e) {
        // console.warn(e);
    }
    // console.log("[Contracts] getValue", path, value);

    return value;
}

export function renderCell(option) {
    if (option) {
        if (option.type === "mail") return (param) => (<a href={"mailto:" + param.value}>{param.value}</a>);
        else if (option.type === "object") {
            if (typeof value === "object") return (param) => (param.value);
        }
        else if (option.type === "link") return (param) => (
            <Link  variant="body2" onClick={() => option.onClick(param.row.id, param.row)} >
                {!!param.value && param.value}
            </Link>);
        else if (option.type === "render") return option.render;
    }
    return (param) => (param.value);
}

export function getValues(data, path) {
    // console.log("[Contracts] getValues", path, data);
    return path.map((p, i) => getValue(data, p)).join(" ");
}

export function getValueByCol(data, path, option) {
    // console.log("[Contracts] getValueByCol", data, path, option);
    return (typeof path === "object") ?
        (typeof option === "undefined" ? getValues(data, path) : getValues(data, path, option))
        :
        (typeof option === "undefined" ? getValue(data, path) : getValue(data, path, option));
}

export function getStrFromAction(action) {
    if (typeof action === "string") return action;
    else if (typeof action === "object" && action.length > 0 && typeof action[0] === "string") return action[0];
    else return "";
}
export function getObjFromAction(action) {
    if (typeof action === "object" && action.length > 1 && typeof action[1] === "object") return action[1];
    else return null;
}
export function getFuncFromAction(action) {
    if (typeof action === "object" && action.length > 2 && typeof action[2] === "function") return action[2];
    else return null;
}