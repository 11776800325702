import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { UserContext } from "../../lib/UserContext";
import ProjectDashboardCard from '../../components/ProjectDashboardCard';
import ProjectRequirementCard from '../../components/ProjectRequirementCard';
import ProjectTestCard from '../../components/ProjectTestCard';
import ProjectTeamCard from '../../components/ProjectTeamCard';
import ProjectSpendTimeCard from '../../components/ProjectSpendTimeCard';
import { functionService } from "../../lib/functions";
import { useParams } from "react-router-dom";

function ViewProject() {
  const [loginUser] = useContext(UserContext);
  const[row,setRow]=useState({});
  const params = useParams();
  const getItem = async()=>{
    let res = await functionService.get(`project/projectdashboard/${params.projectid}`,{});
    if(res.status === true){
       setRow(res.response.data);
    }
  }
  useEffect(()=>{
    getItem();
},[params]);

  return (
    <>
    <Header />
    
    <Container fluid>
        <Row className="main--panel">
           <Sidebar/> 
           <Col md={10} className="p-5">
                <div className= "card--fluid--one">
                  <div className="card-fluid-header"><p>Project Dashboard</p></div>

                  <Row className="mt-4">
                    <Col md={3}><ProjectDashboardCard cardHeading="Project Information"  row={row && row.project}/></Col>
                    <Col md={3}><ProjectRequirementCard cardHeading="Requirements"   row={row}/></Col>
                    <Col md={3}><ProjectTestCard cardHeading="Test Cases/ Bugs" row={row}/></Col>
                    <Col md={3}><ProjectTeamCard cardHeading="Team" row={row && row.projectmember} projectrole={row && row.projectrole}/></Col>
                    <Col md={3}><ProjectSpendTimeCard cardHeading="Project Planned and Spend Hours"/></Col>
                  </Row>
                </div>
            </Col>
        </Row>
    </Container>
</> 
  );
  
}
export default ViewProject;