import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, FormControl } from "react-bootstrap";
import { UserContext } from "../../lib/UserContext";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch,  } from '@fortawesome/free-solid-svg-icons';
import AddSprintModal from '../../components/AddSprintModal';
import SprintCard from '../../components/SprintCard';
import { useParams } from "react-router-dom";
import { functionService } from "../../lib/functions";
import ConfirmModal from "../../components/ConfirmModal";
function Tasks() {
    const [loginUser] = useContext(UserContext);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () =>setShow(true);
    const[isCreate,setIsCreate] = useState(0);
    const[currentPage,setCurrentPage] = useState(1);
  	const[handleModalState,setSandleModalState] = useState(false);
    const[currentProject,setCurrentProject] = useState({role:0});
    const[selectedId,setSelectedId]=useState(0);
    const[confirmModal,handleConfirmModal]=useState(false);
    const[rows,setRows] = useState([]);
    const[lastSprint,setLastSprint]=useState(0);
    const[taskRows,setTaskRows] = useState([]);
  	const params = useParams();
    const limit = 20;
    const getList = async(id = 0)=>{
      setLastSprint(id);
      let path = 'sprints/last';
      let res = await functionService.get(path,{limit:limit,currentPage:currentPage,projectId:params.projectid,id:id});
     if(res.status === true){
        setRows(res.response.data);
      }
    }
    const getItemList = async()=>{
      let res = await functionService.get('sprints/list',{limit:limit,currentPage:currentPage,projectId:params.projectid});
      if(res.status === true){
        setTaskRows(res.response.data.result);
        setCurrentProject(res.response.data.projectresult);
      }
    }
    
    useEffect(()=>{
        getList(lastSprint);
        getItemList();
    },[currentPage,handleModalState]);
	
    const handleEditAction = (row)=>{
      setIsCreate(row.id);
      handleShow();
    }
    const handleDeleteAction = (row)=>{
      setSelectedId(row.id);
      handleConfirmModal(true);
    }
    const handleCreate = ()=>{
      setIsCreate(0);
      handleShow();
    }
 
  return (
    <>
    <Header />    
    <Container fluid>
        <Row className="main--panel">
        <Sidebar/> 
        <Col md={10} className= "p-5">
                <div className= "card--fluid--one">
                    <div className="card-fluid-header">
                        <p>Sprint- Task</p>
                        <div className="card-fluid-right">
                            <Form className="d-flex search--form">
                            <Form.Select aria-label="status" className="form-control " value={(rows && rows.result) && rows.result.id} onChange={(e)=>{getList(e.target.value)}}>
                              <option value="0" disabled>Select Sprint</option>
                              { taskRows && taskRows.map((obj,index)=>(
                                  <option value={obj.id} key={index}>{obj.title}</option>
                              ))}
                        </Form.Select>                 
                            </Form>
                            { (functionService.checkEditStatus(currentProject.role,["1","2"])) &&
                            <Button className="add-btn" onClick={()=>{handleShow(); setIsCreate(0); }}><FontAwesomeIcon icon={faPlus} className="me-3" />Add Sprint</Button>
                            }
                        </div>
                    </div>
                    <AddSprintModal  handleModalState={handleModalState} setSandleModalState={setSandleModalState} handleClose={handleClose} show={show} isCreate={isCreate}/>
                    <ConfirmModal handleModalState={handleModalState} setSandleModalState={setSandleModalState} handleConfirmModal={handleConfirmModal} show={confirmModal} selectedId={selectedId} modalName={'sprints'} />
                    <Row className="dashboard-task-section mt-4">                        
                        <Col md={12}> 
                           
                            {rows &&
                                <SprintCard currentProject = {currentProject} row={rows} setIsCreateParent={setIsCreate} handleModal = {handleShow} handleConfirmSprintModal={handleConfirmModal} setSelectedId={setSelectedId} handleModalState={handleModalState} setSandleModalState={setSandleModalState}/> 
                            }                            
                           
                                                 
                        </Col>                                                                                 
                    </Row> 
                </div>
            </Col>
        </Row>
    </Container>
    </>
  );
  
}

export default Tasks;