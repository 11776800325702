import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Button, FormControl, Image } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form'
import { UserContext } from "../../lib/UserContext";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import MessageModal from '../../components/MessageModal';
import { functionService } from "../../lib/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import noDataImg from '../../static/images/no-data-found.jpg';

function ProjectMessages() {
    const [loginUser] = useContext(UserContext);
    const [show, setShow] = useState(false);
    const handleMessageClose = () => setShow(false);
    const handleMessageShow = () =>setShow(true);
    const history = useHistory();
    const[rows,setRows] = useState([]);
    const[totalRecords,setTotalRecords]=useState(0);
    const[currentPage,setCurrentPage] = useState(1);
    const params = useParams();
    const limit = 200;
    const getList = async()=>{
        let res = await functionService.get('project/project-messages',{limit:limit,currentPage:currentPage,projectId:params.projectid});
        if(res.status === true){
            if(currentPage === 1){
              setTotalRecords(res.response.data.total[0].total);
            }
          setRows(res.response.data.result);
        }
      }
      useEffect(()=>{
          getList();
      },[currentPage]);
	const excludeHtml = (description)=>{
		return description.replace(/<[^>]*>?/gm, '');
	}  
  return (
            <>
                <Header />    
            <Container fluid>
                <Row className="main--panel">
                <Sidebar/> 
                <Col md={10} className= "p-5">
                        <div className= "card--fluid--one">
                            <div className="card-fluid-header">
                                <p>Messages</p>
                                <div className="card-fluid-right">
                                    <Form className="d-flex search--form">
                                        <FormControl
                                            type="search"
                                            placeholder="Search Message"
                                            className="me-2"
                                            aria-label="Search"
                                        />  <FontAwesomeIcon icon={faSearch} size= "lg" className="search--icon"/>                  
                                    </Form>
                                    <Button className="add-btn" onClick={()=>{history.push(`/project/${params.projectid}/new/message`)}}><FontAwesomeIcon icon={faPlus} className="me-3" />Post New</Button>
                                </div>
                            </div>  

                            <MessageModal handleMessageClose={handleMessageClose} show={show}/>

                            {rows.length > 0  ? 
                            <div className="message-overview mt-4">                            
                                {rows && rows.map((obj,index)=>(
                                    <div className="message-summary-box" key={index}>
                                    <h4>{obj.title}</h4>
                                    {obj['messages'] && obj['messages'].map((data,subindex)=>(
                                    <div className="summary-data mb-3" key={subindex}>
                                        <div className="message-text">{excludeHtml(data.description)}</div>
                                        <div className="last-updatedby">
                                            <p className="messagelabel">Last updated by:</p>
                                            <p>{data.user_name}</p>
                                        </div>
                                        <div className="message-timing">
                                            <p className="messagelabel">Last update time:</p>
                                            <p>{data.created_at}</p>
                                        </div>
                                        <div className="message-reply">                                            
                                            <Link to={`/project/${params.projectid}/message/${obj.id}/view`} className="reply-btn"> Reply</Link>
                                        </div>
                                    </div>
                                    ))}
                                   
                                    </div>
                                ))}                              
                            </div>:<><div className="text-center no-data-found"><Image src= {noDataImg}></Image></div></>  }                      
                        </div>
                    </Col>
                </Row>
            </Container>
            </>
  );
  
}

export default ProjectMessages;