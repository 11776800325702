import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { UserContext } from "../../lib/UserContext";
function AddUser() {
    const [loginUser] = useContext(UserContext);
    console.log(loginUser);
  return (
            <Container>
                <Row className="users">
                    <Col md={12}>
                    Users
                    </Col>
                </Row>
            </Container>
  );
  
}

export default AddUser;