import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Form, FormControl, Button, Image } from "react-bootstrap";
import { UserContext } from "../../lib/UserContext";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import PeopleCard from "../../components/PeopleCard";
import { functionService } from "../../lib/functions";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import AddProjectMemberModal from '../../components/AddProjectMemberModal';
import noDataImg from '../../static/images/no-data-found.jpg';
function Peoples() {
    const [loginUser] = useContext(UserContext);
    const[rows,setRows] = useState([]);
    const[currentProject,setCurrentProject] = useState({role:0});
    const params = useParams();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () =>setShow(true);  	
    const handleCreate = ()=>{      
        handleShow();
    }
    const getList = async()=>{
        // let date = new Date();
        let res = await functionService.get('project/member',{projectId:params.projectid});
       
        if(res.status === true){
            //console.log(res.response.data.projectresult);
			setRows(res.response.data.result.results);
            setCurrentProject(res.response.data.projectresult);
        }
      }
    
      useEffect(()=>{
          getList();
          
      },[params,show]);
	  console.log("rows",rows);
  return (        
        <>
            <Header />    
            <Container fluid>
                <Row className="main--panel">
                <Sidebar/> 
                <Col md={10} className="p-5">
                    <div className="project-list-head">
                        <h2>Project Members</h2>
                        <div className="project-head-right">
                        <Form className="d-flex search--form">
                            <FormControl
                                type="search"
                                placeholder="Search Member"
                                className="me-2"
                                aria-label="Search"
                            />  <FontAwesomeIcon icon={faSearch} size= "lg" className="search--icon"/>                  
                        </Form>
                        {functionService.checkEditStatus(currentProject.role,["1","2"]) &&
                            <Button className="add-btn" onClick={handleCreate}><FontAwesomeIcon icon={faPlus} className="me-3" />Add Member</Button>
                        }
                        </div>
                    </div>

                     <AddProjectMemberModal  handleClose= {handleClose} show= {show}/>

                    <div className="projects-list--on  mt-4">
                    {rows.length > 0  ?                             
                        <Row >
                            {rows && rows.map((obj,index)=>(
                                <Col md={4} key={index}><PeopleCard email={obj.email} phone={obj.phone_number} empName = {obj.name} empPosition= {obj.user_role}/></Col>
                            ))}                                                     
                        </Row>
                         :<><div className="text-center no-data-found"><Image src= {noDataImg}></Image></div></>  }
                    </div>                                                    
                </Col>
                </Row>
            </Container>            
        </> 
  );
  
}

export default Peoples;