import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Button, Form, FormControl } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { documentColumn} from "../column/columns";
import { UserContext } from "../../lib/UserContext";
import TreeTable from "../../components/Grid/Table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import BucketModal from '../../components/BucketModal';
import { functionService } from "../../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmModal from "../../components/ConfirmModal";
import AddDocModal from "../../components/AddDocModal";
function BugTracker() {
    const [loginUser] = useContext(UserContext);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const[currentProject,setCurrentProject] = useState({role:0});
    const handleShow = () =>setShow(true);
    const[isCreate,setIsCreate] = useState(0);
    const[currentPage,setCurrentPage] = useState(1);
  	const[handleModalState,setSandleModalState] = useState(false);
    const[totalRecords,setTotalRecords]=useState(0);
    const[selectedId,setSelectedId]=useState(0);
    const[confirmModal,handleConfirmModal]=useState(false);
    const[rows,setRows] = useState([]);
  	const params = useParams();
    const limit = 20;
    const getList = async()=>{
      let res = await functionService.get('documents/list',{limit:limit,currentPage:currentPage,projectId:params.projectid});
      if(res.status === true){
          if(currentPage === 1){
            setTotalRecords(res.response.data.total[0].total);
            setCurrentProject(res.response.data.projectresult);
          }
        setRows(res.response.data.result);
      }
    }
    useEffect(()=>{
        getList();
    },[currentPage,handleModalState]);
	
    const handleEditAction = (row)=>{
      setIsCreate(row.id);
      handleShow();
    }
    const handleDeleteAction = (row)=>{
      setSelectedId(row.id);
      handleConfirmModal(true);
    }
    const handleCreate = ()=>{
      setIsCreate(0);
      handleShow();
    }
    
  return (
    <>
    <Header />
    
    <Container fluid>
        <Row className="main--panel">
           <Sidebar/> 
           <Col md={10} className="p-5">
           <div className="project-list-head">
                 <h2>Documents</h2>
                 <div className="project-head-right">
                  <Form className="d-flex search--form">
                      <FormControl
                        type="search"
                        placeholder="search "
                        className="me-2"
                        aria-label="Search"
                      />  <FontAwesomeIcon icon={faSearch} size= "lg" className="search--icon"/>                  
                  </Form>
                  <Button className="add-btn" onClick={handleCreate}><FontAwesomeIcon icon={faPlus} className="me-3" />Add Document</Button>
                 </div>
               </div>
             <div className="projects-list--on card--fluid mt-4">
               
               <AddDocModal handleModalState={handleModalState} setSandleModalState={setSandleModalState} handleClose={handleClose} show={show} isCreate={isCreate}/>
               <ConfirmModal handleModalState={handleModalState} setSandleModalState={setSandleModalState} handleConfirmModal={handleConfirmModal} show={confirmModal} selectedId={selectedId} modalName={'bugtracker'} />
              <TreeTable
                  columns ={documentColumn}
                  contracts={rows}
                  rowsPerPage={limit}
                  totalRecords={totalRecords}
                  page={currentPage}
                  setPage ={setCurrentPage}
                  actions={(functionService.checkEditStatus(currentProject.role,["1","2"]) === true) ? ([

                    [
                      "Actions",
                      [
                        [["Delete", [<span className="delete--icon fa--icon"><FontAwesomeIcon icon={faTrash} className="sm--icon"/></span>],
                          (c) => true],handleDeleteAction] 
                      ],
                      {
                        width: 90,
                        background: "#26BDB8",
                        color: "#fff",
                        fontSize: "14px",
                        width:"100px",
                      }
                    ]
                  ]):[]}
                 
                />
             </div>
               
            </Col>
        </Row>
    </Container>
</> 
  );
  
}
export default BugTracker;