import React,{useState,useEffect} from "react";
import {Col} from "react-bootstrap";


function ProjectTeamCard ({cardHeading, headbg, borderbg,row,projectrole}){
		const[roles,setRoles]=useState({});
		useEffect(()=>{
			let arr = {};
			projectrole && projectrole.forEach(function(element){
				arr[element.role_id] = element.project_role;
			});
			setRoles(arr);
		},[projectrole]);

    return (
        <>
            <Col className="project-dashboard-box" style={borderbg}>
                <div className="proj-dashboard-head" style={headbg}> <h2>{cardHeading}</h2> </div>                
                
                <div className="proj-dashboard-details">
				   { row && row.map((obj,index)=>(
					<div className="detailRow" key={index}><p className="lbl">{ (roles && roles.hasOwnProperty(obj.role)) ? roles[obj.role] : ''}:</p><p className="val">{obj.name}</p></div>
				   ))}
                   
                   
                </div>

                
                
            </Col>
        </>
    )}

export default ProjectTeamCard;