import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Form, FormControl, Button } from "react-bootstrap";
import { UserContext } from "../../lib/UserContext";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch, faEye} from '@fortawesome/free-solid-svg-icons';
import TreeTable from "../../components/Grid/Table";
import {myTaskColumn,teamTaskColumn} from "../column/columns";
import { functionService } from "../../lib/functions";
import Avtarr from '../../static/images/avatar/3.jpg';
import Avtarr1 from '../../static/images/avatar/5.jpg';
import Image from 'react-bootstrap/Image';
import {  faTrash, faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
function MyTasks() {
    const [loginUser] = useContext(UserContext);
	 let currentUser = functionService.getCurrentUser();
   const[disabled,setDisabled]=useState(false);
	const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const[currentRow,setCurrentRow]=useState({});
   
    const handleViewAction = (row)=>{ setCurrentRow(row);  handleShow(); }
    const[rows,setRows] = useState([]);
    const date = new Date();
    
    const getItemList = async(startdate)=>{
        let currentUser = functionService.getCurrentUser();
       // console.log("currentUser",currentUser);
        let res = await functionService.get('sprints/mytasks',{start_date:startdate,uid:currentUser.sub});
        if(res.status === true){
          setRows(res.response.data.result);
        }
      }  
      useEffect(()=>{
       
        getItemList(date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate());
    },[]); 
	const handleTaskAction = async(e)=>{
     if(disabled === true){ return false;}
      setDisabled(true);
      let array = {...currentRow};
      array.task_status = e.target.value;
      setCurrentRow(array);
      let res = await functionService.update("tasks/status-update",{id:array.id,task_status:array.task_status});
      if(res.status === true){
        getItemList()
      }
      setDisabled(false);
      
  }
  var startDates = new Date(currentRow.start_date).toLocaleDateString().replaceAll('/','-');;   
  var endDates = new Date(currentRow.end_date).toLocaleDateString().replaceAll('/','-');;    

  return (
        <>
            <Header />
            <Container fluid>
                <Row className="main--panel">
                    <Sidebar/>
                        <Col md={10} className="p-5"> 
                            <div className="project-list-head">
                                <h2>{(currentUser && currentUser.role  === "admin") ? "Team Tasks": "My Tasks" }</h2>  
                                <div className="project-head-right">
                                    <Form className="d-flex search--form">
                                        <FormControl
                                            type="date"
                                            placeholder="search Task"
                                            className="me-2"
                                            aria-label="Search"
                                            onChange={(e)=>{getItemList(e.target.value)}}
                                        />  
                                        {/* <FontAwesomeIcon icon={faSearch} size= "lg" className="search--icon"/>                   */}
                                    </Form>
                                    {/* <Button className="add-btn" onClick={handleAddTaskShow}><FontAwesomeIcon icon={faPlus} className="me-3" />Add Task</Button> */}
                                </div>
                            </div>
                            

                            <div className="projects-list--on card--fluid mt-4">            
                            <TreeTable
                                columns ={(currentUser && currentUser.role  === "admin") ? teamTaskColumn : myTaskColumn}
                                contracts={ rows}  
                                actions={[                                   
                                    [
                                        "Actions",
                                        [
                                            [["View", [<span className="view--icon fa--icon me-3"><FontAwesomeIcon icon={faEye} size= "2x" className="sm--icon"/></span> ],
                                            (c) => true],handleViewAction] 
                                        ],
                                    {
                                        width: 90,
                                        background: "#26BDB8",
                                        color: "#fff",
                                        fontSize: "14px",
                                    }
                                    ]
                                ]}
                                
                                />
                            </div>     
                                                                                                    
                        </Col>
                </Row>
				<Offcanvas show={show} onHide={handleClose} placement="end" className= "task--offcanvas"> 
                        <Offcanvas.Header closeButton>
                          <h4>Task Id#{currentRow && currentRow.id}</h4>
                          <div className="task-action">                            
                            <Form.Group className="me-3" controlId="taskname">                              
                                <Form.Select aria-label="status" onChange={handleTaskAction} className="" value={currentRow && currentRow.task_status} disabled={currentRow && currentRow.task_status != 'Completed' ? false:true}>
                                      <option>Select Status</option>
                                      <option value="Not Started">Not Started</option>
                                      <option value="Running">Running</option>
                                      <option value="Completed">Completed</option>
                                </Form.Select> 
                            </Form.Group>                                             
                            {/* <span className="me-3 edit--icon1 fa--icon1"><FontAwesomeIcon icon={faEdit} size= "lg" className="sm--icon me-1"/>Edit</span>
                            <span className="me-3 delete--icon1 fa--icon1"><FontAwesomeIcon icon={faTrash} size= "lg" className="sm--icon me-1"/>Delete</span>  */}
                            <span className="close--icon1 fa--icon1" ><FontAwesomeIcon icon={faTrash} size= "sm" className="sm--icon me-1"/>Close</span>                                                                        
                          </div>                          
                        </Offcanvas.Header>
                        <Offcanvas.Body>                          
                          <div className="top_body">
                            <Form.Group className="mb-3" >                          
                              <Form.Control type="text" className="task--name" value={currentRow && currentRow.title}/>                          
                            </Form.Group>
                            <div className="other-details mb-3">
                              <p>Start date: <br/><span>{currentRow && startDates}</span></p>
                              <p>End date: <br/><span>{currentRow && endDates}</span></p>
                              <p>Assigned To: <br/><span>{currentRow && currentRow.assignedto}</span></p>
                              <p>Assigned By: <br/><span>{currentRow && currentRow.assignedby}</span></p>
                              <p>Duration: <br/><span>{currentRow && currentRow.duration} Hours</span></p>
                              <p></p>
                            </div>
                            
                                <div className="mb-3" >  
                                <Form.Label>Description</Form.Label>                                                
                                <Form.Control disabled as="textarea" rows={6} value={currentRow && currentRow.description}/>                          
                                </div>
                              
                              {/* <div  className="files--list">
                              <Form.Label>Files</Form.Label> 
                              <ul className="task-uploaded-files">
                                <li>FileName1.pdf</li>
                                <li>FileName2.jpg</li>
                                <li>FileName3.html</li>
                                <li>FileName4.png</li>
                                <li>FileName1.pdf</li>
                                <li>FileName2.jpg</li>
                                <li>FileName3.html</li>
                                <li>FileName4.png</li>
                              </ul>
                             </div> */}
                            
                          </div>
                          <div className="task--message--box">
                            <div className="taskmessage in">
                              <div className="avtar-img">
                                <Image src={Avtarr}></Image>
                              </div>
                              <div className="avtar-title">
                                <p className="avtar-name">John Doe <span>12:35pm</span></p>
                                <p className="avtar-text">Hello michel, please complete it as soon as possible</p>
                              </div>
                            </div>
                            <div className="taskmessage out">
                              <div className="avtar-img">
                                <Image src={Avtarr1}></Image>
                              </div>
                              <div className="avtar-title">
                                <p className="avtar-name">Amit Kumar <span>2:30pm</span></p>
                                <p className="avtar-text">Here is some issue i'm working on.Here is some issue i'm working on. Here is some issue i'm working on.s</p>
                              </div>
                            </div>
                            <div className="taskmessage in">
                              <div className="avtar-img">
                                <Image src={Avtarr}></Image>
                              </div>
                              <div className="avtar-title">
                                <p className="avtar-name">John Doe <span>12:30pm</span></p>
                                <p className="avtar-text">Hello michel, please complete it as soon as possible</p>
                              </div>
                            </div>
                            <div className="taskmessage out">
                              <div className="avtar-img">
                                <Image src={Avtarr1}></Image>
                              </div>
                              <div className="avtar-title">
                                <p className="avtar-name">Amit Kumar <span>2:30pm</span></p>
                                <p className="avtar-text">Here is some issue i'm working on.Here is some issue i'm working on. Here is some issue i'm working on.s</p>
                              </div>
                            </div>  
                            <div className="taskmessage out">
                              <div className="avtar-img">
                                <Image src={Avtarr1}></Image>
                              </div>
                              <div className="avtar-title">
                                <p className="avtar-name">Amit Kumar <span>2:30pm</span></p>
                                <p className="avtar-text">Here is some issue i'm working on.Here is some issue i'm working on. Here is some issue i'm working on.s</p>
                              </div>
                            </div>                          
                          </div>

                          <div className="send--message--panel">                            
                            <Form.Group className="text-box" >    
                              
                              <div className="attachicon"><FontAwesomeIcon icon={faPaperclip} size= "2x" className=""/> </div>                                               
                              <Form.Control type="text" rows={2} />   
                              <div className="sendmsgicon"><FontAwesomeIcon icon={faPaperPlane} size= "sm" className="sendmsgicon"/> </div>                      
                            </Form.Group>
                            
                            <div className="send-tools"></div>
                          </div>
                          
                        </Offcanvas.Body>
                      </Offcanvas>
            </Container>
        </>
  );
  
}

export default MyTasks;