import React, { Component } from "react";
// import Header from "../../components/Header";
import {Row,Col} from "react-bootstrap";
import {SOCKET_PATH} from "../../lib/api";
import UserList from "../../components/UserList";
import ChatBox from "../../components/ChatBox";
import ErrorModal from "../../components/ErrorModal";
// import LoadingModal from "../../components/LoadingModal";
import "react-chat-elements/dist/main.css";
import "../../index.css";
import io from "socket.io-client";
import { fetchUsers } from "../../requests";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import axios from "axios";
import { functionService } from "../../lib/functions";
// import Sidebar from "../../components/Sidebar";

/**
 * App Component
 *
 * initiaites Socket connection and handle all cases like disconnected,
 * reconnected again so that user can send messages when he is back online
 *
 * handles Error scenarios if requests from Axios fails.
 *
 */

class ChatMessaging extends Component {
  socket = null;

  state = {
    signInModalShow: false,
    users: [], // Avaiable users for signing-in
    userChatData: [], // this contains users from which signed-in user can chat and its message data.
    user: this.props.loginUser, // Signed-In User
    selectedUserIndex: null,
    showChatBox: false, // For small devices only
    showChatList: true, // For small devices only
    error: false,
    errorMessage: ""
  };
  constructor(props) {
    super(props);
    this.setState({ user:this.props.loginUser});
    console.log(this.props.loginUser);
     
  }
  handleChatStart(){
	  
	  //this.onUserClicked({user:this.props.loginUser});
  }
  /**
   *
   * Setups Axios to monitor XHR errors.
   * Initiates and listen to socket.
   * fetches User's list from backend to populate.
   */

 async componentDidMount() {
    this.initAxios();
    this.initSocketConnection();
    let res = await fetchUsers('',{limit:200000,currentPage:1});
    if(res.status === true){
        this.setState({ users:res.response.data.result, signInModalShow: false })
    }
    //await fetchUsers().then(users => this.setState({ users, signInModalShow: false }));
    this.setupSocketListeners();
    this.onUserClicked({user:{id:this.props.loginUser.id,name:this.props.loginUser.name}});
  }

  initSocketConnection() {
    this.socket = io.connect(SOCKET_PATH);
  }

  /**
   * 
   * Checks if request from axios fails
   * and if it does then shows error modal.
   */
  initAxios() {
    axios.interceptors.request.use(
      config => {
        this.setState({ loading: true });
        return config;
      },
      error => {
        this.setState({ loading: false });
        this.setState({
          errorMessage: `Couldn't connect to server. try refreshing the page.`,
          error: true
        });
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      response => {
        this.setState({ loading: false });
        return response;
      },
      error => {
        this.setState({ loading: false });
        this.setState({
          errorMessage: `Some error occured. try after sometime`,
          error: true
        });
        return Promise.reject(error);
      }
    );
  }

  /**
   *
   * Shows error if client gets disconnected.
   */
  onClientDisconnected() {
    NotificationManager.error(
      "Connection Lost from server please check your connection.",
      "Error!"
    );
  }

  /**
   *
   * Established new connection if reconnected.
   */
  onReconnection() {
    if (this.state.user) {
      this.socket.emit("sign-in", this.state.user);
      NotificationManager.success("Connection Established.", "Reconnected!");
    }
  }

  /**
   *
   * Setup all listeners
   */

  setupSocketListeners() {
    this.socket.on("message", this.onMessageRecieved.bind(this));
    this.socket.on("reconnect", this.onReconnection.bind(this));
    this.socket.on("disconnect", this.onClientDisconnected.bind(this));
  }

  /**
   *
   * @param {MessageRecievedFromSocket} message
   *
   * Triggered when message is received.
   * It can be a message from user himself but on different session (Tab).
   * so it decides which is the position of the message "right" or "left".
   *
   * increments unread count and appends in the messages array to maintain Chat History
   */

  onMessageRecieved(message) {
	 
    let userChatData = this.state.userChatData;
    let messageData = message.message;
    let targetId;
    
    if (message.from === this.state.user.id) {
      messageData.position = "right";
      targetId = message.to;
    } else {
      messageData.position = "left";
      targetId = message.from;
    }
    let targetIndex = userChatData.findIndex(u => u.id === targetId);
    if (!userChatData[targetIndex].messages) {
      userChatData[targetIndex].messages = [];
    }
    if (targetIndex !== this.state.selectedUserIndex) {
      if (!userChatData[targetIndex].unread) {
        userChatData[targetIndex].unread = 0;
      }
      userChatData[targetIndex].unread++;
    }
    userChatData[targetIndex].messages.push(messageData);
    this.setState({ userChatData });
  }

  /**
   *
   * @param {User} e
   *
   * called when user clicks to sign-in
   */
  onUserClicked(e) {
    let user = e.user;
    let currentUser = functionService.getCurrentUser();
    user.id = currentUser.sub;
	  console.log("user",user);
    this.socket.emit("sign-in", user);
    let userChatData = this.state.users.filter(u => u.id !== user.id);
    this.setState({ user, signInModalShow: false, userChatData });
  }

  /**
   *
   * @param {ChatItem} e
   *
   * handles if user clickes on ChatItem on left.
   */
  onChatClicked(e) {
    this.toggleViews();
    let users = this.state.userChatData;
    for (let index = 0; index < users.length; index++) {
      if (users[index].id === e.user.id) {
        users[index].unread = 0;
        this.setState({ selectedUserIndex: index, userChatData: users });
        
        return;
      }
    }
  }

  /**
   *
   * @param {messageText} text
   *
   * creates message in a format in which messageList can render.
   * position is purposely omitted and will be appended when message is received.
   */
  createMessage(text) {
    console.log("this.state.userChatData[this.state.selectedUserIndex].id",this.state.userChatData[this.state.selectedUserIndex].id);
    console.log("this.state.user.id",this.state.user.id);
    let message = {
      to: this.state.userChatData[this.state.selectedUserIndex].id,
      message: {
        type: "text",
        text: text,
        date: +new Date(),
        className: "message"
      },
      from: this.state.user.id
    };
    this.socket.emit("message", message);
  }

  /**
   * Toggles views from 'ChatList' to 'ChatBox'
   *
   * only on Phone
   */
  toggleViews() {
    this.setState({
      showChatBox: !this.state.showChatBox,
      showChatList: !this.state.showChatList
    });
  }

  render() {
    

    return (
      <div>
       
        
          <Row className="show-grid">
            <Col md={4} className="Chat-panel-userlist">
              <UserList
                userData={this.state.userChatData}
                onChatClicked={this.onChatClicked.bind(this)}
              />
            </Col>
            <Col md={8}>
              <ChatBox
                signedInUser={this.state.user}
                onSendClicked={this.createMessage.bind(this)}
                onBackPressed={this.toggleViews.bind(this)}
                targetUser={
                  this.state.userChatData[this.state.selectedUserIndex]
                }
              />
			 
            </Col>
          </Row>
          
       
        <ErrorModal
          show={this.state.error}
          errorMessage={this.state.errorMessage}
        />
       
        <NotificationContainer />
      </div>
    );
  }
}

export default ChatMessaging;
