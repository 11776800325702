import axios from 'axios';
import { API_PATH } from './lib/api';
export const fetchUsers = (path,formdata={}) => {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    let currentUser = localStorage.getItem("currentUser");
    if(currentUser){
        axiosRequestOptions['headers']['authorization'] = currentUser;
        formdata['token'] = currentUser;
        axiosRequestOptions['headers']['body'] = JSON.stringify(formdata);
    }
  
   return axios.get(`${API_PATH}user/list`, axiosRequestOptions)
        .then(response => {
             return {status:true,response};
        })
        .catch((error) => {
            return {status:false,error};
        });
  
}