import React,{useState,useEffect} from "react";
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import { functionService } from "../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { addProjectField, validate } from "./ValidationChecked";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function AddProjectModal({handleModalState,setSandleModalState,handleClose,show,isCreate=0}) {   
  const[formData,setFormData]=useState({});
	const[message,setMessage] = useState('');
	const params = useParams();
  const[projectTech,setProjectTech]=useState([]);
  const[disabled,setDisabled]=useState(false);
  const[errors,setErrors]=useState({});
	const[btnLoader,setBtnLoader]=useState(false);
	const handleAction = async ()=>{
    if(validate(formData,addProjectField) !== 0){ setErrors(validate(formData,addProjectField)); return false;}
		setMessage('');
    if(disabled === true){ return false;}
    setBtnLoader(true);
    setDisabled(true);
		formData.project_id =params.projectid;
    let res = {};
    if (isCreate === 0) {
      res = await functionService.post("project/save",formData);
    }else{
      formData.id = isCreate;
      res = await functionService.update("project/update",formData);
    }
    setBtnLoader(false);
    setDisabled(false);
		if(res.status === true){
			handleClose();
			setSandleModalState(!handleModalState);
        }else{
            setMessage(res.error.message);
        }
	}
  const getItem = async()=>{
    let res = await functionService.get(`project/get/${isCreate}`,{id:isCreate});
    if(res.status === true){
       setFormData(res.response.data);
       let sd = (res.response.data.start_date).substr(0,10)
       console.log("project",sd)
    }
  }
  const getTech = async()=>{
    let res = await functionService.get(`project/project-tech`,{});
    if(res.status === true){
      setProjectTech(res.response.data);
    }
  } 
  useEffect(()=>{
    if(isCreate !== 0 && show === true){
      getItem();
     }
     getTech();
     setFormData({});
     setErrors({});
  },[isCreate,show]);
    return (
      <>
        <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-project-modal"
        >          
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-project-modal">
            {isCreate === 0 ? 'Add New' : 'Update' } Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Project Name</Form.Label>
                  <Form.Control type="text" placeholder="Project Name" value={formData && formData.name} onChange={(e)=>{setFormData({...formData,'name':e.target.value})}}/>
                  {errors && <p className="error">{errors['name']}</p>}
                </Form.Group>              
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control type="date" placeholder="2021-01-05" value={formData && functionService.getFormatedDate(formData.start_date)} onChange={(e)=>{setFormData({...formData,'start_date':e.target.value})}}/>
                  {errors && <p className="error">{errors['start_date']}</p>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control type="date" placeholder="2021-01-05" value={formData && functionService.getFormatedDate(formData.end_date)} onChange={(e)=>{setFormData({...formData,'end_date':e.target.value})}}/>
                  {errors && <p className="error">{errors['end_date']}</p>}
                </Form.Group>              
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control type="text" placeholder="Duration" value={formData && formData.duration} onChange={(e)=>{setFormData({...formData,'duration':e.target.value})}}/>
                  {errors && <p className="error">{errors['duration']}</p>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Status</Form.Label>
                  <Form.Select aria-label="status" className="form-control" value={formData && formData.status} onChange={(e)=>{setFormData({...formData,'status':e.target.value})}}>
                        <option >Select Status</option>
                        <option value="Not Started">Not Started</option>
                        <option value="Analysis">Analysis</option>
                        <option value="Hold">Hold</option>
                        <option value="Running">Running</option>
                        <option value="Completed">Completed</option>
                  </Form.Select> 
                  {errors && <p className="error">{errors['status']}</p>}
                </Form.Group>              
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Project Tech</Form.Label>
                  <Form.Select aria-label="status" className="form-control" value={formData && formData.project_tech} onChange={(e)=>{setFormData({...formData,'project_tech':e.target.value})}}>
                        <option value="0">Select Tech</option>
                        { projectTech && projectTech.map((obj,index)=>(
                             <option value={obj.id} key={index}>{obj.name}</option>
                        ))}
                  </Form.Select> 
                  {errors && <p className="error">{errors['project_tech']}</p>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Staging Url</Form.Label>
                  <Form.Control type="text" placeholder="Staging Url" value={formData && formData.staging_url} onChange={(e)=>{setFormData({...formData,'staging_url':e.target.value})}}/>
                  
                </Form.Group>              
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Live Url</Form.Label>
                  <Form.Control type="text" placeholder="Live Url" value={formData && formData.live_url} onChange={(e)=>{setFormData({...formData,'live_url':e.target.value})}}/>
                </Form.Group>
              </Col>
            </Row>
            <Row>              
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" placeholder="Description" rows={4} value={formData && formData.description} onChange={(e)=>{setFormData({...formData,'description':e.target.value})}}/>
                {errors && <p className="error">{errors['description']}</p>}
              </Form.Group>
            </Row>
            <Row>              
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                <Form.Label>Other Details</Form.Label>
                <Form.Control as="textarea" placeholder="Other Details" rows={4} value={formData && formData.other_details} onChange={(e)=>{setFormData({...formData,'other_details':e.target.value})}}/>
              </Form.Group>
            </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
            {isCreate === 0 ?           
             <Button className="add-btn" onClick={()=>{handleAction(isCreate)}}>{btnLoader && <FontAwesomeIcon icon={faSpinner} spin />} Create</Button>
              :
              <Button className="add-btn"  onClick={()=>{handleAction(isCreate)}}>{btnLoader && <FontAwesomeIcon icon={faSpinner} spin />} Update</Button>
            }
          <Button variant="outline-dark close-btn" onClick={handleClose}>
            Close
          </Button>
		  {message !== '' && message}
        </Modal.Footer>
        </Modal>        
        
      </>
    );
  }
  
 