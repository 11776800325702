import React from "react";
import {Col} from "react-bootstrap";

function DashboardClientUpdate ({ clientName, stylebg, clientCompany,date,details=""}){     
    let content = details.replace(/<\/?[^>]+(>|$)/g, "");
    var dates = new Date(date).toLocaleDateString().replaceAll('/','-');    
    return (
        <>
            <Col className="recnt-client-box" style={stylebg}>                             
                <h4 className="client-name-">{clientName}</h4> 
                <p className="client-company">{clientCompany.substring(0, 80)}</p>             
                
                <p className="client-msg">{content.substring(0, 80) + "..."}</p>  
                <p className="client-msg-time">Date: {dates}</p>            
            </Col>
        </>
    )}

export default DashboardClientUpdate;