import React from "react";
import {Col} from "react-bootstrap";


function ProjectTestCard ({cardHeading, headbg, borderbg,row}){      

    return (
        <>{row &&
            <Col className="project-dashboard-box" style={borderbg}>
                <div className="proj-dashboard-head" style={headbg}> <h2>{cardHeading}</h2> </div>                
                
                <div className="proj-dashboard-details">
                    <div className="detailRow"><p className="val"> <span className="lbl">{row.cases && row.cases[0].cnt}</span> Total Test Cases </p></div>
					 
					 <div className="detailRow"><p className="val"><span className="lbl">{row.unittesting && row.unittesting[0].cnt}</span> Total Unit Testing</p></div>
                    <div className="detailRow">
                        <p className="val">Total <span className="lbl">{row.bugs && row.bugs[0].cnt}</span> Bugs / <span className="lbl">{row.closebugs && row.closebugs[0].cnt}</span> Bugs resolved</p>
                    </div>
                   <div className="detailRow"><p className="val"><span className="lbl">{row.bugs && (row.bugs[0].cnt - row.closebugs[0].cnt) }</span> Running Bugs</p></div>
                    
                    {/* <Button className="add-btn"><FontAwesomeIcon icon={faPlus} className="me-3" />Add Task</Button>   */}
                </div>

                
                
            </Col>
		}
        </>
    )}

export default ProjectTestCard;