import React,{useState,useEffect} from "react";
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import { functionService } from "../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AutoFillDropDown from "./AutoFillDropDown";
import { taskItem, validate } from "./ValidationChecked";
import FileUploader from "./FileUploader";
import SunEditor from "./SunEditor";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function AddTaskModal({handleModalState,setSandleModalState,handleClose,show,isCreate=0,task_id=0}) {   
  const[formData,setFormData]=useState({});
	const[message,setMessage] = useState('');
	const params = useParams();
  const[disabled,setDisabled]=useState(false);
  const[errors,setErrors]=useState({}); 
  const[projectRisks,setProjectRisks]=useState([]);
  const[btnLoader,setBtnLoader]=useState(false);
  const currentUser = functionService.getCurrentUser();
  const[trigger,setTrigger]=useState(0);

	const handleAction = async (isClose)=>{
    if(validate(formData,taskItem) !== 0){ setErrors(validate(formData,taskItem)); return false;}
		setMessage('');
    if(disabled === true){ return false;}
    setDisabled(true);
    setBtnLoader(true);
		formData.project_id =params.projectid;
    formData.user_id = currentUser.sub;
    let res = {};
    if (isCreate === 0) {
      formData.task_id = task_id;
      res = await functionService.post("tasks/save",formData);
      formData.title="";
      formData.description="";
      formData.priority="";
      formData.start_date="";
      formData.end_date="";
      formData.duration="";
      formData.risks_id="";
    }else{
      formData.id = isCreate;
      res = await functionService.update("tasks/update",formData);
      handleClose();
    }
		setDisabled(false);
		setBtnLoader(false);
		if(res.status === true){
			isClose === 1 && 	handleClose();
			setSandleModalState(!handleModalState);
        }else{
            setMessage(res.error.message);
        }
	}
  const getItem = async()=>{
    let res = await functionService.get(`tasks/get/${isCreate}`,{id:isCreate,projectId:params.projectid});
    if(res.status === true){
       setFormData(res.response.data.result);
      
    }
  }
  const getRisks = async()=>{
    let res = await functionService.get(`risks/open/list`,{projectId:params.projectid});
    if(res.status === true){
      setProjectRisks(res.response.data);
      
    }
  }
 const handleChangeAction= (val)=>{
   setFormData({...formData,developer_id:val});
 }
  useEffect(()=>{
    if(isCreate !== 0 && show === true){
      getItem();
      
     }
     getRisks();
     setFormData({});
     setErrors({});
  },[isCreate,show]);
    return (
      <>
        <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-project-modal"
        >          
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-project-modal">
            {isCreate === 0 ? 'Add New' : 'Update' } Task
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
          <Row>
              <Col md = {12}>
                <Form.Group className="mb-3" controlId=""> 
                <Form.Label>Task Name</Form.Label>
                <Form.Control type="text" placeholder="Title" value={formData && formData.title} onChange={(e)=>{setFormData({...formData,'title':e.target.value})}}/>              
                {errors && <p className="error">{errors['title']}</p>}
                </Form.Group>
              </Col>
          </Row>
          <Row>
            <Col md = {12}>
              <Form.Group className="mb-3" controlId=""> 
              <Form.Label>Description</Form.Label>
              <SunEditor placeholder="Description" value={formData && formData.description} onChange={(e)=>{setFormData({...formData,'description':e})}}/>              
               {errors && <p className="error">{errors['description']}</p>}
              </Form.Group>
            </Col>
          </Row> 

            <Row>
              
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Priority</Form.Label>
                  <Form.Select aria-label="task" className="form-control" value={formData && formData.priority} onChange={(e)=>{setFormData({...formData,'priority':e.target.value})}}>                  
                  <option value="">Select</option>
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </Form.Select>
                  {errors && <p className="error">{errors['priority']}</p>}
                </Form.Group>              
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Assigned To</Form.Label>
                  <AutoFillDropDown formData={formData} setFormData={handleChangeAction} path={'project/member'} name={'developer_id'}  parameter={{projectId:params.projectid}} attr_name={'name'} />
                  {errors && <p className="error">{errors['developer_id']}</p>}
                </Form.Group>
              </Col>
            </Row>
      
            <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId=""> 
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="date" placeholder="Start Date" value={formData && functionService.getFormatedDate(formData.start_date)} onChange={(e)=>{setFormData({...formData,'start_date':e.target.value})}}/>              
                {errors && <p className="error">{errors['start_date']}</p>}
                </Form.Group>
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId=""> 
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" placeholder="End Date" value={formData && functionService.getFormatedDate(formData.end_date)} onChange={(e)=>{setFormData({...formData,'end_date':e.target.value})}}/>              
                {errors && <p className="error">{errors['end_date']}</p>}
                </Form.Group>
              </Col>
          </Row> 
          <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId=""> 
                <Form.Label>Duration</Form.Label>
                <Form.Control type="text" placeholder="Duration" value={formData && formData.duration} onChange={(e)=>{setFormData({...formData,'duration':e.target.value})}}/>              
                {errors && <p className="error">{errors['duration']}</p>}
                </Form.Group>
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Task Risk</Form.Label>
                  <Form.Select aria-label="status" className="form-control" value={formData && formData.risks_id} onChange={(e)=>{setFormData({...formData,'risks_id':e.target.value})}}>
                        <option value="0">Select Risks</option>
                        { projectRisks && projectRisks.map((obj,index)=>(
                             <option value={obj.id} key={index}>{obj.description}</option>
                        ))}
                  </Form.Select> 
                  {errors && <p className="error">{errors['Project_tech']}</p>}
                </Form.Group>
              </Col>
          </Row>         
            <Row>
              <Col md = {12}>
                <FileUploader formData={formData} setFormData={setFormData}/>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
            {isCreate === 0 ? <>          
             <Button className="add-btn" onClick={()=>{setTrigger(0); handleAction(isCreate)}}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Create</Button>
            <Button className="add-btn" onClick={() => {setTrigger(1); handleAction(1) }}>{(btnLoader && trigger === 1) && <FontAwesomeIcon icon={faSpinner} spin />} Create & Close</Button> </> 
              
             :
              <Button className="add-btn"  onClick={()=>{setTrigger(0); handleAction(isCreate)}}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Update</Button>
            }
          <Button variant="outline-dark close-btn" onClick={handleClose}>
            Close
          </Button>
		  {message !== '' && message}
        </Modal.Footer>
        </Modal>        
        
      </>
    );
  }
  
  
 