import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function AnchorLink({label,link}) {
   const history = useHistory();
  return (
           <><span className="anchor--link" onClick={()=>{history.push(link)}}>{label}</span></>
  );
  
}

export default AnchorLink;