import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { functionService } from '../lib/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross, faCrosshairs, faWindowClose } from '@fortawesome/free-solid-svg-icons';

function FileUploader({formData,setFormData,limit=100}) {
  const[uploaded,setUploaded]=useState([]);
  const[file,setFile]=useState();
  const[message,setMessage]=useState("");
  
 const uploadHandler = async (event) =>{
   setMessage("");
   if(uploaded.length === limit){ setMessage("You have already upload maximum number of file"); return false;}
    const data =  event.target.files[0];
    setFile(event.target.files[0].name);
    let res = await functionService.uploadFile(data);
    let uploadedFile = res.response.data.result;
    setUploaded( uploaded=> [...uploaded,uploadedFile]);
    event.target.value = null;
  }
  const handleRemove =(index)=>{
    console.log(index);
    setMessage("");
    setUploaded(uploaded.filter(item => item.id !== index));  
  }
  useEffect(()=>{
    setFormData({...formData,images:JSON.stringify(uploaded)});
  },[uploaded])
  console.log("uploaded",uploaded);
    return  (
      <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Label>Choose document or image</Form.Label>
            <Form.Control type="file" multiple name="file" onChange={uploadHandler} className='file--uploader'/>
            {message && <p className="error">{message}</p>}
            {uploaded.length > 0 && 
            <ul className='uploaded-images'>
                {uploaded &&  uploaded.map((obj,index)=>(
                  <li key={index}> <img src={`/static/icons/${(obj.ext).toLowerCase()}-sm.png`} /> {(obj.name).replace("public/","")} <FontAwesomeIcon icon={faWindowClose}  size="lg" onClick={()=>{handleRemove(obj.id)}} className='ms-3 close-icon'/>
				  
				  </li>
                ))}
            </ul>
                }
      </Form.Group>
    )
 
}
 
export default FileUploader;