import React,{useState} from "react";
import {Modal, Button} from 'react-bootstrap';
import { functionService } from "../lib/functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function ConfirmModal({handleModalState,setSandleModalState,handleConfirmModal,show,selectedId,modalName}) {   
  	const[message,setMessage] = useState('');
    const[disabled,setDisabled]=useState(false);
	const[btnLoader,setBtnLoader]=useState(false);
	const handleAction = async ()=>{
		setMessage('');
    if(disabled === true){ return false;}
    setDisabled(true);
    setBtnLoader(true);
        let res = await functionService.remove(`${modalName}/delete/${selectedId}`);
        if(res.status === true){
			handleConfirmModal(false);
			setSandleModalState(!handleModalState);
        }else{
            setMessage(res.error.message);
        }
        setDisabled(false);
        setBtnLoader(false);
	
	}
 
    return (
      <>
        <Modal
        show={show}
        size="md"
        onHide={()=>{handleConfirmModal(false)}}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-project-modal"
        className="confirm--modal"
        >          
        {/* <Modal.Header>
          <Modal.Title id="handle-add-project-modal">
            Are you sure !
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="text-center border-0">
            <FontAwesomeIcon icon={faExclamationTriangle} size= "5x" className="cautious--icon"/>
            <p className="delete--message mt-4">You want to delete it !</p>
        </Modal.Body>
        <Modal.Footer>
            
          <Button className="add-btn"  onClick={()=>{handleAction()}}>{btnLoader && <FontAwesomeIcon icon={faSpinner} spin />} Confirm</Button>
          <Button variant="outline-dark close-btn" onClick={()=>{handleConfirmModal(false)}}>
            Cancel
          </Button>
		  {message !== '' && message}
        </Modal.Footer>
        </Modal>        
        
      </>
    );
  }
  
 