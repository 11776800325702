import React, { useState,useEffect } from "react";
import { UserContext } from './lib/UserContext';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import './index.css';
// import "react-datepicker/dist/react-datepicker.css";
import Login from "./Pages/login/Login";
import Dashboard from "./Pages/dashboard/Dashboard";
import Users from "./Pages/users/Users";
import AddUser from "./Pages/users/AddUser";
import EditUser from "./Pages/users/EditUser";
import ViewUser from "./Pages/users/ViewUser";
import Projects from "./Pages/projects/Projects";
import AddProject from "./Pages/projects/AddProject";
import ViewProject from "./Pages/projects/ViewProject";
import EditProject from "./Pages/projects/EditProject";
import BucketHours from "./Pages/projects/BucketHours";
import BugTracker from "./Pages/projects/BugTracker";
import Documents from "./Pages/projects/Documents";
import TestCase from "./Pages/projects/TestCase";
import UnitTesting from "./Pages/projects/UnitTesting";
import TaskBugTracker from "./Pages/projects/TaskBugTracker";
// import ProjectHome from "./Pages/projects/Home";
import Meetings from "./Pages/projects/Meetings";
import ProjectMessages from "./Pages/projects/Messages";
import MessageUpdates from "./Pages/projects/MessageUpdates";
import MileStones from "./Pages/projects/MileStones";
import MyTasks from "./Pages/projects/MyTasks";
import Overview from "./Pages/projects/Overview";
import Peoples from "./Pages/projects/Peoples";
import Risks from "./Pages/projects/Risks";
import Tasks from "./Pages/projects/Tasks";
import ChatPanel from "./Pages/chat/ChatPanel";
import Holiday from "./Pages/calendar/Holiday";
import Customers from "./Pages/users/Clients";
import Notification from "./Pages/notification/Notification";
import PostNewMessage from "./components/PostNewMessage";
import Announce from "./Pages/calendar/Announce";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


function App() {
  //const [loginUser, setLoginUser] = useState({id:1,name:'dev'});
     let loginData = null;
	 let currentUser = localStorage.getItem("currentUser");
	 if(currentUser && currentUser !== null){
		  let name = localStorage.getItem("currentUserName");
		  loginData = {id:0,name:name,token:currentUser};
		  console.log("currentUser",currentUser);
	  }
  const [loginUser, setLoginUser] = useState(loginData);
  useEffect(()=>{
	  
  },[]);
  return (
    <UserContext.Provider value={[loginUser, setLoginUser]}>
    <Router>
      <Switch>
          <Route
          exact
          path="/"
          render={() => <Redirect to="/dashboard" />}
        />
     
        <PrivateRoute path="/dashboard" component={Dashboard} />
        {/*User management*/}
        <PrivateRoute path="/users" component={Users} />
        <PrivateRoute path="/customers" component={Customers} />
        <PrivateRoute path="/user/add" component={AddUser} />
        <PrivateRoute path="/user/:userid/edit" component={EditUser} />
        <PrivateRoute path="/user/:userid/view" component={ViewUser} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/notification" component={Notification} />

         {/*Prject management*/}
         <PrivateRoute path="/my-task" component={MyTasks} />
         <PrivateRoute path="/projects" component={Projects} />
         <PrivateRoute path="/announce" component={Announce} />
         <PrivateRoute path="/project/add" component={AddProject} />
         <PrivateRoute path="/project/:projectid/view" component={ViewProject} />
         <PrivateRoute path="/project/:projectid/edit" component={EditProject} />
         <PrivateRoute path="/project/:projectid/bucket" component={BucketHours} />
         <PrivateRoute path="/project/:projectid/bug-tracker" component={BugTracker} />
         <PrivateRoute path="/project/:projectid/task-bug-tracker/:id" component={TaskBugTracker} />
         <PrivateRoute path="/project/:projectid/documents" component={Documents} />
         <PrivateRoute path="/project/:projectid/meetings" component={Meetings} />
         <PrivateRoute path="/project/:projectid/message/:messagesid/view" component={MessageUpdates} />
         <PrivateRoute path="/project/:projectid/new/message" component={PostNewMessage} />
		     <PrivateRoute path="/project/:projectid/messages" component={ProjectMessages} />
         <PrivateRoute path="/project/:projectid/milestones" component={MileStones} />         
         <PrivateRoute path="/project/:projectid/overview" component={Overview} />
         <PrivateRoute path="/project/:projectid/risks" component={Risks} />
         <PrivateRoute path="/project/:projectid/tasks" component={Tasks} />
         <PrivateRoute path="/project/:projectid/peoples" component={Peoples} />
         <PrivateRoute path="/project/:projectid/test-cases" component={TestCase} />
         <PrivateRoute path="/project/:projectid/unit-testing" component={UnitTesting} />
          {/*Prject management*/}
          <PrivateRoute path="/discussion" component={ChatPanel} />
          {/* Calendar*/}
          <PrivateRoute path="/holiday" component={Holiday} />
       </Switch>
    </Router>
    </UserContext.Provider>
  );
  
  function PublicRoute({ component, ...rest } ) {
    return (
      <Route
        {...rest}
        render={props =>
         (
            React.createElement(component, props)
          )
        }
      />
    );
  }
  function PrivateRoute({ component, ...rest } ) {
    return (
	  (typeof loginUser !== 'undefined' && loginUser !== null) ?
      <Route
        {...rest}
        render={props =>
         (
            React.createElement(component, props)
          )
        }
      />
	  :
	  <Redirect to="/login" />
	  
    );
  }
}

export default App;