import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Button, Form, FormControl } from "react-bootstrap";
import Header from "../../components/Header";
import Image from 'react-bootstrap/Image';
import Avtar2 from '../../static/images/avatar/2.jpg';
import Avtar from '../../static/images/avatar/3.jpg';
import Avtar1 from '../../static/images/avatar/4.jpg';
import Sidebar from "../../components/Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { functionService } from "../../lib/functions";
import { useParams } from "react-router-dom";

function Notification() {
  let date = new Date();
  const[rows,setRows] = useState([]);
  const params = useParams();
  const[currentPage,setCurrentPage] = useState(1);
  const[totalRecords,setTotalRecords]=useState([]);
  const limit = 20;
  const getList = async()=>{
    let res = await functionService.get('notification/list',{limit:limit,currentPage:currentPage});
    if(res.status === true){
        if(currentPage === 1){
          let total = (res.response.data.total[0].total);
          let totalPages = [];
          let totalPage = Math.ceil(total / limit);
          for (var i = 1; i <= totalPage; i++) {totalPages.push(i); }
          //console.log("totalPage",totalPage);
          setTotalRecords(totalPages);
        }
      setRows(res.response.data.result);
    }
  }
  useEffect(()=>{
      getList();
  },[currentPage]);  
    
  return (
    <>
    <Header />
    
    <Container fluid>
        <Row className="main--panel">
           <Sidebar/> 
           <Col md={10} className="p-5">
           <div className="project-list-head">
                 <h2>Notifications</h2>
                 <div className="project-head-right">
                  <Form className="d-flex search--form me-0">
                      <FormControl
                        type="search"
                        placeholder="search"
                        className="mr-0"
                        aria-label="Search"
                      />  <FontAwesomeIcon icon={faSearch} size= "lg" className="search--icon"/>                  
                  </Form>
                  {/* <Button className="add-btn" onClick={handleCreate}><FontAwesomeIcon icon={faPlus} className="me-3" />Add Customer</Button> */}
                 </div>
               </div>
             <div className="notification--panel mt-4"> 
                
              {rows && rows.map((obj,index)=>(
                <div className="notification-card" key={index}>                
                <div className="notification-details">
                    <Image roundedCircle src={Avtar2} className="avatar_img" />
                    <p>{obj.notification_text}</p>
                </div>
                <p className="notification-time"> {obj.created_at}</p>
              </div>
              ))}          
            
             </div>
             <>
      <div className="usr-pagination text-right">
                    {totalRecords.length > 1 &&
                      totalRecords.map((data, index) => (
                        <span
                          onClick={() => {
                            setCurrentPage(data);
                          }}
                          className={
                            currentPage === data
                              ? "pagination current "
                              : "pagination"
                          }
                          key={index}
                        >
                          {data}
                        </span>
                      ))}
                  </div>
   </>
            </Col>
        </Row>
    </Container>

</> 
  );
  
}
export default Notification;