import React, { useEffect, useState } from "react";
import {Col} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook,  faBug, faBullhorn, faEnvelope, faHome, faList, faListAlt, faMicrochip, faObjectGroup, faShoppingBag, faUser, faVirus } from '@fortawesome/free-solid-svg-icons';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { functionService } from '../lib/functions';
import Footer from './Footer';
export default function Sidebar (){
    const history = useHistory();
    let currentUser = functionService.getCurrentUser();
    console.log("currentUser",currentUser);
    const logout = (e)=>{
		e.preventDefault();
		localStorage.removeItem("currentUser");
		history.push("/login");
	}
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const[projectId,setProjectId]=useState(0);
    const params = useParams();
    
    useEffect(()=>{
        if(params && typeof params.projectid !== "undefined"){
            handleShow();
            setProjectId(params.projectid);
        }
    },[params,handleShow,setProjectId])
    return (
        <>
        <Col md={2} className="sidebar--panel">
        <div className="sidebar--light">
            <ul className="sidebar--menu" >
                <li><Link to="/dashboard"> <FontAwesomeIcon icon={faHome} className="me-3"/>  Dashboard</Link></li>
                <li><Link to="/projects" > <FontAwesomeIcon icon={faBook} className="me-3"/> Projects</Link></li>
                <li><Link to="/my-task"> <FontAwesomeIcon icon={faTasks} className="me-3"/> {(currentUser && currentUser.role  === "admin") ? "Team Tasks": "My Tasks" }</Link></li>
                
                {(currentUser && currentUser.role  === "admin") && 
                <>
                    <li><Link to="/users"> <FontAwesomeIcon icon={faUserFriends} className="me-3"/> Members</Link></li>
                    <li><Link to="/customers"> <FontAwesomeIcon icon={faUser} className="me-3"/> Customers/Agency</Link></li>
                    <li><Link to="/announce"> <FontAwesomeIcon icon={faBullhorn} className="me-3"/> Announce</Link></li>
                </>
                }
                <li><Link to="/holiday"> <FontAwesomeIcon icon={faCalendar} className="me-3"/> Holiday</Link></li>
                <li><Link to="/discussion"> <FontAwesomeIcon icon={faCommentDots} className="me-3"/> Discussion</Link></li>
                <li><Link to="/login" onClick={(e)=>{logout(e)}}> <FontAwesomeIcon icon={faSignInAlt}  className="me-3"/> Logout</Link></li>
            </ul>
            <Footer />
        </div>        
        


        <div className= {show === true ? "sidebar-project-menu open":"sidebar-project-menu"}>
            <div className="sidebar--panel">
            <div className="sidebar--light project--menu">
                <ul className="sidebar--menu " >
                    <li><Link to="/projects"> <FontAwesomeIcon icon={faArrowLeft} className="me-3"/>  Back to home</Link></li>
                    <li><Link to={`/project/${projectId}/view`} > <FontAwesomeIcon icon={faHome} className="me-3"/> Home</Link></li>
                    <li><Link to={`/project/${projectId}/messages`}> <FontAwesomeIcon icon={faEnvelope} className="me-3"/> Messages</Link></li> 
                    <li><Link to={`/project/${projectId}/milestones`}> <FontAwesomeIcon icon={faObjectGroup} className="me-3"/> Milestone</Link></li> 
                    <li><Link to={`/project/${projectId}/tasks`}> <FontAwesomeIcon icon={faTasks} className="me-3"/> Tasks</Link></li> 
                    <li><Link to={`/project/${projectId}/unit-testing`}> <FontAwesomeIcon icon={faList} className="me-3"/>Unit Testing</Link></li> 
                    <li><Link to={`/project/${projectId}/test-cases`}> <FontAwesomeIcon icon={faListAlt} className="me-3"/> Test Cases</Link></li> 
                    <li><Link to={`/project/${projectId}/bug-tracker`}> <FontAwesomeIcon icon={faBug} className="me-3"/> Bug Tracker</Link></li> 
                    <li><Link to={`/project/${projectId}/documents`}> <FontAwesomeIcon icon={faAddressBook} className="me-3"/> Document</Link></li>
                    <li><Link to={`/project/${projectId}/meetings`}> <FontAwesomeIcon icon={faCalendar} className="me-3"/> Meetings</Link></li>
                    <li><Link to={`/project/${projectId}/risks`}> <FontAwesomeIcon icon={faVirus} className="me-3"/> Risk</Link></li>
                    <li><Link to={`/project/${projectId}/bucket`}> <FontAwesomeIcon icon={faShoppingBag} className="me-3"/> Bucket Hour</Link></li>
                    <li><Link to={`/project/${projectId}/peoples`}> <FontAwesomeIcon icon={faUserFriends} className="me-3"/> Peoples</Link></li>                    
                </ul>
            </div>
            </div> 
      </div>
      </Col>
    </>
    );
  
}
