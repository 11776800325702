import React from "react";
import { API_HOME_PATH, HOME_PATH } from "../lib/api";

function ImagesExtract({documents}) {
    let documentsItem = JSON.parse(documents);
    return (
        <div className="uploader--images">
        {documentsItem && documentsItem.map((obj,index)=>(
            
              <a key={index} href={`${API_HOME_PATH}${obj.name}`} target={"_blank"} title={obj.name} alt={obj.name}> <img src={`${HOME_PATH}/static/icons/${obj.ext}-lg.png`} /></a>
            
        ))}
        </div>

    )   
}
export default ImagesExtract;