import React,{useState,useEffect} from "react";
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import { functionService } from "../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { riskfield, validate } from "./ValidationChecked";
import SunEditor from "./SunEditor";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function RisksModal({handleModalState,setSandleModalState,handleClose,show,isCreate=0}) {   
  const[formData,setFormData]=useState({});
	const[message,setMessage] = useState('');
	const params = useParams();
  const[disabled,setDisabled]=useState(false);
  const[errors,setErrors]=useState({}); 
  const[btnLoader,setBtnLoader]=useState(false);
  const[trigger,setTrigger]=useState(0); 
  const currentUser = functionService.getCurrentUser();

	const handleAction = async (isClose)=>{
    if(validate(formData,riskfield) !== 0){ setErrors(validate(formData,riskfield)); return false;}
		setMessage('');
    if(disabled === true){ return false;}
    setDisabled(true);
    setBtnLoader(true);
		formData.project_id =params.projectid;
    formData.user_id = currentUser.sub;
    let res = {};
    
    if (isCreate === 0) {
      res = await functionService.post("risks/save",formData);
      formData.identificationdate="";
      formData.reviewdate="";
      formData.description="";
      formData.riskowner ="";
      formData.category="";
      formData.status="";
      formData.end_date="";
    }else{
      formData.id = isCreate;
      res = await functionService.update("risks/update",formData);
      handleClose();
    }
    setDisabled(false);
    setBtnLoader(false);
		if(res.status === true){
			isClose === 1 && handleClose();
			setSandleModalState(!handleModalState);
        }else{
            setMessage(res.error.message);
        }
	}
  const getItem = async()=>{
    let res = await functionService.get(`risks/get/${isCreate}`,{id:isCreate});
    if(res.status === true){
       setFormData(res.response.data);
    }
  }
  useEffect(()=>{
    if(isCreate !== 0 && show === true){
      getItem();
     }
     setErrors({});
     setFormData({});
  },[isCreate,show]);
  console.log("form",formData);
    return (
      <>
        <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-project-modal"
        >          
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-project-modal">
            {isCreate === 0 ? 'Add New' : 'Update' } Risk
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Identification Date</Form.Label>
                  <Form.Control type="date" placeholder="Identification Date" value={formData && functionService.getFormatedDate(formData.identificationdate)} onChange={(e)=>{setFormData({...formData,'identificationdate':e.target.value})}}/>
                  {errors && <p className="error">{errors['identificationdate']}</p>}
                </Form.Group>              
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="taskname">
                  <Form.Label>Review Date</Form.Label>
                  <Form.Control type="date" placeholder="Review Date" value={formData && functionService.getFormatedDate(formData.reviewdate)} onChange={(e)=>{setFormData({...formData,'reviewdate':e.target.value})}}/>
                  {errors && <p className="error">{errors['reviewdate']}</p>}
                </Form.Group>
              </Col>
            </Row>

            <Row>              
              <Form.Group className="mb-3" controlId="RiskDescription">
                <Form.Label>Risk Description</Form.Label>
                <SunEditor   value={formData && formData.description} onChange={(e)=>{setFormData({...formData,'description':e})}}/>
                {errors && <p className="error">{errors['description']}</p>}
              </Form.Group>
            </Row>

            <Row>
              <Col md = {6}>
              <Form.Group className="mb-3" controlId="Agenda">
                <Form.Label>Risk Category</Form.Label>
                <Form.Select aria-label="taskpriority" value={formData && formData.category} className="form-control" onChange={(e)=>{setFormData({...formData,'category':e.target.value})}}>
                  <option value="">Risk Category</option>
                  <option value="Business">Business</option>
                  <option value="External">External</option>
                  <option value="Internal">Internal</option>
                  <option value="Pure">Pure</option>
                  <option value="Strategic">Strategic</option>
                  <option value="Technical">Technical</option>
                  <option value="Unforseen">Unforseen</option>
                </Form.Select>
                {errors && <p className="error">{errors['category']}</p>}
              </Form.Group>                              
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="Agenda">
                <Form.Label>Risk Owner</Form.Label>
                <Form.Control type="text" placeholder="Risk Owner" value={formData && formData.riskowner} onChange={(e)=>{setFormData({...formData,'riskowner':e.target.value})}}/>
                {errors && <p className="error">{errors['riskowner']}</p>}
              </Form.Group>
            </Col>
            </Row>

            <Row>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="Agenda">
                <Form.Label>Status</Form.Label>
                <Form.Select aria-label="status" className="form-control" value={formData && formData.status} onChange={(e)=>{setFormData({...formData,'status':e.target.value})}}>
                  <option>Status</option>
                  <option value="Open">Open</option>
                  <option value="Close">Close</option>
                  <option value="Ongoing">Ongoing</option>
                </Form.Select> 
                {errors && <p className="error">{errors['status']}</p>}
              </Form.Group>
                             
              </Col>
              <Col md = {6}>
                <Form.Group className="mb-3" controlId="Agenda">
                <Form.Label>Risk End Date</Form.Label>
                <Form.Control type="date" placeholder="Risk End Date" value={formData && functionService.getFormatedDate(formData.end_date)} onChange={(e)=>{setFormData({...formData,'end_date':e.target.value})}}/>
                {errors && <p className="error">{errors['end_date']}</p>}
                </Form.Group>
              </Col>
            </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
            {isCreate === 0 ?   <>        
             <Button className="add-btn" onClick={()=>{setTrigger(0); handleAction(0)}}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Create</Button>
             <Button className="add-btn" onClick={()=>{setTrigger(1); setTrigger(1);handleAction(1)}}>{(btnLoader && trigger === 1) && <FontAwesomeIcon icon={faSpinner} spin />} Create & Close</Button> </>
             :
              <Button className="add-btn"  onClick={()=>{setTrigger(0); handleAction(0)}}>{(btnLoader && trigger === 0) && <FontAwesomeIcon icon={faSpinner} spin />} Update</Button>
            }
          <Button variant="outline-dark close-btn" onClick={handleClose}>
            Close
          </Button>
		  {message !== '' && message}
        </Modal.Footer>
        </Modal>        
        
      </>
    );
  }
  
 