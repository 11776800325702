import React from "react";
import {Col} from "react-bootstrap";


function ProjectSpendTimeCard ({cardHeading, headbg, borderbg}){      

    return (
        <>
            <Col className="project-dashboard-box" style={borderbg}>
                <div className="proj-dashboard-head" style={headbg}> <h2>{cardHeading}</h2> </div>                
                
                <div className="proj-dashboard-details">
                    <div className="detailRow"><p className="lbl">Member Name:</p><p className="lbl">Planned/Spend Hours</p></div>
                    <div className="detailRow"><p className="lbl">Amit Sharma:</p><p className="val">4h:30m / 4h:30m</p></div>
                    <div className="detailRow"><p className="lbl">Gulasan Sir:</p><p className="val">6h:00m / 5h:40m</p></div>
                    <div className="detailRow"><p className="lbl">Amit Sharma:</p><p className="val">2h:30m / 2h:10m</p></div>
                    <div className="detailRow"><p className="lbl">Gulasan Sir:</p><p className="val">6h:00m / 5h:40m</p></div>
                    
                    {/* <Button className="add-btn"><FontAwesomeIcon icon={faPlus} className="me-3" />Add Task</Button>   */}
                </div>

                
                
            </Col>
        </>
    )}

export default ProjectSpendTimeCard;