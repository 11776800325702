import React, { useContext, useState,useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import { UserContext } from "../lib/UserContext";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import {  Link, useParams } from "react-router-dom";
import { functionService } from "../lib/functions";
import FileUploader from "./FileUploader";
import { messageField, validate } from "./ValidationChecked";
import { useHistory } from "react-router-dom";
import SunEditor from "./SunEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
function PostNewMessage() {
    const [loginUser] = useContext(UserContext);
    const[formData,setFormData]=useState({});
    const[message,setMessage] = useState('');
	const params = useParams();
    const history= useHistory();
    const[disabled,setDisabled]=useState(false);
	const[btnLoader,setBtnLoader]=useState(false);
    const[itemRows,setItemRows] = useState([]);
    const[errors,setErrors]=useState({});
    const handleAction = async ()=>{
        if(validate(formData,messageField) !== 0){ setErrors(validate(formData,messageField)); return false;}
        setMessage('');
        let currentUser = functionService.getCurrentUser();
        if(disabled === true){ return false;}
        setDisabled(true);
        setBtnLoader(true);
        formData.project_id =params.projectid;
        formData.user_id = currentUser.sub;
        formData.user_name = loginUser.name;
        let res = {};
        res = await functionService.post("message/save",formData);
        setBtnLoader(false);
        setDisabled(false);
        if(res.status === true){
            history.push(`/project/${params.projectid}/message/${res.response.data.result.insertId}/view`);
            let messagesid = res.response.data.result.insertId;
            handleMessageAlert(messagesid);
         }else{
             setMessage(res.error.message);
          }
    } 

    const handleMessageAlert = (messagesid) =>{
        let res = {};
        res = functionService.post("message/emailsent", {id:messagesid});        
        if(res.status === true){
            console.log("Message sent");
         }else{
             setMessage(res.error.message);
          }   
    }
	console.log("formData",formData);
  return (
            <>
                <Header />    
                <Container fluid>
                    <Row className="main--panel">
                    <Sidebar/> 
                    <Col md={10} className= "p-5">
                            <div className= "card--fluid--one">
                                <div className="card-fluid-header">
                                    <p>Messages - Post New</p> 
                                    <div className="project-head-right">                                    
                                    <Link to={`/project/${params.projectid}/messages`} className="add-btn"><FontAwesomeIcon icon={faArrowLeft} className="me-3" />Go Back</Link>
                                </div>                                
                                </div>                            
                           
                                 
                                <div className="message--reply--box mt-4">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Title</Form.Label>
                                    <Form.Control type="text" rows={10} value={formData && formData.title} onChange={(e)=>{setFormData({...formData,'title':e.target.value})}}/>
                                </Form.Group> 
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Message</Form.Label>
                                    {/* <Form.Control as="textarea" rows={10} value={formData && formData.description} onChange={(e)=>{setFormData({...formData,'description':e.target.value})}}/> */}
                                    <SunEditor value={formData && formData.description} onChange={(e)=>{setFormData({...formData,'description':e})}}/>
                                </Form.Group>                            

                               
                                 <FileUploader formData={formData} setFormData={setFormData}/>
                

                                <Button className="add-btn" onClick={handleAction}>Post Update</Button>                              
                                </div>  
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer/>
            </>
  );
  
}

export default PostNewMessage;