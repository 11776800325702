import React from "react";
import {Col, Button} from "react-bootstrap";
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons';

function DashboardTasksView ({taskid, taskName, priority,name,stylebg,details=""}){  
    
    let content = details.replace(/<\/?[^>]+(>|$)/g, "");
    return (
        <>
            <Col className="recnt-task-box" style={stylebg}>                
                <div className="task-head">
                    <h6>Task {taskid}</h6>
                    <a href="#"><FontAwesomeIcon icon={faEdit} /></a>
                </div>                
                <h4 className="task-name-">{taskName}</h4>                          
                <p className="client-msg">{content.substring(0, 40) + "..."}             
                </p>  
                <div className="task-details">
                    <div className="task_duration"><p className="lbel">Duration: <strong>3h 15min</strong></p></div>
                    <Badge className={`prority-${priority}`}>{priority}</Badge>{' '}    
                    <Button className="add-task-btn">{name}</Button>
                </div>                
            </Col>
        </>
    )}

export default DashboardTasksView;