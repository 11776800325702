import React, { useContext, useState,useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { UserContext } from "../../lib/UserContext";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import {  useParams } from "react-router-dom";
import { functionService } from "../../lib/functions";
import FileUploader from "../../components/FileUploader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { messageField, validate } from "../../components/ValidationChecked";
import { useHistory } from "react-router-dom";
import ImagesExtract from "../../components/ImagesExtract";
import SunEditor from "../../components/SunEditor";

function MessageUpdates() {
    const [loginUser] = useContext(UserContext);
    const[rows,setRows]=useState([]);
    const params = useParams();
    const[formData,setFormData]=useState({});
    const[message,setMessage] = useState('');
    const history= useHistory();
    const[disabled,setDisabled]=useState(false);
    const[itemRows,setItemRows] = useState([]);
    const[errors,setErrors]=useState({});

    const handleAction = async ()=>{
        formData.title = rows && rows[0].title;
        if(validate(formData,messageField) !== 0){ setErrors(validate(formData,messageField)); return false;}
        setMessage('');
        let currentUser = functionService.getCurrentUser();
        if(disabled === true){ return false;}
        setDisabled(true);
        formData.parent_id =params.messagesid;
        formData.project_id =params.projectid;
        formData.user_id = currentUser.sub;
        formData.user_name = loginUser.name;
        let res = {};
        res = await functionService.post("message/save",formData);
        setDisabled(false);
        if(res.status === true){
            setFormData({'title':'',"description":'',images:'[]'});   
            getList();    
            let messagesid = res.response.data.result.insertId;
            handleMessageAlert(messagesid);                   
   
         }else{
             setMessage(res.error.message);
          }   
               
    } 
    const handleMessageAlert = (messagesid) =>{
        let res = {};
        res = functionService.post("message/emailsent", {id:messagesid});        
        if(res.status === true){
           console.log("Message sent");
         }else{
             setMessage(res.error.message);
          }   
    }
    const getList = async()=>{
        let res = await functionService.get('project/thread/messages',{projectId:params.projectid,messageId:params.messagesid});
        if(res.status === true){
           setRows(res.response.data);
        }
      }
      useEffect(()=>{
          getList();
      },[]);
      console.log(rows);
  return (
            <>
                <Header />    
                <Container fluid>
                    <Row className="main--panel">
                    <Sidebar/> 
                    <Col md={10} className= "p-5">
                            <div className= "card--fluid--one">
                                <div className="card-fluid-header">
                                    <p>Messages - Project Name</p>  
                                    <div className="project-head-right">                                    
                                    <Link to={`/project/${params.projectid}/messages`} className="add-btn"><FontAwesomeIcon icon={faArrowLeft} className="me-3" />Go Back</Link>
                                </div>                              
                                </div>                            
                            {rows && rows.map((obj,index)=>(
                                    <div className="message--updates mt-4" key={index}>
                                    <div className="last-updated-message">
                                        <div className="user-information">
                                            <p>{obj.name}</p>
                                            <p className="user-update-time">  {obj.created_at} </p>
                                        </div>
                                        <div className="user-message-body">
                                        <div className="message--content" dangerouslySetInnerHTML={{
                                            __html: obj.description
                                          }}></div>
                                          {obj.images != '' &&  <ImagesExtract documents={obj.images}/>  }                                  
                                        </div>
                                    </div>                                
                                </div>
                            ))}
                                 
                                <div className="message--reply--box mt-4">
                                
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Message</Form.Label>
                                    {/* <Form.Control as="textarea" rows={10} value={formData && formData.description} onChange={(e)=>{setFormData({...formData,'description':e.target.value})}}/> */}
                                    <SunEditor value={formData && formData.description} onChange={(e)=>{setFormData({...formData,'description':e})}}/>
                                </Form.Group>                           
                               
                                 <FileUploader formData={formData} setFormData={setFormData}/>                
                                 
                                <Button className="add-btn" onClick= {handleAction}>Post Update</Button>                             
                                </div>  
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer/>
            </>
  );
  
}

export default MessageUpdates;