import React from "react";


/**
 *
 * Renders top navbar and shows the current signed in user.
 */
export default function Footer (){
  
 
    return (
      <>
       <div className="siderbar--footer">
          <p>©Copyright Start Designs<br /> All Rights Reserved.</p>
        </div>
      </>
    );
  
}
