import React,{useState} from "react";
import {Col, Button} from "react-bootstrap";
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AddTaskModal from './AddTaskModal';


function DashboardProjectsView ({taskName,projectName,obj}){  
    const [show, setShow] = useState(false);
    const handleAddTaskClose = () => setShow(false);
    const handleAddTaskShow = () =>setShow(true);

    return (
        <>
            <Col className="recnt-project-box">
                <div className="proj-head"><h2>{taskName}</h2>
                    <a href="#"><FontAwesomeIcon icon={faEdit} size="lg"/></a>
                </div>
                <p className="project-category">{projectName}</p>
                <Badge bg="danger">High</Badge>{' '}
                
                <div className="proj-details">
                    <div className="ttl_task"><p className="val">{obj.total_task}</p><p className="lbl">Total</p></div>
                    <div className="comp_task"><p className="val">{obj.start_date}</p><p className="lbl">Start Date</p></div>
                    <div className="remain_task"><p className="val">{obj.end_date}</p><p className="lbl">End Date</p></div>
                </div>

                <div className="proj-update-history">
                    <div className="last_update"><p className="update_lbl">Last Update On:</p>
                    <p className="update_time"><strong >{obj.created_at}</strong></p></div>
                    <Button className="add-btn" onClick={handleAddTaskShow}><FontAwesomeIcon icon={faPlus} className="me-3" />Add Task</Button>
                </div>
                <AddTaskModal handleAddTaskClose={handleAddTaskClose} show={show}/>
            </Col>
        </>
    )}
 
export default DashboardProjectsView;