import React, { useContext, useState } from "react";
import { Card, Form, Row, Col} from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import {Link} from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { UserContext } from "../lib/UserContext";
import TreeTable from "./Grid/Table";
import {taskColumn} from "../Pages/column/columns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit, faVirus, faEye, faBug, faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Offcanvas from 'react-bootstrap/Offcanvas';
import noDataImg from '../static/images/no-data-found.jpg';
import AddBugModal from "./AddBugModal";
import RisksModal from './RisksModal';
import AddTaskModal from './AddTaskModal';
import Avtarr from '../static/images/avatar/3.jpg';
import Avtarr1 from '../static/images/avatar/5.jpg';


function TaskBugCard({row,setIsCreateParent, handleModal,handleConfirmModal, setSelectedId}) {
    const [loginUser] = useContext(UserContext);  

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [taskShow, setTaskShow] = useState(false);
    const handleAddTaskClose = () => setTaskShow(false);
    const handleAddTaskShow = () =>setTaskShow(true);

    const [bugshow, setBugShow] = useState(false);
    const handleBugClose = () => setBugShow(false);
    const handleBugShow = () =>setBugShow(true);     
    const handleBugCreate = ()=>{      
      handleBugShow();
    }

    const[isCreate,setIsCreate] = useState(0);
    const[handleModalState,setSandleModalState] = useState(false);
    const [riskshow, setRiskShow] = useState(false);
    const handleRiskClose = () => setRiskShow(false);
    const handleRiskShow = () =>setRiskShow(true);     
    const handleRiskCreate = ()=>{      
      handleRiskShow();
    }

    const handleViewAction = ()=>{  handleShow() }   
    const handleDeleteAction =() =>{}

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
          console.log(),
        );      
        return (
          <button
            type="button"
            style={{ fontSize: "16px",fontWeight:500, border: "none", background:"#fff", padding: 0 }}
            onClick={decoratedOnClick}
          >
            {children}
          </button>
        );
      }
    const handleEdit = (id)=>{
      setIsCreateParent(id);
      handleModal();
    }
   const handleSprintDelete = (id)=>{
    handleConfirmModal(true);
    setSelectedId(id);
   } 
  return (
    <>

    <div className= 'task--main mb-5'>
        <div className="tasks-inner-data">
        <Accordion defaultActiveKey="0" >  
                                                                     
                                    <Card>
                                        <Card.Header className= 'task-bar-head'>
                                            <CustomToggle eventKey="0">Bug Id<span className="sprint-duration ms-3">(reopen)</span></CustomToggle>
                                            <div className="task-bug-action"> 
                                                <span className="priority--high bug-prior me-4">high</span>                                                
                                                <p className="bug-assignee me-4">Assignee: <strong>Amit kumar</strong> </p>
                                                <Form.Select size="sm">
                                                    <option>Select status</option>
                                                    <option value="todo">To Do</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="resolved">Resolved</option>
                                                </Form.Select>
                                                {/* <span className="me-3 add--icon1 fa--icon1" onClick={handleAddTaskShow}><FontAwesomeIcon icon={faPlus} size= "lg" className="sm--icon me-1"/> Add</span>                                               
                                                <span className="me-3 edit--icon1 fa--icon1" onClick={()=>{handleEdit(row.result.id)}}><FontAwesomeIcon icon={faEdit} size= "lg" className="sm--icon me-1"/>Edit</span>
                                                <span className="delete--icon1 fa--icon1" onClick={()=>{handleSprintDelete(row.result.id)}}><FontAwesomeIcon icon={faTrash} size= "lg" className="sm--icon me-1"/>Delete</span>                                                 */}
                                            </div>
                                        </Card.Header >
                                        
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body className="task-bug-body">
                                                <div className="task-bug-info">
                                                    <h3 className="mb-4">Bug Name- UI Designs</h3>
                                                    <p className="bug-info-label">Description :</p>
                                                    <p className="bug-info-text mb-4"> 1) Both useEffect and useLayoutEffect will be fired after layout and paint. However, useLayoutEffect will fire synchronously 
                                                        before the next paint. I'd say if you really need visual consistency then use useLayoutEffect, otherwise, useEffect should 
                                                        suffice. 
                                                        2) You're right about that! In a functional component createRef will create a new ref every time the component is 
                                                        being rendered. Using useRef is the better option. I'll update my answer
                                                    </p>
                                                </div>
                                                <div className="bug-detailing"><p className="bug-label">Reported By :</p><p  className="bug-value">Sarah singh</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Source URL :</p><Link to="https://www.startdesigns.com/"  className="bug-value">https://www.startdesigns.com/</Link></div>
                                                <div className="bug-detailing"><p className="bug-label">Meta Data :</p><p  className="bug-value">Some kind of metadata</p></div>

                                                <div className="bug-detailing">
                                                    <p className="bug-label">Console Logs :</p>
                                                    <p  className="bug-value1">In a functional component createRef will create <br/>In a functional component createRef will create <br/>In a functional component createRef will create <br/></p>
                                                </div>

                                                <div className="bug-detailing">
                                                    <p className="bug-label">Environment :</p>
                                                    <p  className="bug-value1">In a functional component createRef will create <br/>In a functional component createRef will create <br/>In a functional component createRef will create <br/></p>
                                                </div>

                                                <div className="bug-detailing">
                                                    <p className="bug-label">Attachment :</p>
                                                    <p  className="bug-value1">In a functional component createRef will create <br/>In a functional component createRef will create <br/>In a functional component createRef will create <br/></p>
                                                </div>



                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                     
                                </Accordion> 
                                <AddTaskModal handleModalState={handleModalState} setSandleModalState={setSandleModalState} handleClose={handleAddTaskClose} show={taskShow} isCreate={isCreate}/>
                                <AddBugModal handleClose= {handleBugClose} show= {bugshow} />
                                <RisksModal handleModalState={handleModalState} setSandleModalState={setSandleModalState} handleClose={handleRiskClose} show={riskshow} isCreate={isCreate}/>
        </div>
    </div>
   
    </>
  );
  
}

export default TaskBugCard;