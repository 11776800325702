import React, { useState } from 'react';

import { sortContracts } from "./util";
import "./table.css";
import { getValue, getValueByCol, renderCell } from "./functions";
import { renderActionCells, ToolTipCell } from "./ActionCell";
import { renderHeaderCell } from "./HeaderCell";
import plusCircleIcon from "./plus-circle.svg";
import minusCircleIcon from "./minus-circle.svg";

function Row({
  row={}, columns=[], contract={}, collapse=false, collapseTitle="", collapseParams={},
}) {

  const [open, setOpen] = useState(false);
  
  let collapseData = (collapse && 'collapse' in row) ? row['collapse'] : [];
  const isCollapse = collapse && (collapseData.length > 0);
  // const isCollapse = collapse;
  //  console.log("[TreeContracts] Row", row);
  const setOpenToggle = (openStatus)=>{
    setOpen(!openStatus);
  }

  return (
    <>
      <tr key={`subParentRow.(${row.id})`} className="childInner welcomeMsg">

        {
          columns.map((col,index) => (
            <td className={`tablePaddingSm ${col.cellClassName}`} key={`subParentRow.(${col.field}${index})`} >
                {(typeof col.tooltip !== "undefined" && col.tooltip===true) ?
                  <ToolTipCell cellData={col.renderCell({value: row[col.field], row: contract})}/>
                  :
                  <>  
                    {(typeof col.beforeText !== "undefined" && col.beforeText !== "") && <>{col.beforeText}</>}
                    {col.renderCell({value: row[col.field], row: contract})} 
                    {(typeof col.afterText !== "undefined" && col.afterText !== "") && <>{col.afterText}</>}
                  </>
                }
               
            </td>
          ))
        }
        {
          collapse && (
          <td key="collapse" className="collapseIconWrap" >
            {
              isCollapse && (
              <button onClick={() => setOpenToggle(open)} > 
                { (open) 
                ? 
                <img  alt="" src={minusCircleIcon} className="toggleSlide"/> 
                : 
                <img  alt="" src={plusCircleIcon} className="toggleSlide"/> }
              </button>)
            }
          </td>)
        }

      </tr>

      {
        collapse && (
        <CollapseRow 
          contracts={collapseData}
          parentContract={contract}
          title={collapseTitle}
          open={open}
          colSpan={columns.length + ((collapse) ? 1 : 0)}
          {...collapseParams}
        />)
      }
      
    </>
  );
}

function CollapseRow({
  contracts, columns, parentContract={}, actions = [], options = {}, title="", open, colSpan, headerShow,
}) {
  // console.log("[TreeContracts] CollapseRow", contracts, columns, parentContract, actions, options, headerShow);

 

  const boxBackgroundColor = headerShow ? '#F4F6F8' : '#ffffff';
  const tableBackgroundColor = headerShow ? '#ffffff' : '#F4F6F8';

  let renderHeadingClone = null;

  if (options.renderHeading && typeof options.renderHeading.props.onClick === 'function') {
    const oldOnClick = options.renderHeading.props.onClick;
    // console.log("[TreeContracts] CollapseRow", oldOnClick);
    renderHeadingClone = {
      ...options.renderHeading,
      props: {
        ...options.renderHeading.props,
        onClick: (e) => {
          oldOnClick(e, parentContract);
        },
      },
    };
    // console.log("[TreeTable] CollapseRow", parentContract);
  }

  return (
    <tr className = "child-tree-grid">
      <td style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: boxBackgroundColor }} colSpan={colSpan}>
        <div in={open} timeout="auto" unmountOnExit>
          <div margin={1} >
            {
              headerShow && (
                <div  gutterBottom component="div">
                  <div className="tree-heading">
                    <h1  className="poHeading">{title}</h1>
                    
                    <div className="poArchive">
                      {renderHeadingClone}
                    </div>
                  </div>
                </div>
              )
            }

            <TreeTable 
              contracts={contracts}
              columns={columns}
              actions={actions}
              options={{
                isPagination: false,
                isCollapseHeaderShow: false,
                backgroundColor: tableBackgroundColor,
              }}
            />

          </div>
        </div>
      </td>
    </tr>
  );
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


export function CustomPagination({
  page, setPage, rowsPerPage, totalRecords,
}) {
  

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  
  let totalPages = [];
  let totalPage = Math.ceil(totalRecords / rowsPerPage);
  for (var i = 1; i <= totalPage; i++) {totalPages.push(i); }

  return (
    <>
      <div className="usr-pagination text-right">
                    {totalPages.length > 1 &&
                      totalPages.map((data, index) => (
                        <span
                          onClick={() => {
                            handleChangePage(data);
                          }}
                          className={
                            page === data
                              ? "pagination current "
                              : "pagination"
                          }
                          key={index}
                        >
                          {data}
                        </span>
                      ))}
                  </div>
   </>
  );
}


/**
 * Tree Contracts based on Table
 * @param {Array} contracts 
 * @param {Array} columns 
 * @param {Array} actions 
 * @param {Object} options 
 * @returns 
 */
export default function TreeTable({
  contracts, columns, actions = [], options = {},rowsPerPage=20,totalRecords=1,page=1,setPage
}) {
   

  options = {
    isPagination: true,
    isRow: true,
    
    isCollapseHeaderShow: true,
    backgroundColor: 'transparent',
    sortKey: 'created_at',
    ...options,
  };
  
  const isDefault = !columns;
  columns = columns ? columns : [["TemplateId", "templateId"], ["ContractId", "contractId"]];

  contracts = sortContracts(contracts, options.sortKey, getValue);
  
  
  

  // check if it needs collapse or not
  const collapseIndex = columns.findIndex(col => col[2] && col[2].type === 'collapse');

  const collapseTitle = (collapseIndex >= 0) ? columns[collapseIndex][0] : "";
  const collapseParams = (collapseIndex >= 0) ? columns[collapseIndex][2] : {};
  if (options.isCollapseHeaderShow) collapseParams.headerShow = true;
  
  let cols = columns.filter(col => !col[2] || col[2].type !== 'collapse')
    .map(col => ({
      field: col[0],
      isAction: false,
      headerClassName: "headerCell",
      cellClassName: "headerCell",
      renderHeader: renderHeaderCell(col[2]),
      renderCell: renderCell(col[2]),
      ...((!!col[2] && !!col[2].options) ? col[2].options : {})
    }));
  if (isDefault) cols.push({
    field: 'payload',
    headerName: 'Payload',
  });
  if (actions.length) cols = cols.concat(actions.map(act => ({
    field: act[0],
    isAction: true,
    renderCell: renderActionCells(act[1]),
    ...((!!act[2] && typeof act[2] === 'object') ? act[2] : {})
  }))); 
  
 
  const rows = contracts.map((c, i) => {
    let values = {
      id: (c && c.contractId) ? c.contractId : i,
      contract: c,
    };
    
    columns.forEach(col => {
      let val = getValueByCol(c, col[1], col[2]);
      val = typeof val === "boolean" ? (val ? "True" : "False") : val;

      if (col[2] && col[2].type === 'collapse') {
        values['collapse'] = val;
      }
      else if (!!val && typeof val === "object" && val.length >= 0 && typeof col[2] === "object"
        // && 'columns' in col[2]
        ) {
          val = val.length;
      }
      values[col[0]] = val;
    });

    actions.forEach(act => {
      values[act[0]] = c;
    });
    
    return values;
  });
  
  const [order] = useState('asc');
  const [orderBy] = useState('calories');
  // console.log("rows",rows);
  
  return (
    <>
    
    <div className="grid--table--container tree-table--container"  style={{backgroundColor: options.backgroundColor}}>
      <table className="grid--table tree--table transition03  table  table-hover" aria-label="collapsible table">

        <thead>
          <tr className='tableHeader'>
            {
              cols.map((col, index)=>(
                <th  
                  style={{minWidth:(typeof col.width !== "undefined" && col.width !== "") ? col.width: 'none' }}  
                  className={col.headerClassName} key={index}
                >
                  {
                    typeof col.renderHeader === 'function'
                    ?
                    col.renderHeader({field: col.field})
                    :
                    col.field
                  }
                </th>
              ))
            }
            {
              (collapseIndex >= 0) && <td key="collapse"></td>
            }
          </tr>
        </thead>

        <tbody>
          {rows.length > 0 ?<>{stableSort(rows, getComparator(order, orderBy))
                .map((row,index) => (
            options.isRow && 
              <Row 
                key={`parentRow.(${row.id}).(${index})`} 
                row={row} 
                columns={cols} 
                contract={row.contract} 
                collapse={(collapseIndex >= 0)}
                collapseTitle={collapseTitle}
                collapseParams={collapseParams}
              />))}</>
          :<tr><td colSpan={cols.length}>No Records Found</td>
          </tr>}
        </tbody>

        {(options.isPagination === true) && 
        <tfoot>
          <tr>
            <td 
              className="footerpagination" 
              colSpan={cols.length + ((collapseIndex >= 0) ? 1 : 0)} 
            >
              {
                options.isPagination && (
                  <CustomPagination
                     page={page}
                     setPage={setPage}
                     rowsPerPage={rowsPerPage}
                     totalRecords={totalRecords}
                  />
              )}
            </td>
          </tr>
        </tfoot>
        }
        
      </table>
    </div>
   
    
   </>
  );
}
