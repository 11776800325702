import React from "react";
import {Col} from "react-bootstrap";


function ProjectRequirementCard ({cardHeading, headbg, borderbg,row}){      

    return (
        <>
		{row &&
            <Col className="project-dashboard-box" style={borderbg}>
                <div className="proj-dashboard-head" style={headbg}> <h2>{cardHeading}</h2> </div>                
                
                <div className="proj-dashboard-details">
                    <div className="detailRow"><p className="lbl">Total Requirement:</p><p className="val">{row.totsprint && row.totsprint[0].cnt}</p></div>
                    <div className="detailRow"><p className="lbl">Completed Requirement:</p><p className="val">{row.comsprint && row.comsprint[0].cnt}</p></div>
                    <div className="detailRow"><p className="lbl">Total Tasks:</p><p className="val">{row.tottask && row.tottask[0].cnt}</p></div>
                    <div className="detailRow"><p className="lbl">Completed Tasks:</p><p className="val">{row.comtask && row.comtask[0].cnt}</p></div>
                    <div className="detailRow"><p className="val">{(row.comtask &&  row.tottask[0].cnt > 0 ) &&  ((row.comtask[0].cnt / row.tottask[0].cnt ) * 100) } % of tasks is completed</p></div>
                    
                    {/* <Button className="add-btn"><FontAwesomeIcon icon={faPlus} className="me-3" />Add Task</Button>   */}
                </div>

                
                
            </Col>
		}
        </>
    )}

export default ProjectRequirementCard;