import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { UserContext } from "../../lib/UserContext";
import ChatMessaging from "./Chat";
function ChatPanel() {
    const [loginUser] = useContext(UserContext);
    console.log(loginUser);
  return (
    <>
    <Header />
    <Container fluid>
      <Row className="main--panel">
          <Sidebar/>
          <Col md={10}>
            <ChatMessaging  loginUser={loginUser}/>
          </Col>
      </Row>
    </Container>
   
  </>
  );
  
}

export default ChatPanel;