import React,{useState,useEffect} from "react";
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import { functionService } from "../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { holidayField, validate } from "./ValidationChecked";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function HolidayModal({handleModalState,setSandleModalState,handleClose,show,isCreate=0}) {   
  const[formData,setFormData]=useState({});
	const[message,setMessage] = useState('');
	const params = useParams();
  const[errors,setErrors]=useState({});
  const[disabled,setDisabled]=useState(false);
const[btnLoader,setBtnLoader]=useState(false);
	const handleAction = async ()=>{
    if(validate(formData,holidayField) !== 0){ setErrors(validate(formData,holidayField)); return false;}
		setMessage('');
    if(disabled === true){ return false;}
    setDisabled(true);
    setBtnLoader(true);
		formData.project_id =params.projectid;
    let res = {};
    if (isCreate === 0) {
        res = await functionService.post("holidays/save",formData);
    }else{
      formData.id = isCreate;
      res = await functionService.update("holidays/update",formData);
    }
		setDisabled(false);
		setBtnLoader(false);
		if(res.status === true){
			handleClose();
			setSandleModalState(!handleModalState);
        }else{
            setMessage(res.error.message);
        }
	}
  const getItem = async()=>{
    let res = await functionService.get(`holidays/get/${isCreate}`,{id:isCreate});
    if(res.status === true){
       setFormData(res.response.data);
    }
  }
  useEffect(()=>{
    if(isCreate !== 0 && show === true){
      getItem();
     }
     setFormData({});
  },[isCreate,show]);
  console.log("formData",formData)
    return (
      <>
        <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="handle-add-project-modal"
        >          
        <Modal.Header closeButton>
          <Modal.Title id="handle-add-project-modal">
            {isCreate === 0 ? 'Add New' : 'Update' } Holiday
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
        <Row>
            <Col md = {6}>
                <Form.Group className="mb-3" controlId="Date">
                  <Form.Label>Holiday Name</Form.Label>
                  <Form.Control type="text" placeholder="Holiday Name" value={formData && formData.holiday_name} onChange={(e)=>{setFormData({...formData,'holiday_name':e.target.value})}}/>
                  {errors && <p className="error">{errors['holiday_name']}</p>}
                </Form.Group>              
              </Col>
             <Col md = {6}>
              <Form.Group className="mb-3" controlId="Date">
                <Form.Label>Type</Form.Label>
              <Form.Select aria-label="status" className="form-control " value={formData && formData.holiday_type} onChange={(e)=>{setFormData({...formData,'holiday_type':e.target.value})}}>
                    <option value="">Type</option>
                    <option value="Holiday">Holiday</option>
                    <option value="Compensatory">Compensatory</option>                 
                  </Form.Select>
                  {errors && <p className="error">{errors['holiday_type']}</p>}
              </Form.Group>             
            </Col>                         
            </Row>

            <Row>
            <Col md = {6}>
                <Form.Group className="mb-3" controlId="Date">
                  <Form.Label>Date</Form.Label>
                   <Form.Control type="date" placeholder="Holiday Date" value={formData && functionService.getFormatedDate(formData.holiday_date)} onChange={(e)=>{setFormData({...formData,'holiday_date':e.target.value})}}/>
                  {errors && <p className="error">{errors['holiday_date']}</p>}
                </Form.Group>
              </Col>
              <Col md = {6}>
              <Form.Group className="mb-3" controlId="Is-Defer">
                <Form.Label>Is Defer</Form.Label>
               <Form.Select aria-label="status" className="form-control " value={formData && formData.is_defer} onChange={(e)=>{setFormData({...formData,'is_defer':e.target.value})}}>
                  <option value="">-Select-</option>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </Form.Select>  
                {errors && <p className="error">{errors['is_defer']}</p>}  
              </Form.Group>
                         
              </Col>              
            </Row>           
             

          </Form>
        </Modal.Body>
        <Modal.Footer>
            {isCreate === 0 ?           
             <Button className="add-btn" onClick={()=>{handleAction(isCreate)}}>{btnLoader && <FontAwesomeIcon icon={faSpinner} spin />} Create</Button>
              :
              <Button className="add-btn"  onClick={()=>{handleAction(isCreate)}}>{btnLoader && <FontAwesomeIcon icon={faSpinner} spin />} Update</Button>
            }
          <Button variant="outline-dark close-btn" onClick={handleClose}>
            Close
          </Button>
		  {message !== '' && message}
        </Modal.Footer>
        </Modal>        
        
      </>
    );
  }
  
 