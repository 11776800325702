import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Button, Form, FormControl } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import {bucketColumn, bugColumn} from "../column/columns";
import { UserContext } from "../../lib/UserContext";
import TreeTable from "../../components/Grid/Table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { functionService } from "../../lib/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmModal from "../../components/ConfirmModal";
import AddBugModal from "../../components/AddBugModal";
import ImagesExtract from "../../components/ImagesExtract";
function BugTracker() {
    const [loginUser] = useContext(UserContext);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () =>setShow(true);
    const [showCanvas, setCanvasShow] = useState(false);
    const[isCreate,setIsCreate] = useState(0);
    const[currentProject,setCurrentProject] = useState({role:0});
    const[currentPage,setCurrentPage] = useState(1);
  	const[handleModalState,setSandleModalState] = useState(false);
    const[totalRecords,setTotalRecords]=useState(0);
    const[selectedId,setSelectedId]=useState(0);
    const [currentRow,setCurrentRow] = useState({});
    const[confirmModal,handleConfirmModal]=useState(false);
    const[rows,setRows] = useState([]);
  	const params = useParams();
    const limit = 20;
    const getList = async()=>{
      let res = await functionService.get('bugtracker/list',{limit:limit,currentPage:currentPage,projectId:params.projectid});
      if(res.status === true){
          if(currentPage === 1){
            setTotalRecords(res.response.data.total[0].total);
            setCurrentProject(res.response.data.projectresult);
          }
        setRows(res.response.data.result);
      }
    }
    useEffect(()=>{
        getList();
    },[currentPage,handleModalState]);

    const handlecanvasClose  = () => setCanvasShow(false);
    const handlecanvasShow  = () => setCanvasShow(true);

    const handleViewAction = (row)=>{setCurrentRow(row);  handlecanvasShow(); }
	
    const handleEditAction = (row)=>{
      setIsCreate(row.id);
      handleShow();
    }
    const handleDeleteAction = (row)=>{
      setSelectedId(row.id);
      handleConfirmModal(true);
    }
    const handleCreate = ()=>{
      setIsCreate(0);
      handleShow();
    }
    const actionHook = ()=>{
      let action = [
        [["View", [<span className="fa--icon view--icon me-3"><FontAwesomeIcon icon={faEye} size= "2x" className="sm--icon"/></span> ],
          (c) => true],handleViewAction]];
      if(functionService.checkEditStatus(currentProject.role,["1","2","4","5"])){ 
        action.push([["Edit", [<span className="edit--icon fa--icon"><FontAwesomeIcon icon={faEdit} className="sm--icon"/></span>],
          (c) => true],handleEditAction]);
        action.push( [["Delete", [<span className="delete--icon fa--icon"><FontAwesomeIcon icon={faTrash} className="sm--icon"/></span>],
          (c) => true],handleDeleteAction]); 
      }
      return action;
    }
    
  return (
    <>
    <Header />
    
    <Container fluid>
        <Row className="main--panel">
           <Sidebar/> 
           <Col md={10} className="p-5">
           <div className="project-list-head">
                 <h2>Bug Tracker</h2>
                 <div className="project-head-right">
                  <Form className="d-flex search--form">
                      <FormControl
                        type="search"
                        placeholder="search "
                        className="me-2"
                        aria-label="Search"
                      />  <FontAwesomeIcon icon={faSearch} size= "lg" className="search--icon"/>                  
                  </Form>
                  { (functionService.checkEditStatus(currentProject.role,["1","2","4","5"])) &&
                    <Button className="add-btn" onClick={handleCreate}><FontAwesomeIcon icon={faPlus} className="me-3" />Add Bug</Button>
                  }
                 </div>
               </div>
             <div className="projects-list--on card--fluid mt-4">
               
               <AddBugModal handleModalState={handleModalState} setSandleModalState={setSandleModalState} handleClose={handleClose} show={show} isCreate={isCreate}/>
               <ConfirmModal handleModalState={handleModalState} setSandleModalState={setSandleModalState} handleConfirmModal={handleConfirmModal} show={confirmModal} selectedId={selectedId} modalName={'bugtracker'} />
              <TreeTable
                  columns ={bugColumn}
                  contracts={rows}
                  rowsPerPage={limit}
                  totalRecords={totalRecords}
                  page={currentPage}
                  setPage ={setCurrentPage}
                  actions={[

                    [
                      "Actions",
                      actionHook(),
                      {
                        width: 90,
                        background: "#26BDB8",
                        color: "#fff",
                        fontSize: "14px",
                        width:"150px",
                      }
                    ]
                  ]}
                 
                />
             </div>
               
            </Col>
        </Row>
    </Container>
    <Offcanvas show={showCanvas} onHide={handlecanvasClose} placement="end" className= "profile--offcanvas"> 
                        <Offcanvas.Header closeButton>
                          <h4>Bug Details</h4>    
                          <div className="task-action">                            
                            <Form.Group className="me-3" controlId="taskname">                              
                                <Form.Select aria-label="status" className="" value={currentRow && currentRow.task_status}>
                                      <option>Select Status</option>                                      
                                      <option value="Running">Running</option>
                                      <option value="Resolved">Resolved</option>
                                      <option value="Unresolved">Unresolved</option>
                                </Form.Select> 
                            </Form.Group>                                             
                            {/* <span className="me-3 edit--icon1 fa--icon1"><FontAwesomeIcon icon={faEdit} size= "lg" className="sm--icon me-1"/>Edit</span>
                            <span className="me-3 delete--icon1 fa--icon1"><FontAwesomeIcon icon={faTrash} size= "lg" className="sm--icon me-1"/>Delete</span>  */}
                            <span className="close--icon1 fa--icon1" ><FontAwesomeIcon icon={faTrash} size= "sm" className="sm--icon me-1"/>Close</span>                                                                        
                          </div>                                                 
                        </Offcanvas.Header>
                        <Offcanvas.Body className="task-bug-body">                                              
                        
                                                                                                
												                        <div className="bug-detailing"><p className="bug-label">Title :</p><p  className="bug-value">{currentRow && currentRow.title}</p></div>
                                                <div className="bug-detailing">
                                                    <p className="bug-label">Description :</p>
                                                    <div  className="bug-value" dangerouslySetInnerHTML={{
                                            __html: currentRow && currentRow.bug_description
                                          }}></div>                                                  
                                                </div>
                                                <div className="bug-detailing"><p className="bug-label">Impact :</p><p  className="bug-value">{currentRow && currentRow.nothing}</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Priority :</p><p  className="bug-value">{currentRow && currentRow.bug_priority}</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Occurrence :</p><p  className="bug-value">{currentRow && currentRow.occurrence}</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Category :</p><p  className="bug-value">{currentRow && currentRow.bug_type}</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Status :</p><p  className="bug-value">{currentRow && currentRow.bug_status}</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Assigned To :</p><p  className="bug-value">{currentRow && currentRow.assignedto}</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Assigned By :</p><p  className="bug-value">{currentRow && currentRow.assignedby}</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Start Date :</p><p  className="bug-value">{currentRow && currentRow.start_date}</p></div>
                                                <div className="bug-detailing"><p className="bug-label">End Date :</p><p  className="bug-value">{currentRow && currentRow.end_date}</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Sprint :</p><p  className="bug-value">{currentRow && currentRow.task_id}</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Task :</p><p  className="bug-value">{currentRow && currentRow.task_item_id}</p></div>
                                                <div className="bug-detailing"><p className="bug-label">Attachments :</p><div  className="bug-value">
                                                <ImagesExtract documents={currentRow && currentRow.images}/> 
                                                  </div></div>                                                   
                                                
                                                                    
                          
                        </Offcanvas.Body>
                      </Offcanvas>
</> 
  );
  
}
export default BugTracker;